import React,{Component }from 'react';

import  { useState, useEffect,useMemo, useContext, createContext, } from 'react';


import { useDispatch, useSelector } from "react-redux";

import { logout } from "../actions/auth";


import {ReactComponent as CloseIcon} from '../img/close-circle.svg';

import axios from 'axios';

import APIservice from "./services/api.service";



import  { Alert } from 'react';

import './css/brojila.css';

import {Line} from 'react-chartjs-2';

import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";





import { format } from 'date-fns';

import Loader from "./Loader";






import { FiXCircle } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { IoCloudDownload } from "react-icons/io5";

import { APIbase, APIvar } from './variables';



import { pdfjs, Document, Page } from 'react-pdf';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import JoyRide from "react-joyride";

import {ReactComponent as InfoIcon} from '../img/information-circle.svg';

import Swal from 'sweetalert2';




Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineController);
Chart.register(LineElement);
Chart.register(Tooltip);
Chart.register(Title);
Chart.register(Legend);

function Brojila(props) {

    const [list,setList]=useState([]);
    const [data, setData] = useState([])
    
    const [brojiloListStart, setBrojiloListStart] = useState([]); 

    const [text, setText ]=useState()
    const [xAxis, setXAxis] = useState([])
    const [yAxis, setYAxis] = useState([])
    const [r, setR] = useState("r1");

    const [date, setDate] = useState("");




    const [platform, setPlatform] = useState("3");
    
    const [isLoading, setIsLoading] = useState(true);

    const [isLoadingBill, setIsLoadingBill] = useState(false);


    const [nameOwner, setNameOwner] = useState("");
    const [stanje, setStanje] = useState("");
    const [lastDate, setLastDate] = useState("");
    const [R1, setR1] = useState(0);
    const [R2, setR2] = useState(0);
    const [R3, setR3] = useState(0);
    const [adresa, setAdresa] = useState("");
  
    const [fctCode, setFctCode] = useState("");
    const [buyerCode, setBuyerCode] = useState("");
    const [sifra, setSifra] = useState("");



    const [idBrojila, setIdBrojila] = useState('');

    const [OMM, setOMM] = useState('');
    const [userId, setUserId] = useState("")

    const [uInfo, setUserInfo] = useState({});

  
    const [listaTrosak, setListaTrosak] = useState([]);
    const [listaOcitanje, setListaOcitanje] = useState([]);

    
    const [BrojiloListOwed, setBrojiloListOwed] = useState([]);
    const [BrojiloListPaid, setBrojiloListPaid] = useState([]);
  
    const [numLines, setNumLines] = useState('30');

    const [activeType, setActiveType] = useState("R1");
    const [activeYear, setActiveYear] = useState("20");
    const [activeYearName, setActiveYearName] = useState("Sve")
  
    const [yearList, setYearList] = useState([{id:0, value:"20", name:"Sve"},{id:1, value:"2021", name:"2021"}, {id:2, value:"2020", name:"2020"}]);

    const [listaGrafova, setListaGrafovi] = useState([]);



    const [actBillDate, setActBillDate] = useState("");
    const [actBillOwed, setActBillOwed] = useState("");
    const [actBillDue, setActBillDue] = useState("");
    const [actBillPaid, setActBillPaid] = useState("");
    const [actBIllPNB, setActBillPNB] = useState("");


     const [actProperty, setActProperty] = useState("stanje");

     const [billBack, setBillBack] = useState("");

     const [pdfLink, setPdfLink] = useState("");

     const [showBill, setShowBill] = useState(false);

     const [addingMeter, setAddingMeter] = useState(false);

     const [addName, setAddName] = useState("");
     const [addFctCode, setAddFctCode] = useState("");
     const [addBuyCode, setAddBuyCode] = useState("");

     



     const { user: currentUser } = useSelector((state) => state.auth);


     const [currentPDF, setCurrentPDF] = useState('')

     const [pdfString, setPdfString] = useState('');

     const [numPages, setNumPages] = useState(null);
     const [pageNumber, setPageNumber] = useState(1);

     const [runTour, setRunTour] = useState(false);

     const [pdfModalIsVisible, setPdfIsVisible] =useState(false)
     const [racunModalIsVisible, setRacunIsVisible] =useState(false)

     const toastError = {                                                        
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
     };

     const toastSuccess = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };

     const toastInfo = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };

   
     const onDocumentLoadSuccess = ({ numPages }) => {
       setNumPages(numPages);
     };
   

     const dispatch = useDispatch();



    const state = {
        labels: xAxis,
        datasets: [
            {
              label: 'Potrošnja',
              
              fill: false,
              lineTension: 0,
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: '#29648a',
              borderWidth: 1,
              data: yAxis
            }
        ]
    };




    function handlePrimaryListItem (e) {
            var x = Array.from(document.querySelectorAll(".main-list-item")).map(item=>{
              item.classList.remove("mainListItemIsSelected")
            });
            var y =Array.from(document.querySelectorAll(".main-content")).map(item=>{
              item.classList.add("isHidden")
            });
            var z = document.querySelector(`.main-content-${e.target.id}`).classList.remove("isHidden")
            
            e.target.classList.add("mainListItemIsSelected");



            setActProperty(e.target.id);


            setShowBill(false);

//document.querySelector("#stanje")
    }


    function handleSideList(e) {



            var x = Array.from(document.querySelectorAll(".side-list-item")).map(item=>{
              item.classList.remove("sideListItemIsSelected")
            })


            //document.getElementById(".side-list-item-" + e).classList.add("isSelected");
            document.getElementById(e).classList.add("sideListItemIsSelected");
    }



    function handleSubOption(e) {
               var x = Array.from(document.querySelectorAll(".potrosnja-option")).map(item=>{
               item.classList.remove("potrosnjaOptionIsSelected")
               });
               var y = e.classList.add("potrosnjaOptionIsSelected")
               
               var z =Array.from(document.querySelectorAll(".lista-potrosnja")).map(item=>{
               item.classList.add("isHidden")
               })
               document.querySelector(`.${e.id}`).classList.remove("isHidden")

}    

                         {/*---------------------------------------------------------------------------*/}

  function handleSubOptionPromet(e) {

               setShowBill(false);
               var x = Array.from(document.querySelectorAll(".promet-option")).map(item=>{
                    item.classList.remove("prometOptionIsSelected")
               });
               var y = e.classList.add("prometOptionIsSelected")
               
               var z =Array.from(document.querySelectorAll(".lista-racun")).map(item=>{
                    item.classList.add("isHidden")
               })
               document.querySelector(`.${e.id}`).classList.remove("isHidden")

  }   

                           {/*---------------------------------------------------------------------------*/}



    function handleSubOptionAnaliza(e) {
               var x = Array.from(document.querySelectorAll(".analiza-option")).map(item=>{
               item.classList.remove("analizaOptionIsSelected")
               });
               var y = e.classList.add("analizaOptionIsSelected")
               
               var z =Array.from(document.querySelectorAll(".analiza-potrosnja")).map(item=>{
                    item.classList.add("isHidden");
               })
               document.querySelector(`.${e.id}`).classList.remove("isHidden");




    }

    
useEffect(() => {


          setShowBill(false);

          getDataBrojila();

          
          const allMenuTabs =  Array.from(document.querySelectorAll(".menu-item"));
          allMenuTabs.map((tab, index)=> {
               tab.classList.remove("menuItemIsSelected")
          })
          
          document.querySelector(".menu-item-2").classList.add("menuItemIsSelected")


          pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

          
          


}, []);

    
const logOut = () => {
     dispatch(logout());
};
    





    function handleItemList(id) {



                setShowBill(false);


                let aaa = brojiloListStart[id].text;

                setBuyerCode(aaa);


                if(actProperty == "stanje"){


                         getItemsStanje (aaa); 

                }else if(actProperty =="promet"){


                         getDataPromet(aaa);

                }else if(actProperty == "analiza"){


                         getDataAnaliza (aaa); 


                }else{

                }
                

    };


    /*

    function createDataToGraphs(data, r) {
                var y= [];
                var x= [];

                for (var i=0; i<data.length; i++) {
                    for (const property in data[i]) {
                      if(property === r) {
                        y.push(data[i][property])
                      } 
                      if(property === "date") {
                        x.push(data[i][property])
                      }

                    }
                  
                }

                setXAxis(x);
                setYAxis(y);

    }
    */





function getDataBrojila () {


          
     setBuyerCode('');

     setBrojiloListStart([]);

     setLastDate('');

     setNameOwner('');
     setListaOcitanje([]);
     setListaTrosak([]);

     setStanje('');

     
     setR1('');
     setR2('');
     setR3('');
     setAdresa('');
     setBuyerCode('');
     setFctCode('');

     setSifra('');
     setUserId('');    
     
     setBrojiloListOwed([]);
     setBrojiloListPaid([]);

     setActBillDate('');
     setActBillOwed('');
     setActBillDue('');
     setActBillPaid('');
     setActBillPNB('');

     setListaGrafovi([]);

     setXAxis([]);
     setYAxis([]);



     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         
     APIservice.GetMeterList(source.token).then(

             (response) => {
 
                     if (!unmounted) {
             


                                   setIsLoading(false);


                                   var dataTmp = response.data;



                                   if(dataTmp.meterList.length !=0){



                                             


                                             var arrBrojilo = [];
                                             var inty = 0;
          
          
                                             for(var propT in dataTmp.meterList) {
          
          
          
          
                                                  var flagT = 0;
          
          
                                                  var MeterNameTmp = "";
                                                  var MeterAddressTmp = "";
                                                  var MeterBuyerCodeTmp = "";
                                                  var MeterFactCodeTmp = "";
                                                  var MeterR1Tmp = "";
                                                  var MeterR2Tmp = "";
                                                  var MeterR3Tmp = "";
                                                  var MeterTipSaldoTmp ="";
                                                  var MeterSaldoTmp = "";
          
                                                  var tempMeterObj;
          
          
                                                  var tempOMM = '';
          
          
                                                  for(var propT2 in dataTmp.meterList[propT]){
          
                                                  
          
          
          
          
                                                  if(propT2 === 'MeterName'){
          
                                                       MeterNameTmp = dataTmp.meterList[propT][propT2];
          
          
          
                                                  }else if (propT2 === "MeterAddress"){
          
                                                       MeterAddressTmp = dataTmp.meterList[propT][propT2];
          
          
                                                  }else if (propT2 === "MeterBuyerCode"){
          
                                                       MeterBuyerCodeTmp = dataTmp.meterList[propT][propT2];
          
          
                                                  }else if (propT2 === "MeterFactCode"){
          
                                                       MeterFactCodeTmp = dataTmp.meterList[propT][propT2];
          
          
                                                  }else if (propT2 === "MeterR1"){
          
                                                       MeterR1Tmp = dataTmp.meterList[propT][propT2];
          
                                                  }else if (propT2 === "MeterR2"){
          
                                                       MeterR2Tmp = dataTmp.meterList[propT][propT2];
          
          
                                                  }else if (propT2 === "MeterR3"){
          
                                                       MeterR3Tmp = dataTmp.meterList[propT][propT2];
          
          
                                                  }else if (propT2 === "MeterSaldo"){
          
                                                       MeterSaldoTmp = dataTmp.meterList[propT][propT2];
          
          
          
                                                  }else if (propT2 === "MeterTipSaldo"){
          
                                                       MeterTipSaldoTmp = dataTmp.meterList[propT][propT2];
          
          
                                                  }else if (propT2 === "OMM"){
          
                                                       tempOMM = dataTmp.meterList[propT][propT2];
          
          
          
                                                  } else{
          
                                                       //nema ostalih
          
          
                                                  }
          
          
                                                  }
          
          
                                                  let tmpValue = parseFloat(MeterSaldoTmp);
                                                  tmpValue = tmpValue.toFixed(2);
          
          
                                                  let tmpValueString = "";
          
                                                  if(tmpValue!=0){
                                                  
          
                                                       //tmpValue = - tmpValue;
          
                                                       if(tmpValue > 0){
               
                                                            //stanje je dug
               
                                                            tmpValueString = tmpValue;
          
                                                       
          
                                                       }else{
          
                                                            //stanje je preplata
               
                                                            tmpValue = -tmpValue;
               
                                                            tmpValueString = 'U preplati ste: ' + tmpValue; 
          
                                                       }
          
                                                  }else{
          
                                                       //stanje je nula, dodaj string
               
                                                       tmpValueString = tmpValue;
          
                                                  }
          
          
                                                  //arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, "stanje": tmpValue, "id":inty, "OMM": tempOMM, "meterCode": MeterFactCodeTmp});
                                                  arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, "stanje": tmpValueString, "id":inty, "OMM": tempOMM, "meterCode": MeterFactCodeTmp});
          
          
                                                  inty = inty+1;
          
          
          
          
                                             }
          
          
                                             setBrojiloListStart(arrBrojilo);
          
                                             setBuyerCode(arrBrojilo[0].text);
          
          //********************************************************************************************************** */
                                            
                                             //handleSideList(arrBrojilo[0].id);


          //*********************************************************************************************************** */
          
          
          
                                             if(actProperty == "stanje"){
                              
                              
                                                       getItemsStanje (arrBrojilo[0].text); 
                              
                                             }else if(actProperty =="promet"){
                              
                              
                                                       getDataPromet(arrBrojilo[0].text);
                              
                                             }else if(actProperty == "analiza"){
                              
                              
                                                       getDataAnaliza (arrBrojilo[0].text); 
                              
                              
                                             }else{
          
                                                       getItemsStanje (arrBrojilo[0].text); 
                                             }
                                             
          
                                             
                    
                                        
                                   }else{

      

                                             toast.info(dataTmp.message,
                                                  toastInfo);
                                          
                                             setBrojiloListStart([]);
          
                                             setBuyerCode('');
               
                                          

                                   }
            
                     }
             
             },
 
             (error) => {
 
                    if (!unmounted) {
             
             

                       setIsLoading(false);

             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije",
                                   toastError);
                           


                         } else {


                              if(!error.response.status){

                                   toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije",
                                   toastError);

                              }else{
 
                                   if (error.response.status == 401) {
               
                            
                                        toast.error(error.response.data.message,
                                             toastError);
                                     
                                        setTimeout(() => {
                                                       logOut();
                                        }, 5000);                             
                            

                                   
                              
                                   } else {
                              
                              
                                             toast.error(error.response.data.message,
                                                  toastError);
                                                                            
                                        
                                   }
                              }
                         }
                     
                    }
             
             
             });
             
             
}            





function getItemsStanje  (meterID) {


     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         
     APIservice.GetMeterState(meterID, numLines, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             


                                   setIsLoading(false);


                                   
                                   var dataTmp = response.data;


                                   if(dataTmp.meterList.length !=0 && dataTmp.detailedMeter != null){


                                             //dobavi listu brojila

                                             var arrBrojilo = [];
                                             var inty = 0;

                                             var paid = 0.00;
                                             var owed = 0.00;




                                             for(var propT in dataTmp.meterList) {


                                                  var flagT = 0;


                                                  var MeterNameTmp = "";
                                                  var MeterAddressTmp = "";
                                                  var MeterBuyerCodeTmp = "";
                                                  var MeterFactCodeTmp = "";
                                                  var MeterR1Tmp = 0;
                                                  var MeterR2Tmp = 0;
                                                  var MeterR3Tmp = 0;
                                                  //var MeterTipSaldoTmp ="";
                                                  //var MeterSaldoTmp = 0.00;
                                                  var MeterDateTmp = "";

                                                  var MeterOMM = "";

                                                  var tempMeterObj;


                                                  var MeterSifraTmp = "";
                                                  

                                                  for(var propT2 in dataTmp.meterList[propT]){


                                                       

                                                  if(propT2 === 'MeterName'){

                                                       MeterNameTmp = dataTmp.meterList[propT][propT2];

                                                       
                                                  }else if(propT2 === 'MeterSifra'){

                                                       MeterSifraTmp = dataTmp.meterList[propT][propT2];
               
                                                            


                                                  }else if (propT2 === "MeterAddress"){

                                                       MeterAddressTmp = dataTmp.meterList[propT][propT2];
                                                       


                                                  }else if (propT2 === "MeterBuyerCode"){

                                                       MeterBuyerCodeTmp = dataTmp.meterList[propT][propT2];
                                                       


                                                  }else if (propT2 === "MeterFactCode"){

                                                       MeterFactCodeTmp = dataTmp.meterList[propT][propT2];
                                                       


                                                  }else if (propT2 === "MeterR1"){

                                                       MeterR1Tmp = dataTmp.meterList[propT][propT2];
                                                       

                                                  }else if (propT2 === "MeterR2"){

                                                       MeterR2Tmp = dataTmp.meterList[propT][propT2];
                                                       


                                                  }else if (propT2 === "MeterR3"){

                                                       MeterR3Tmp = dataTmp.meterList[propT][propT2];
                                                       



                                                       


                                                  }else if (propT2 === "Datum"){

                                                       MeterDateTmp = dataTmp.meterList[propT][propT2];
                                                       


                                                  }else if (propT2 === "OMM"){

                                                       MeterOMM = dataTmp.meterList[propT][propT2];
                                                       



                                                  } else{

                                                       //nema ostalih


                                                  }


                                                  }



                                                  arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, 
                                                       "id":inty, "sifra": MeterSifraTmp});
                                                  


                                                       





                                                  inty = inty+1;


                                             }


                                             //*******************************************************
                                             setBrojiloListStart(arrBrojilo);
                                             //**********************************************************/




                                             let startDate = dataTmp.detailedMeter.Datum;


                                             
                                             let endDate = format(new Date(startDate), 'dd-MM-yyyy');
                                            

                                             setLastDate(endDate);

                                             setNameOwner(dataTmp.detailedMeter.MeterName);
                                             
                                   


                                             let tmpValue = parseFloat(dataTmp.detailedMeter.MeterSaldo);
                                             tmpValue = tmpValue.toFixed(2);

                                             
                                             
                                             let tmpValueString = "";

                                             if(tmpValue!=0){
                                                       


                                                  if(tmpValue > 0){

                                                       //stanje je dug

                                                       tmpValueString = tmpValue;
                                                       
                                                  }else{

                                                       //stanje je preplata

                                                       tmpValue = -tmpValue;

                                                       tmpValueString = 'U preplati ste: ' + tmpValue; 

                                                  }

                                             }else{

                                                  //stanje je nula, dodaj string

                                                  tmpValueString = tmpValue;

                                             }



                                             setStanje(tmpValueString);

                                             
                                             setR1(dataTmp.detailedMeter.MeterR1);
                                             setR2(dataTmp.detailedMeter.MeterR2);
                                             setR3(dataTmp.detailedMeter.MeterR3);
                                             setAdresa(dataTmp.detailedMeter.MeterAddress);
                                             setBuyerCode(dataTmp.detailedMeter.MeterBuyerCode);
                                             setFctCode(dataTmp.detailedMeter.MeterFactCode);

                                             setSifra(dataTmp.detailedMeter.MeterSifra);
                                             setUserId( dataTmp.detailedMeter.user_id)

                                             //dohvati ocitanja


                                             var arrOcitanja = [];
                                             var arrTrosak = [];



                                             inty = 0;
                                             var inty2 = 0;

                                             var initID = 0;


                                             if(dataTmp.readingList.length != 0){


                                                            for(var propT in dataTmp.readingList) {


                                                                 var flagT = 0;


                                                                 var readingDateTmp = "";

                                                                 var readingR1Tmp = 0;
                                                                 var readingR2Tmp = 0;
                                                                 var readingR3Tmp = 0;

                                                                 var readingObrTmp ="";

                                                                 var readingNacinTmp = "";
                                                                 var readingKPCTmp = "";

                                                                 var readingOMM = "";
                                                                 var readingStorno = 0;


                                                                 var tempMeterObj;


                                                                 for(var propT2 in dataTmp.readingList[propT]){


                                                                 if(propT2 === 'NACIN'){

                                                                      readingNacinTmp = dataTmp.readingList[propT][propT2];


                                                                 }else if (propT2 === "DATUM"){

                                                                      readingDateTmp = dataTmp.readingList[propT][propT2];



                                                                 }else if (propT2 === "KPC_ID"){

                                                                      readingKPCTmp = dataTmp.readingList[propT][propT2];



                                                                 }else if (propT2 === "OBRACUNATO"){

                                                                      readingObrTmp = dataTmp.readingList[propT][propT2];


                                                                 }else if (propT2 === "R1"){

                                                                      readingR1Tmp = dataTmp.readingList[propT][propT2];



                                                                 }else if (propT2 === "R2"){

                                                                      readingR2Tmp = dataTmp.readingList[propT][propT2];


                                                                 }else if (propT2 === "R3"){

                                                                      readingR3Tmp = dataTmp.readingList[propT][propT2];


                                                                 }else if (propT2 === "OMM"){

                                                                      readingOMM = dataTmp.readingList[propT][propT2];



                                                                 }else if (propT2 === "STORNO"){

                                                                      readingStorno = dataTmp.readingList[propT][propT2];




                                                                 } else{

                                                                      //nema ostalih


                                                                 }

                                                                 }



                                                                           let startDateR = readingDateTmp;
                                                                           
                                                                           let endDateR = format(new Date(startDateR), 'dd-MM-yyyy');

                                                                           readingDateTmp = endDateR;



                                                                      arrOcitanja.push({"name": readingNacinTmp, "kupacId": readingKPCTmp, "date":"Datum očitanja: " + readingDateTmp,
                                                                                     "r1":readingR1Tmp, "r2":readingR2Tmp,"r3":readingR3Tmp,
                                                                                     "obrac": readingObrTmp, "id":inty,
                                                                                     "omm": readingOMM, "storno": readingStorno});


                                                                      inty = inty +1;



                                                            }



                                                            setListaOcitanje(arrOcitanja);


                                             }else{


                                                            setListaOcitanje([]);


                                             }


                                             if(dataTmp.spentList.length != 0){


                                                       for(var propT in dataTmp.spentList) {


                                                            var flagT = 0;


                                                            var spentDateODTmp = "";
                                                            var spentDateDOTmp = "";
                                                            var spentR1Tmp = 0;
                                                            var spentR2Tmp = 0;
                                                            var spentR3Tmp = 0;

                                                            var spentKPCTmp = "";


                                                            for(var propT2 in dataTmp.spentList[propT]){


                                                            if(propT2 === 'DATUMOD'){

                                                                 spentDateODTmp = dataTmp.spentList[propT][propT2];


                                                            }else if (propT2 === "DATUMDO"){

                                                                 spentDateDOTmp = dataTmp.spentList[propT][propT2];



                                                            }else if (propT2 === "KPC_ID"){

                                                                 spentKPCTmp = dataTmp.spentList[propT][propT2];


                                                            }else if (propT2 === "R1"){

                                                                 spentR1Tmp = dataTmp.spentList[propT][propT2];



                                                            }else if (propT2 === "R2"){

                                                                 spentR2Tmp = dataTmp.spentList[propT][propT2];


                                                            }else if (propT2 === "R3"){

                                                                 spentR3Tmp = dataTmp.spentList[propT][propT2];



                                                            } else{

                                                                 //nema ostalih


                                                            }

                                                            }


                                                            let startDateS = spentDateODTmp;
                                                                      
                                                            let endDateS = format(new Date(startDateS), 'dd-MM-yyyy');

                                                            spentDateODTmp = endDateS;

                                                            startDateS = spentDateDOTmp;
                                                                      
                                                            endDateS = format(new Date(startDateS), 'dd-MM-yyyy');

                                                            spentDateDOTmp = endDateS;


                                                                 arrTrosak.push({"name": "Potrošnja po brojilima", "date": "Razdoblje: " + spentDateODTmp + " do " + spentDateDOTmp, "kupacId": spentKPCTmp,
                                                                                "r1":spentR1Tmp, "r2":spentR2Tmp,"r3":spentR3Tmp, "id":inty2});


                                                                 inty2 = inty2 +1;




                                                       }


                                                       setListaTrosak(arrTrosak);



                                             }else{

                                                       setListaTrosak([]);

                                             }



                                             setIsLoading(false);
                                             
                    
                                        
                                   }else{

      


                                             toast.info(dataTmp.message,
                                                  toastInfo);

                                             setBrojiloListStart([]);

                                             setStanje('');

                                             
                                             setR1('');
                                             setR2('');
                                             setR3('');
                                             setAdresa('');
                                             setBuyerCode('');
                                             setFctCode('');

                                             setSifra('');
                                             setUserId('');    
                                             
                                             setListaTrosak([]);

                                             setListaOcitanje([]);


                                        }
            
                     }
             
             },
 
             (error) => {
 
                    if (!unmounted) {
             
               
                         console.error(error);

                         setIsLoading(false);

             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije",
                                   toastError);
                           


                         } else {


                              if(!error.response.status){

                                   toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije",
                                   toastError);

                              }else{
 
                                   if (error.response.status == 401) {
               
                                   
                                             toast.error(error.response.data.message,
                                                  toastError);
                                          
                                             setTimeout(() => {
                                                            logOut();
                                             }, 5000);                                    
                              
                                   } else {
                              
                                             toast.error(error.response.data.message,
                                             toastError);                             
                                        
                                   }
                              }
                         }
                    }
             
             
             });
             
             
}            





function getDataPromet (opcija) {


     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);

         
     APIservice.GetBillList(opcija, numLines, source.token).then(

             (response) => {
 
                    if (!unmounted) {
             


                                   setIsLoading(false);


                                   var dataTmp = response.data;

                                   if(dataTmp.meterList.length != 0){



                                             var arrBrojiloOwed = [];
                                             var arrBrojiloPaid = [];
                                             var inty = 0;
                                             var inty2 = 0;

                                             var initID = 0;


                                             if(dataTmp.bills.length != 0){

                                                       for(var propT in dataTmp.bills) {



                                                                 var flagT = 0;


                                                                 var BillNameTmp = "";
                                                                 var BillDateTmp = "";
                                                                 var BillBuyerCodeTmp = "";

                                                                 var BillOwed =0.00;
                                                                 var BillPaid = 0.00;
                                                                 var PNB = "";

                                                                 var BillSnaga = 0.00;
                                                                 var BillKamata = 0.00;
                                                                 var BillDospijece = "";
                                                                 var BillVte = "";
                                                                 var BillStorno = 0;
                                                                 var BillMonth = 0;
                                                                 var BillYear = 0;

                                                                 let paidBill = "";



                                                                 for(var propT2 in dataTmp.bills[propT]){


                                                                           if(propT2 === 'NAZIV'){

                                                                           BillNameTmp = dataTmp.bills[propT][propT2];




                                                                           }else if (propT2 === "DATUM"){

                                                                                BillDateTmp = dataTmp.bills[propT][propT2];


                                                                           }else if (propT2 === "KPC_ID"){

                                                                                BillBuyerCodeTmp = dataTmp.bills[propT][propT2];


                                                                           }else if (propT2 === "PNB"){

                                                                                PNB = dataTmp.bills[propT][propT2];


                                                                           }else if (propT2 === "UPLATA"){

                                                                                BillPaid = dataTmp.bills[propT][propT2];


                                                                           }else if (propT2 === "DUG"){

                                                                                BillOwed = dataTmp.bills[propT][propT2];



                                                                           }else if (propT2 == "PAID"){

                                                                                paidBill = dataTmp.bills[propT][propT2];

                                                                           }else if (propT2 === "VTE"){

                                                                                BillVte = dataTmp.bills[propT][propT2];

                                                                           }else if (propT2 === "SNAGA"){

                                                                                BillSnaga = dataTmp.bills[propT][propT2];

                                                                           }else if (propT2 === "KAMATA"){

                                                                                BillKamata = dataTmp.bills[propT][propT2];

                                                                           }else if (propT2 === "DOSPIJECE"){

                                                                                BillDospijece = dataTmp.bills[propT][propT2];

                                                                           }else if (propT2 === "STORNO"){

                                                                                BillStorno = dataTmp.bills[propT][propT2];
                                                                           
                                                                           }else if (propT2 === "MONTH"){

                                                                                BillMonth = dataTmp.bills[propT][propT2];

                                                                           }else if (propT2 === "YEAR"){

                                                                                BillYear = dataTmp.bills[propT][propT2];


                                                                           } else{

                                                                           //nema ostalih


                                                                           }


                                                                 }




                                                                 var tmpOwed = parseFloat(BillOwed).toFixed(2);

                                                                 let startDate = BillDateTmp;
                                                                                
                                                                 let endDate = format(new Date(startDate), 'dd-MM-yyyy');

                                                                 BillDateTmp = endDate;

                                                                 startDate = BillDospijece;
                                                                                
                                                                 endDate = format(new Date(startDate), 'dd-MM-yyyy');

                                                                 BillDospijece = endDate;



                                                                 if(parseFloat(BillOwed) >= 0 && BillStorno == 0 && BillNameTmp=="Račun"){



                                                                           var tmpPaid = "";

                                                                           var tmpBol;

                                                                           if(paidBill == 0){

                                                                                tmpPaid = "Račun nije podmiren";

                                                                                tmpBol = false;

                                                                           }else{

                                                                                tmpPaid = "Račun je podmiren";

                                                                                tmpBol = true;
                                                                           }


                                                                           arrBrojiloOwed.push({"name": BillNameTmp, "kupacId": BillBuyerCodeTmp, "date": BillDateTmp, "owed":parseFloat(BillOwed).toFixed(2), 
                                                                                "id":inty,
                                                                                "dospijece": BillDospijece, "VTE": BillVte, "kamata": BillKamata, "snaga": BillSnaga, "pnb": PNB, "paid":tmpPaid, 
                                                                                "paidFlag": paidBill, "dateOwed": BillMonth + "/" + BillYear});



                                                                           inty = inty +1;
                                                                 
                                                                 
                                                                 }else if(parseFloat(BillPaid) > 0 || BillNameTmp=="Uplata"){


                                                                      arrBrojiloPaid.push({"name": BillNameTmp, "kupacId": BillBuyerCodeTmp, "date": BillDateTmp, "owed":parseFloat(BillPaid).toFixed(2),
                                                                      "id":inty2, "pnb": PNB,
                                                                      "dospijece": BillDospijece, "VTE": BillVte, "kamata": BillKamata, "snaga": BillSnaga});





                                                                      inty2 = inty2 +1;

                                                                 }else if(BillStorno == 1){



                                                                           arrBrojiloOwed.push({"name": BillNameTmp, "kupacId": BillBuyerCodeTmp, "date": BillDateTmp, "owed":parseFloat(BillOwed).toFixed(2), 
                                                                           "id":inty,
                                                                           "dospijece": BillDospijece, "VTE": BillVte, "kamata": BillKamata, "snaga": BillSnaga, "pnb": PNB, "paid":tmpPaid, 
                                                                           "paidFlag": paidBill, "dateOwed": BillDateTmp});



                                                                           inty = inty +1;

                                                                 }



                                                       }



                                                       setBrojiloListOwed(arrBrojiloOwed);
                                                       setBrojiloListPaid(arrBrojiloPaid);

                                                       //setActBillDate(arrBrojiloOwed[0].date);
                                                       setActBillDate(arrBrojiloOwed[0].dateOwed);
                                                       setActBillOwed(arrBrojiloOwed[0].owed);
                                                       setActBillDue(arrBrojiloOwed[0].dospijece);
                                                       setActBillPaid(arrBrojiloOwed[0].paid);
                                                       setActBillPNB(arrBrojiloOwed[0].pnb);

                                             
                                             
                                             
                                             
                                             }else{




                                             }



                                             //ucitaj sve KPC_ID

                                             var arrBrojilo = [];
                                             var inty3 = 0;

                                             var paid = 0.00;
                                             var owed = 0.00;


                                             for(var propT in dataTmp.meterList) {


                                                  var flagT = 0;


                                                  var MeterNameTmp = "";
                                                  var MeterAddressTmp = "";
                                                  var MeterBuyerCodeTmp = "";
                                                  var MeterFactCodeTmp = "";
                                                  var MeterR1Tmp = 0;
                                                  var MeterR2Tmp = 0;
                                                  var MeterR3Tmp = 0;
                                                  var MeterTipSaldoTmp ="";
                                                  var MeterSaldoTmp = 0.00;
                                                  var MeterDateTmp = "";

                                                  var MeterOMM = "";

                                                  var tempMeterObj;


                                                  for(var propT2 in dataTmp.meterList[propT]){



                                                  if(propT2 === 'MeterName'){

                                                       MeterNameTmp = dataTmp.meterList[propT][propT2];




                                                  }else if (propT2 === "MeterAddress"){

                                                       MeterAddressTmp = dataTmp.meterList[propT][propT2];


                                                  }else if (propT2 === "MeterBuyerCode"){

                                                       MeterBuyerCodeTmp = dataTmp.meterList[propT][propT2];


                                                  }else if (propT2 === "MeterFactCode"){

                                                       MeterFactCodeTmp = dataTmp.meterList[propT][propT2];


                                                  }else if (propT2 === "MeterR1"){

                                                       MeterR1Tmp = dataTmp.meterList[propT][propT2];

                                                  }else if (propT2 === "MeterR2"){

                                                       MeterR2Tmp = dataTmp.meterList[propT][propT2];


                                                  }else if (propT2 === "MeterR3"){

                                                       MeterR3Tmp = dataTmp.meterList[propT][propT2];


                                                  }else if (propT2 === "MeterSaldo"){

                                                       MeterSaldoTmp = dataTmp.meterList[propT][propT2];



                                                  }else if (propT2 === "MeterTipSalda"){

                                                       MeterTipSaldoTmp = dataTmp.meterList[propT][propT2];


                                                  }else if (propT2 === "Datum"){

                                                       MeterDateTmp = dataTmp.meterList[propT][propT2];


                                                  }else if (propT2 === "OMM"){

                                                       MeterOMM = dataTmp.meterList[propT][propT2];



                                                  } else{

                                                       //nema ostalih


                                                  }


                                                  }


                                                  let tmpValue = parseFloat(MeterSaldoTmp);
                                                  tmpValue = tmpValue.toFixed(2);

                                                  let tmpValueString = "";
                                                  if(tmpValue!=0){
                                                  

                                                       //tmpValue = - tmpValue;

                                                       if(tmpValue > 0){

                                                       //stanje je dug

                                                       tmpValueString = tmpValue;

                                                       

                                                       }else{

                                                       //stanje je preplata

                                                       tmpValue = -tmpValue;

                                                       tmpValueString = 'U preplati ste: ' + tmpValue; 

                                                       }

                                                  }else{

                                                  //stanje je nula, dodaj string

                                                  tmpValueString = tmpValue;

                                                  }




                                                  //arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, "stanje": MeterSaldoTmp, "id":inty3});
                                                  arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, "stanje": tmpValueString, "id":inty3});








                                                  inty3 = inty3+1;


                                             }

               





                                             setIsLoading(false);




                                   }else{


                                        toast.info(dataTmp.message, toastInfo);
                                   }

            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
             

                       setIsLoading(false);

             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                                                

                         } else {


                              if(!error.response.status){

                                   toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{

                                   if (error.response.status == 401) {
               

                                             toast.error(error.response.data.message,
                                                  toastError);
                                          
                                             setTimeout(() => {
                                                            logOut();
                                             }, 5000);                                    
                              
                                   } else {
                              
                                             toast.error(error.response.data.message, toastError)
                              
                                        
                                   }
                              }
                         }
                     }
             
             
             });
             
             
}            






///////////////////////////////////////////////////////////////////////////////////////////////////////////////
//dobavi podatke za graf
///////////////////////////////////////////////////////////////////////////////////////////////////////////////


function getDataAnaliza  (meterID) {

     setRDefault();

     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         
     APIservice.GetMeterState(meterID, numLines, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                

                                   setIsLoading(false);

                                 

                                   var dataTmp = response.data;


                                   if(dataTmp.meterList.length !=0){


                                             //dobavi listu ocitanja

                                             var arrOcitanja = [];
                                             var arrTrosak = [];



                                             var inty = 0;
                                             var inty2 = 0;

                                             var initID = 0;

                                             let dataArrAll = [];
                                             let labelArrayAll = [];

                                             let dataArr = [];
                                             let labelArray = [];
                                             let dataArrR1 = [];
                                             let dataArrR2 = [];
                                             let dataArrR3 = [];

                                             setYearList([{id:0, value:"20", name:"Sve"}]);

                                             let tmpYearList = [{id:0, value:"20", name:"Sve"}];

                                             let intYear = 1;


                                             if(dataTmp.spentList.length != 0){

                                                       for(var propT in dataTmp.spentList) {


                                                                 var flagT = 0;


                                                                 var spentDateODTmp = "";
                                                                 var spentDateDOTmp = "";
                                                                 var spentR1Tmp = 0;
                                                                 var spentR2Tmp = 0;
                                                                 var spentR3Tmp = 0;

                                                                 var spentKPCTmp = "";


                                                                 for(var propT2 in dataTmp.spentList[propT]){


                                                                      if(propT2 === 'DATUMOD'){

                                                                           spentDateODTmp = dataTmp.spentList[propT][propT2];


                                                                      }else if (propT2 === "DATUMDO"){

                                                                           spentDateDOTmp = dataTmp.spentList[propT][propT2];



                                                                      }else if (propT2 === "KPC_ID"){

                                                                           spentKPCTmp = dataTmp.spentList[propT][propT2];


                                                                      }else if (propT2 === "R1"){

                                                                           spentR1Tmp = dataTmp.spentList[propT][propT2];



                                                                      }else if (propT2 === "R2"){

                                                                           spentR2Tmp = dataTmp.spentList[propT][propT2];


                                                                      }else if (propT2 === "R3"){

                                                                           spentR3Tmp = dataTmp.spentList[propT][propT2];



                                                                      } else{

                                                                           //nema ostalih


                                                                      }

                                                                 }

                                                                 
                                                                 let tmpDateForYear = spentDateDOTmp;

                                                                 let startDateS = spentDateODTmp;
 
                                                                      
                                                                 let endDateS = format(new Date(startDateS), 'dd-MM-yyyy');
     
                                                                 spentDateODTmp = endDateS;
     
                                                                 startDateS = spentDateDOTmp;
                                                                           
                                                                 endDateS = format(new Date(startDateS), 'dd-MM-yyyy');
     
                                                                 spentDateDOTmp = endDateS;
                                                                 





                                                                      
                                                                 arrTrosak.push({"name": "Potrošnja po brojilima", "date": "Razdoblje: " + spentDateODTmp + " do " + spentDateDOTmp, "kupacId": spentKPCTmp,
                                                                                     "r1":spentR1Tmp, "r2":spentR2Tmp,"r3":spentR3Tmp, "id":inty2});

                    
                                                                 labelArray.push(spentDateDOTmp);

                                                               

                                                                 let tmpVar = format(new Date(tmpDateForYear), 'yyyy');


                                                                 let index = tmpYearList.findIndex(object => object.name === tmpVar);

                                                                 if (index === -1) {
                                                                           tmpYearList.push({id:intYear, value:tmpVar, name:tmpVar});

                                                                           intYear++;
                                                                 }

                                                                 //([{id:0, value:"20", name:"Sve"},{id:1, value:"2021", name:"2021"}, {id:2, value:"2020", name:"2020"}]);

                                                                      
                                                                 let tmpR1 = 0;
                                                                      
                                                                 let tmpR2 = 0;
                                                                      
                                                                 let tmpR3 = 0;



                                                                      
                                                                 if(spentR1Tmp == 0){


                                                                           dataArrR1.push(0);


                                                                      
                                                                 }else{

                                                                           dataArrR1.push(spentR1Tmp);

                                                                           tmpR1 = spentR1Tmp;
                                                                 }

                                                                      
                                                                 if(spentR2Tmp == 0){


                                                                           dataArrR2.push(0);

                                                                 }else{

                                                                           dataArrR2.push(spentR2Tmp);

                                                                           tmpR2 = spentR2Tmp;
                                                                 }



                                                                      
                                                                 if(spentR3Tmp == 0){


                                                                           dataArrR3.push(0);

                                                                 }else{

                                                                           dataArrR3.push(spentR3Tmp);

                                                                           tmpR3 = spentR3Tmp;
                                                                 }

          
                                                                 dataArrAll.push({"id": inty2, "label": spentDateDOTmp, "R1": tmpR1, "R2": tmpR2, "R3": tmpR3});




                                                                 inty2 = inty2 +1;




                                                       }


                                                       setListaTrosak(arrTrosak);

                                                       //console.log(tmpYearList);

                                                       setYearList(tmpYearList);




                                                       let tmpLabels = labelArray.reverse();
                                                       let tmpData = dataArrR1.reverse();

                                                       let tmpAll = dataArrAll.reverse();

                                                       setListaGrafovi(tmpAll);

                                                       setXAxis(tmpLabels);
                                                       setYAxis(tmpData);


                                                       setActiveType("R1");
                                                       setActiveYear("20");
                                                       setActiveYearName("Sve");

                                             
                                             }else{

                                                       toast.info("Nema podataka o potrošnji za odabrano mjerno mjesto", toastInfo);

                    
                                                       setListaTrosak([]);


                                             }

                                             //ucitaj sve KPC_ID

                                             var arrBrojilo = [];
                                             var inty3 = 0;

                                             var paid = 0.00;
                                             var owed = 0.00;


                                             for(var propT in dataTmp.meterList) {


                                                  var flagT = 0;


                                                  var MeterNameTmp = "";
                                                  var MeterAddressTmp = "";
                                                  var MeterBuyerCodeTmp = "";
                                                  var MeterFactCodeTmp = "";
                                                  var MeterR1Tmp = 0;
                                                  var MeterR2Tmp = 0;
                                                  var MeterR3Tmp = 0;
                                                  var MeterTipSaldoTmp ="";
                                                  var MeterSaldoTmp = 0.00;
                                                  var MeterDateTmp = "";

                                                  var MeterOMM = "";

                                                  var tempMeterObj;

                                                  

                                                  for(var propT2 in dataTmp.meterList[propT]){


                                                       if(propT2 === 'MeterName'){

                                                            MeterNameTmp = dataTmp.meterList[propT][propT2];

                                                            



                                                       }else if (propT2 === "MeterAddress"){

                                                            MeterAddressTmp = dataTmp.meterList[propT][propT2];
                                                            


                                                       }else if (propT2 === "MeterBuyerCode"){

                                                            MeterBuyerCodeTmp = dataTmp.meterList[propT][propT2];
                                                            


                                                       }else if (propT2 === "MeterFactCode"){

                                                            MeterFactCodeTmp = dataTmp.meterList[propT][propT2];
                                                            


                                                       }else if (propT2 === "MeterR1"){

                                                            MeterR1Tmp = dataTmp.meterList[propT][propT2];
                                                            

                                                       }else if (propT2 === "MeterR2"){

                                                            MeterR2Tmp = dataTmp.meterList[propT][propT2];
                                                            


                                                       }else if (propT2 === "MeterR3"){

                                                            MeterR3Tmp = dataTmp.meterList[propT][propT2];
                                                            


                                                       }else if (propT2 === "MeterSaldo"){

                                                            MeterSaldoTmp = dataTmp.meterList[propT][propT2];
                                                            



                                                       }else if (propT2 === "MeterTipSalda"){

                                                            MeterTipSaldoTmp = dataTmp.meterList[propT][propT2];
                                                            


                                                       }else if (propT2 === "Datum"){

                                                            MeterDateTmp = dataTmp.meterList[propT][propT2];
                                                            


                                                       }else if (propT2 === "OMM"){

                                                            MeterOMM = dataTmp.meterList[propT][propT2];
                                                            



                                                       } else{

                                                            //nema ostalih


                                                       }


                                                  }

                                                  let tmpValue = parseFloat(MeterSaldoTmp);
                                                  tmpValue = tmpValue.toFixed(2);

                                                  let tmpValueString = "";
                                                  if(tmpValue!=0){
                                                  

                                                       //tmpValue = - tmpValue;

                                                       if(tmpValue > 0){

                                                       //stanje je dug

                                                       tmpValueString = tmpValue;

                                                       

                                                       }else{

                                                       //stanje je preplata

                                                       tmpValue = -tmpValue;


                                                       }

                                                  }else{

                                                       //stanje je nula, dodaj string

                                                       tmpValueString = tmpValue;

                                                  }




                                                  //arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, "stanje": MeterSaldoTmp, "id":inty3});
                                                  arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, "stanje": tmpValueString, "id":inty3});


                                                  inty3 = inty3+1;


                                             }


                                             setIsLoading(false);

                                        
                                   }else{

      
                                        toast.info(dataTmp.message, toastInfo);
                                   }
            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoading(false);

             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                         } else {


                              if(!error.response.status){

                                   toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{

                                   if (error.response.status == 401) {
                                        
                                        toast.error(error.response.data.message,
                                             toastError);
                                     
                                        setTimeout(() => {
                                                       logOut();
                                        }, 5000); 
                                   
                              
                                   } else {
                              
                                             toast.error(error.response.data.message, toastError)
                              
                                        
                                   }
                              }
                         }
                     }
             
             
             });
             
             
}            






function filterYear (year, type){

     //console.log("FilterYear");
     //console.log(year);
     //console.log(listaGrafova);

  //let tmpArray = listaGrafova.filter(data => (data.label.endsWith(year)));

  let tmpArray = listaGrafova.filter(data => {
     
     data.label.endsWith(year);
     if (year == "20" ){

               return data;

     } else {
               if (data.label.endsWith(year)){

                    return data;
               }
     }
});


  let privremenoArr = [];

  let privremenoLabels = [];

  tmpArray.forEach(myFunction);

  function myFunction(item, index) {

      privremenoLabels.push(item.label);

      if(type=="R2"){

              if(item.R2==0){


                      privremenoArr.push(0);
              }else{

                      privremenoArr.push(item.R2);
              }





      }else if(type=="R3"){

              if(item.R3==0){


                      privremenoArr.push(0);
              }else{

                      privremenoArr.push(item.R3);
              }


      }else{

              if(item.R1==0){


                      privremenoArr.push(0);
              }else{

                      privremenoArr.push(item.R1);
              }


      }


  }


  setXAxis(privremenoLabels);
  setYAxis(privremenoArr);


}



function setRDefault(){

     var x = Array.from(document.querySelectorAll(".analiza-option")).map(item=>{
          item.classList.remove("potrosnjaOptionIsSelected")
          });
     
     var y = document.querySelector(".analiza-option.analiza-option1").classList.add("analizaOptionIsSelected");
     

}






    function getStanjeData() {


        getItemsStanje(buyerCode); 
    }

    function getPrometData() {


        getDataPromet (buyerCode);

    }
    function getAnalizaData() {


        getDataAnaliza (buyerCode); 
    }
    function getKontaktData() {

        toast.info("Komunikacijska sučelja prema korisničkoj službi nisu postavljena", toastInfo);
    }





    function convertPDF (kpc_id, pnb)  {


     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoadingBill(true);
     setIsLoading(true);
         



     APIservice.ConvertPDF(kpc_id, pnb, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoadingBill(false);
                                   setIsLoading(false);


                                   var pdfLinkTmp = response.data.PDFLink;

                                   //console.log(response.data.guid);

                                   //console.log(pdfLinkTmp);



                                   let pdfString = APIbase + 'PDFViewer';

                                   //let pdfFinal = pdfString + '?appId=' + appId + '&token=' + token + '&fileID=' + pdfLinkTmp;
                                   let pdfFinal = pdfString + '?fileID=' + pdfLinkTmp;


                                   //setPdfLink(pdfFinal);

                                   //setShowBill(true);

                                   requestPDF (response.data.guid);


            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoadingBill(false);

                       setIsLoading(false);


             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                         } else {


                              if(!error.response.status){

                                   toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{

                                   if (error.response.status == 401) {
               
                                        toast.error(error.response.data.message,
                                             toastError);
                                     
                                        setTimeout(() => {
                                                       logOut();
                                        }, 5000); 
                                   
                              
                                   } else {
                              
                                             toast.error(error.response.data.message, toastError)
                              
                                        
                                   }
                              }
                         }
                     }
             
             
             });
             
             
}       




function requestPDF (guid)  {


     let unmounted = false;


     setIsLoadingBill(true);
     setIsLoading(true);
         

     APIservice.previewPDF(guid).then(

         // .then((response) => response.blob())

          //.then((blob) => {    

          (response) => {
                    /*          
                    const file = window.URL.createObjectURL(blob);
                    const iframe = document.querySelector("iframe");
                    if (iframe?.src) iframe.src = file;
                    */


                    //console.log(response.data.pdf);

                    let base64String = response.data.pdf;
                    
                    //setCurrentPDF(response.data);

                    setCurrentPDF(base64String.substr(base64String.indexOf(',') + 1));

                    window.scrollTo(0,0);

                    setIsLoadingBill(false);
                    setIsLoading(false);

                    setShowBill(true);
                    setRacunIsVisible(true);
                    setPdfIsVisible(true)
                    
          })

          .catch((error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoadingBill(false);

                       setIsLoading(false);


             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                         } else {


                              if(!error.response.status){

                                   toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{

                                   if (error.response.status == 401) {
               
                                        toast.error(error.response.data.message,
                                             toastError);
                                     
                                        setTimeout(() => {
                                                       logOut();
                                        }, 5000); 
                              
                                   } else {
                              
                                             toast.error(error.response.data.message, toastError)
                              
                                        
                                   }
                              }
                         }
                     }
             
             
             });
             
             
}       




function downloadAsPDF(pdfToDL) {

     var base64String = pdfToDL;
   
     if (base64String.startsWith("JVB")) {
          base64String = "data:application/pdf;base64," + base64String;
          downloadFileObject(base64String);
     } else if (base64String.startsWith("data:application/pdf;base64")) {
          downloadFileObject(base64String);
     } else {
          toast.error("Dokument nije ispravan, molimo provjerite dokument koji želite preuzeti", toastError);
     }
   
   }
   
   function downloadFileObject(base64String) {
          const linkSource = base64String;
          const downloadLink = document.createElement("a");
          const fileName = "Dokument.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
   }




function spremi() {

     if(addFctCode != ''){
 

 
 
             if(addBuyCode != ''){
 

 
                         //console.log("buyer valid");
 
                         AddMeter();
 
                         

 

 
             }else{
 
 
                  toast.error("Šifra kupca koju ste unijeli je neispravna, molimo unesite ispravnu šifru kupca", toastError);
 
             }
 
 
     }else{
 
 
 
          toast.error("Broj brojila koji ste unijeli je neispravan, molimo ponovite unos", toastError);
 
     }
 
 
 }
 



 function AddMeter () {


     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         



     APIservice.AddMeter(addFctCode, addBuyCode, addName, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoading(false);

                                   //console.log(response.data.message);


                                   setAddingMeter(false);
  
                                   toast.success(response.data.message, toastSuccess);
  
                                   setAddName("");
                                   setAddFctCode("");
                                   setAddBuyCode("");
  
  
                                   getDataBrojila();


            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       //console.log(error.response);

                       //console.log(error.response.status);

                       //console.log(error.response.data.message);

                       

                       setIsLoading(false);

                       setAddName("");
                       setAddFctCode("");
                       setAddBuyCode("");
       
                       setAddingMeter(false);
             


                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                         } else {


                              if(!error.response.status){

                                   toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{

                                   if (error.response.status == 401) {
               
                                        toast.error(error.response.data.message,
                                             toastError);
                                     
                                        setTimeout(() => {
                                                       logOut();
                                        }, 5000); 
                                   
                              
                                   } else {
                              
                                             toast.error(error.response.data.message, toastError)
                              
                                        
                                   }
                              }
                         }



                     }
             
             
             });
             
             
}            


const deleteMeter = (deleteID) => {



     Swal.fire({
          title: `Jeste li sigurni da želite obrisati mjerno mjesto?`,
          text: "Ova radnja se ne može poništiti",
          icon: "warning",
          iconSize: '5',

          showCancelButton: true,
          confirmButtonColor: "#e31b2e",
          cancelButtonColor: "#29648a",
          showCancelButton: true,
          confirmButtonText: "Da, obriši!",
          cancelButtonText: "Odustani",
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {

                 DeleteMeterConfirmed(deleteID)

          }
        });


};

function DeleteMeterConfirmed (meterID) {


     //console.log("Attempting delete: " + meterID);

     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         



     APIservice.DeleteMeter(meterID, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoading(false);

                                   //console.log(response.data.message);


  
                                   toast.success(response.data.message, toastSuccess);
  

  
                                   getDataBrojila();


            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoading(false);


             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                         } else {


                                   if(!error.response.status){

                                        toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                                   }else{

                                        if (error.response.status == 401) {
                    
                                             toast.error(error.response.data.message,
                                                  toastError);
                                          
                                             setTimeout(() => {
                                                            logOut();
                                             }, 5000); 
                                   
                                        } else {
                                   
                                                  toast.error(error.response.data.message, toastError)
                                   
                                             
                                        }
                                   }
                         }
                     }
             
             
             });
             
             
}       



var steps = [
     {
       target: '.menuLists',
       content: `U listi su vam prikazana sva mjerna mjesta vezana uz vaše korisničko ime. 
       Odabirom pojedinog mjernog mjesta u listi možete pregledati detaljne podatke dostupne za odabrano mjerno mjesto. Podaci o odabranom mjernom mjestu će se pojaviti na desnoj strani ekrana. 
       U svakom trenutku možete odabirom različitog mjernog mjesta u listi dobaviti podatke za sljedeće brojilo.`,
       disableBeacon: true,
       placement: "right-start"
     },
     {
       target: '.ikonaBrisi',
       content: `Ukoliko želite ukloniti određeno mjerno mjesto s vašeg korisničkog računa, to možete učiniti 
       odabirom ikone brisanja desno od imena mjernog mjesta u popisu.`,
       disableBeacon: true,
       placement: "right"
     },
     {
          target: '.addMeter',
          content: `Ukoliko želite dodati mjerno mjesto svom korisničkom računu, to možete učiniti 
          odabirom opcije "Dodaj mjerno mjesto", te unosom potrebnih podataka koje možete pronaći na bilo kojem računu izdanom za traženo mjerno mjesto.`,
          disableBeacon: true,
          placement: "right"
     },     
     {
          target: '.main-list-item1',
          content: `Više informacija o pojedinom mjernom mjestu, trenutno stanje, kao i pregled dostupnih očitanja i potrošnje možete vidjeti odabirom opcije "Stanje" u desnom izborniku`,
          disableBeacon: true,
          placement: "right"
     },     
     {
          target: '.main-list-item3',
          content: `Za informacije o izdanim računima i provedenim uplatama odaberite opciju "Račun" u izborniku. 
          Pojedini izdani račun možete pregledati tako što odaberete željeni redak u listi računa, te pri dnu ekrana odaberete opciju "Učitaj račun". Izrađeni račun se može i preuzeti na svoje računalo u pdf formatu odabirom opcije preuzimanja.`,
          disableBeacon: true,
          placement: "left"
     },     
     {
          target: '.main-list-item4',
          content: `Odabirom opcije "Analiza" možete pregledati grafičku analizu trenda potrošnje po dostupnim tarifama za odabrano mjerno mjesto.`,
          disableBeacon: true,
          placement: "right"
     },                
]


     const handleJoyrideCallback = (data) => {

          //console.log("JOyride callback");
          //console.log(data);
          const { status, type } = data;
          //const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
          if ((data.status == "finished" || data.status == "skipped")) {
            setRunTour(false);
          }

     }


     const handleJoyrideStart =()=>{

          setRunTour(true);
     }






return (
     
     
     <div className="brojila">
 
           

               <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
               />      


               <div id="container1">

                         <JoyRide 
                         run={runTour}
                         steps={steps} 
                         continuous={true} 
                         showSkipButton={true} 
                         callback={handleJoyrideCallback}
                         
                         locale={{
                              skip: "Preskoči",
                              last:"Završi", 
                              back:"Natrag",
                              next: "Dalje"}}
                              
                         styles={{
                              options: {
                                   arrowColor: "#f4f6f9",
                                   primaryColor: "#29648a",
                                   textColor: "#72a0c1",
                                   backgroundColor: "#f4f6f9",
                                   borderTopRadius: 10,
                                   borderWidth:0,
                                   width: 500,
                                   zIndex: 10000,
                                   fontSize: 14,
                                   
                              },
                              buttonNext: {
                                   backgroundColor: "#29648a",
                                   title: "Dalje",
                                   text:"Dalje",
                                   border: "none",
                                   borderRadius: 10,
                                   color: "white",
                                   fontSize: 14,
                                   "&:focus": {
                                   outline: "none !important",
                                   },
                              },
                              buttonBack: {
                                   color: "#29648a",
                                   marginLeft: "auto",
                                   marginRight: 5,
                                   fontSize: 14,
                              },
                              buttonClose: {
                                   color: "#29648a",
                                   height: 14,
                                   padding: 15,
                                   position: "absolute",
                                   right: 0,
                                   top: 0,
                                   width: 14,
                                   
                              },
                              buttonSkip: {
                                   backgroundColor: "#e31b2e",
                                   title: "Preskoči",
                                   text:"Preskoči",
                                   border: "none",
                                   borderRadius: 10,
                                   color: "white",
                                   fontSize: 14,
                                   "&:focus": {
                                   outline: "none !important",
                                   },                                   
                              }
                              }}
                         />

               </div>

           <div className="menuLists">

 
               <ul>
           
                     {brojiloListStart.map((element, index) => {
                       
                       const className=()=>{
 
                             return( index==0 ? "side-list-item sideListItemIsSelected" : "side-list-item")
 
                       }
 
                       return(
               
                             <li
                             
                                 className={className()}
                                 //id={element["id"]}
                                 id={index}
                                 onClick={(e)=>{
 
 
                                        handleItemList(element["id"]);
                                        handleSideList(element["id"]);
 
                                 }}>
                                    <div className="nameMeterList" onClick={(e)=>{
                                              
                                             
                                    }}>{element["name"]}</div>
                                    
                                    <div>
                                        <IoMdCloseCircle className="ikonaBrisi" onClick={(e)=>{
                                        
                                             //console.log("I'm gonna delete it!!!");
                                             //console.log(element["text"]);
                                             deleteMeter (element["text"]);
                                        }}/>

                                    </div>
 
                             </li>
      
 
 
                       )
                       
  
                         })}
               </ul>
 
 
               {!addingMeter && <div className="addMeter">
 
                               <button className="addMeterBtn" onClick={e=>{
                                              
 
                                              setAddingMeter(true);
                                              
                                              }}>Dodaj mjerno mjesto</button>
               </div>}
 
               {addingMeter && <div className="addMeterInfo">
 
                              <input className="addMeterName" type="text" placeholder="Unesite ime kupca" value={addName}
                             
                                   onChange={e => {
                                     
                                     setAddName(e.target.value) ;
                                     }}>
 
                             </input>                             
 
 
                             <input className="addMeterFctCode" type="text" pattern="[0-9]*" placeholder="Unesite broj brojila" value={addFctCode}
                             
                                   onChange={e => {
                                     
                                    
                                     setAddFctCode(e.target.value) ;
                                     }}>
 
                             </input>
 
                             <input className="addMeterBuyer" type="text" pattern="[0-9]*" placeholder="Unesite šifru kupca" value={addBuyCode}
                             
                                   onChange={e => {
                                     
                                     
                                     setAddBuyCode(e.target.value) ;
                                     }}>
 
                             </input>
                               <button className="addMeterInfoBtn" onClick={e=>{
                                              
 
 
                                              spremi();
                                              
                                              }}>Dodaj</button>
                                <button className="addMeterInfoBtn" onClick={e=>{
                                              
 
                                              setAddName("");
                                              setAddFctCode("");
                                              setAddBuyCode("");
 
                                              setAddingMeter(false);
                                              
                                              }}>Odustani</button>                                            
               </div>}  


                    <div style={{marginTop: 20}}><button 
													
                                                                 className='infoButton'
                                                                 onClick={() => handleJoyrideStart()}
                                                                 >
                                                                 <InfoIcon style={{fill: '#29648a', }} size="0.7x"/>
                         </button>   
                    </div>                           
           </div>
 
         
           
           <div className="container-home">

 
                   <div className="main-list">
                           <div id="stanje" className="main-list-item main-list-item1 mainListItemIsSelected" onClick={(e)=>{
                                   handlePrimaryListItem(e);
                                   getStanjeData();
                             }}>Stanje</div>
 
                           <div id="promet" className="main-list-item main-list-item3" onClick={(e)=>{
                                   handlePrimaryListItem(e);
                                   getPrometData();
                             }}>Račun</div>
                           <div id="analiza" className="main-list-item main-list-item4" onClick={(e)=>{
                                   handlePrimaryListItem(e);
                                   getAnalizaData();
                             }}>Analiza</div>
                           {/*<div id="kontak" className="main-list-item main-list-item5" style={{cursor: "not-allowed"}} onClick={(e)=>{
                                   //handlePrimaryListItem(e);
                                   //getKontaktData();
                             }}>Kontakt</div>*/}
                   </div>
 
                   <div className="main-content main-content-stanje">
                     <div className="stanje-container">
 
                           
                           <div className="stanje-body">
                                 
                                 <div className="stanje-first-column">
                                        
                                         <div className="stanje-stanje"> Stanje duga: </div> 
                                         <div className="stanje-val">{stanje} KM</div>
                                         <div className="stanje-stanje"> Ime vlasnika: </div>
                                         <div className="stanje-val">{nameOwner}</div>
                                         <div className="stanje-stanje">Adresa: </div>
                                         <div className="stanje-val">{adresa}</div>
 
                                         <div className="stanje-stanje">Šifra brojila:</div> 
                                         <div className="stanje-val">{fctCode}</div>
                                         <div className="stanje-stanje">Šifra kupca:</div> 
                                         <div className="stanje-val">{sifra}</div>
                                 </div>
                                 <div className="stanje-second-column">
                                         <div className="stanje-stanje">Datum zadnjeg očitanja: </div>
                                         <div className="stanje-val">{lastDate}</div>
                                         <div className="stanje-stanje">Tarifa R1: </div>
                                         <div className="stanje-val">{R1}</div>
                                         <div className="stanje-stanje">Tarifa R2: </div>
                                         <div className="stanje-val">{R2}</div>
                                         <div className="stanje-stanje">Korisnik </div>
                                         <div className="stanje-val">{currentUser.email}</div>
                                         {/*<div className="stanje-stanje">Tarifa R3: </div>
                                         <div className="stanje-val">{R3}</div>*/}
 
                                 </div>
                         </div>
                     </div>
 
                     <div className="main-content-potrosnja">
                           
                           <div className="potrosnja-options">
                                   <div id="listaTrosak" className="potrosnja-option potrosnja-option1 potrosnjaOptionIsSelected" onClick={(e)=>handleSubOption(e.target)}>Potrošnja</div>
                                   <div id="listaOcitanje" className="potrosnja-option potrosnja-option1" onClick={(e)=>handleSubOption(e.target)}>Očitanja</div>
                           </div>
 
                           <div className="potrosnja-body">
                                   <div  className="lista-potrosnja listaTrosak">
                                           {listaTrosak && listaTrosak.map((item, index)=> {
                                             return (
                                               <div className="potrosnja-item">
                                                       <div className="potrosnja-item-date">
                                                          {item.date}
                                                       </div>
                                                       <div className="potrosnja-item-r">
                                                             <div className="potrosnja-item-r1">
                                                                 R1:  {item["r1"]}
                                                             </div>
                                                             <div className="potrosnja-item-r2">
                                                                 R2:     {item["r2"]}
                                                             </div>
                                                             {/*<div className="potrosnja-item-r3">
                                                                 R3:     {item["r3"]}
                                                             </div>*/}
                                                       </div>
 
                                               </div>
                   
                                               )
                                               })}
                                   </div>
 
 
                                   <div  className="lista-potrosnja listaOcitanje isHidden">
                                             {listaOcitanje && listaOcitanje.map((item, index)=> {
                                                   return (
                                                     <div className="potrosnja-item">
                                                           <div className="potrosnja-item-date">
                                                                 {item.date}
                                                           </div>
                                                           <div className="potrosnja-item-nacin">
                                                                 Nacin:&nbsp;{item.name}&nbsp;&nbsp;  Obracunato:&nbsp;{item.obrac}
                                                            </div>

                                                           <div className="potrosnja-item-r">
                                                               <div className="potrosnja-item-r1">
                                                                   R1:     {item["r1"]}
                                                               </div>
                                                               <div className="potrosnja-item-r2">
                                                                   R2:     {item["r2"]}
                                                               </div>
                                                               {/*<div className="potrosnja-item-r3">
                                                                   R3:     {item["r3"]}
                                                                  </div>*/}
                                                           </div>
 
                                                     </div>
                                                     
                                                   )
                                                 })}
                                   </div>
                           </div>
           
 
                   </div>
                          
                           
                   </div>
 
                   
 
                     <div className="main-content main-content-promet isHidden">
                          <div className="promet-container">      
                          {/*-------------------------------------*/}

                                                 
                              <div>
                               <div className="potrosnja-options">
                                         <div id="listaRacuni" className="promet-option potrosnja-option1 prometOptionIsSelected" onClick={(e)=>handleSubOptionPromet(e.target)}>Računi</div>
                                         <div id="listaUplata" className="promet-option potrosnja-option1" onClick={(e)=>handleSubOptionPromet(e.target)}>Uplate</div>
                               </div>
                          {/*-------------------------------------*/}
 
                          {/*-------------------------------------*/}
 
 
                               <div className="potrosnja-body">
                                    <div  className="lista-racun listaRacuni listaTrosak">
                                           {BrojiloListOwed && BrojiloListOwed.map((item, index)=> {
                                             return (
                                               <div className="racun-item" onClick={e=>{
                                                            setRacunIsVisible(true)
 
                                                             
                                                             //setActBillDate(item.date);
                                                             setActBillDate(item.dateOwed);
                                                             setActBillOwed(item.owed);
                                                             setActBillPaid(item.paid);
                                                             setActBillDue(item.dospijece);
                                                             setActBillPNB(item.pnb);
 
                                                             //setBillBack("red");
   
 
 
 
                                              }}> 
                                                       <div className="racun-item-date">
                                                         {item.name}
                                                       </div>
                                                       <div className="racun-item-r">
                                                             <div className="racun-item-r1">
                                                                  Datum: {item.dateOwed}
                                                             </div>
                                                             <div className="racun-item-r2">
                                                                 Iznos:     {item.owed} KM
                                                             </div>
                                                       </div>
 
                                               </div>
                   
                                               )
                                               })}
                                    </div>
 
 
 
                                    <div  className="lista-racun listaUplata listaOcitanje isHidden">
                                             {BrojiloListPaid && BrojiloListPaid.map((item, index)=> {
                                                   return (
                                                     <div className="potrosnja-item" >
                                                           <div className="potrosnja-item-date">
                                                                 {item.name}
                                                           </div>
                                                           <div className="potrosnja-item-r">
                                                               <div className="potrosnja-item-r1">
                                                                    Datum:  {item.date}
                                                               </div>
                                                               <div className="potrosnja-item-r2">
                                                                   Iznos:     {item.owed} KM
                                                               </div>
 
                                                           </div>
 
                                                     </div>
                                                     
                                                   )
                                                 })}
                                    </div>
 
                                     {/*-------------------------------------*/}
                               </div>
                          </div>   
 
                          </div>
                          {!showBill && !isLoadingBill && 
                          <div className="racun-modal-background" style={{display: racunModalIsVisible ? "block" : "none"}}>
                          
                              <div className="racun-modal" style={{display: racunModalIsVisible ? "block" : "none"}}>
                                   <button 
                                        
                                        className='racun-modal-close'
                                        onClick={() =>{
                                             
                                             setRacunIsVisible(false);}}
                                        >
                                        <CloseIcon style={{fill: '#29648a', }} size="0.5x"/>
                                   </button>   
               
               
                              
                                   <div className="racun-container">
                                             <div className="racun-info">
                                                       <div className="stanje-stanje">Datum:  <div className="stanje-value">{actBillDate} </div></div> 
                                                       
                                                       <div className="stanje-stanje">Iznos:  <div className="stanje-value">{actBillOwed} KM</div></div> 
                                                       
                                                       {/*<div className="stanje-stanje">Račun </div> 
                                                       <div className="stanje-value">{actBillPaid} </div>*/}
                                                       <div className="stanje-stanje">Datum dospijeća: <div className="stanje-value">{actBillDue} </div></div> 
                                                       
                                                       <div className="stanje-stanje">Poziv na broj: <div className="stanje-value">{actBIllPNB} </div></div> 
                                                       
                                             </div>  
          
                                             <div className="racun-action">
                                                  {/*<button className="racun-plati" onClick={e=>{
                                                       
                                                            if(stanje < 0 && (actBillPaid =="Račun nije podmiren")){
          
                                                                 alert("Nije podešeno sučelje za plaćanje računa");
                                                            }else{
          
                                                                 alert("Traženi račun je već plaćen");
                                                            }
                                                            
                                                            
                                                            }}>Plati</button>*/}
                                                  <button className="racun-details" onClick={e=>{
                                                            setRacunIsVisible(false)
                                                            //setIsLoadingBill(true);
                                                            convertPDF (buyerCode, actBIllPNB);
                                                            }}>Učitaj račun</button>
          
                                                  {/*<button className="racun-plati-saldo" onClick={e=>{
                                                       
                                                       if(stanje < 0){
          
                                                            alert("Nije podešeno sučelje za plaćanje zaostataka");
                                                       }else{
          
                                                            alert("Ne postoje zaostatci za odabrano mjerno mjesto");
                                                       }
                                                       
                                                       }}>Plati sve zaostatke</button>*/}
          
          
                                             </div>
          
                                   </div> 
                                             

                         </div>
                         </div>
}
     
                         {/*} {showBill && !isLoadingBill &&
                              <div className="racun-container">
                                        <button className="racun-PDF" onClick={e=>{
                                             setShowBill(false);
     
                                             }}>Zatvori</button>                        
                                        <object data={pdfLink} type="application/pdf" width="100%" height="100%">
                                                       <p><a href={pdfLink}></a></p>
                                        </object>
                                             </div>}*/}

                              {showBill && !isLoadingBill && //second modal
                              <div className="pdf-modal-background" style={{display: pdfModalIsVisible ? "block" : "none"}}>
                                   <div style={{display: pdfModalIsVisible ? "block" : "none"}} className="pdf-modal">
                                             <button 
                                                                      
                                                  className='racun-modal-close'
                                                  onClick={() => {
                                                       setRacunIsVisible(false);
                                                       setPdfIsVisible(false);                                                       setShowBill(false)
                                                       setShowBill(false)

                                                  }}
                                                  >
                                                  <CloseIcon style={{fill: '#29648a', }} size="0.5x"/>
                                             </button>   
               
               
                                            {/*

                                                  <button className="racun-PDF" onClick={e=>{
                                                  setShowBill(false);
          
                                                  }}>Zatvori</button>   
                                             */} 
                                             <div className="racun-buttons">

                                                  <button className="racun-PDF" onClick={e=>{
                                                       //console.log("Attempting dl");
                                                       downloadAsPDF(currentPDF);
                                                       setShowBill(false)
          
                                                  }}><IoCloudDownload fill="#29648a"/></button> 
                         
                                             </div>
                                                  <div>
                                                  <Document
                                                  className={'react-pdf__Page__canvas-container'}
                                                  file={`data:application/pdf;base64,${currentPDF}`}
                                                  onLoadSuccess={onDocumentLoadSuccess}
                                                  >
                                                            <Page pageNumber={1} 
                                                            renderAnnotationLayer={false} 
                                                                 renderTextLayer={false}
                                                                 
                                                                 />
                                                  </Document> 
                                                  </div>

                                   </div>
                              </div>
                              }

                             
     
                              {isLoadingBill && <Loader />}


                              {/*</div>*/}
                         {/*} </div>          */}             
                          
           
 
                   </div>
  
 
 
 
                   <div className="main-content main-content-analiza isHidden">
                         <div className="analiza-content">
                         
                         <div className="brojila-selector">
                          <select className="brojila-selector-select" name="basic" id="ex-basic" 
                                    value={activeYearName} 
                                    onChange={(e)=>{
 
                                    }}>
 
 
 
 
                                    {yearList && yearList.map(item=>{
                                         return (
                                         <option value={item["name"]} id={item["value"]} onClick={(e)=>{
                                                   
                                                   var a = e.target.value;
                                                   
                                                   var b = e.target.id;
                                                   
 
                                                   setActiveYearName(a); 
                                                   setActiveYear(b);
                                                   
                                                   setRDefault();
 
                                                   filterYear(b, "R1");
                                                   
                                                   
 
                                         }}>{item["name"]}</option>
                                         )
                                    })}
                          </select>
                       </div>
 
                              <div style={{border: "1.5px solid #9dadba", borderRadius: 10, marginBottom: '20px'}}>
                                 <div className="analiza-options">
                                       <div id="listaTrosak" className="analiza-option analiza-option1 analizaOptionIsSelected" onClick={(e)=>{
                                                         handleSubOptionAnaliza(e.target); 
                                                         //createDataToGraphs(listaTrosak,"r1");
                                                         filterYear(activeYear, "R1");
                                                         
                                                         }}> R1 </div>
                                       <div id="listaOcitanje" className="analiza-option analiza-option2" onClick={(e)=>{
                                                         handleSubOptionAnaliza(e.target); 
                                                         //createDataToGraphs(listaTrosak,"r2");
                                                         filterYear(activeYear, "R2");
                                                         }}> R2 </div>
                                       {/*<div id="listaOcitanje" className="analiza-option analiza-option3" onClick={(e)=>{
                                                         handleSubOptionAnaliza(e.target); 
                                                        
                                                         filterYear(activeYear, "R3");
                                                         }}> R3 </div>*/}
                                 </div>
 
                                   <div style={{margin: "10px"}}>
                                        
                                        <Line
                                         style={{height: '100%',
                                             width: '100%'}}
                                         data={state}
                                         
                                         options={{

                                             transitions: {
                                                  show: {
                                                    animations: {
                                                      x: {
                                                        from: 0
                                                      },
                                                      y: {
                                                        from: 0
                                                      }
                                                    }
                                                  },},
                                             plugins:{
                                                  title: {
                                                       display: false,
                                                       text: "Potrošnjaaaa"
                                                     },
                                                  tooltip: {
                                                       callbacks: {
                                                         //title: function () {
                                                         //  return "my tittle";
                                                         //}
                                                       },
                                                       display: true,
                                                       enabled: true,
                                                  },
                                                  legend: {
                                                       display: false
                                                     }

                                             },

                                             responsive: true,
                                           scales: {
                                             y: 
                                               {
                                                 /*ticks: {
                                                   suggestedMin: -20,
                                                   suggestedMax: 100,
                                                   display: true,
                                                 }*/

                                                 ticks: {
                                                  //autoSkip: true,
                                                  display: true,
                                                  maxTicksLimit: 20,
                                                  },
                                                  display: true,
                                                  scaleLabel: {
                                                      //display: true,
                                                      //labelString: "Point"
                                                  }
                                               }
                                             
                                           }
                                         }}
                                        />

                                   </div>

                                   </div>



 
                         </div>
                   </div>
 
                   {/*<div className="main-content main-content-kontak isHidden">
                           Kontakt
                                    </div>*/}
 
 
           </div>
         
     </div>
     );
 
 }
 
 
 
 export default Brojila;