import React from 'react';
import{Link} from 'react-router-dom';
import './css/NavigationBar.css'

function NavigationBarAdmin(){


    function handleMenu(e) {
        var x = Array.from(document.querySelectorAll(".menu-item")).map(item=>{
          item.classList.remove("menuItemIsSelected")
        })
        e.target.parentElement.classList.add("menuItemIsSelected")
      }
     
    return(
        <div className="containerNav">
       
            <Link className="menu-item menu-item-1 link" onClick={(e)=>{ handleMenu(e)}} to = "/pocetna" >
                <div>Planirana isključenja</div>
            </Link>

            <Link  className="menu-item menu-item-2 link" onClick={(e)=>{ handleMenu(e)}}  to ="/brojilaAdmin" >
                <div>Mjerna mjesta</div>
            </Link>
           
            <Link  className="menu-item menu-item-3 link" onClick={(e)=>{ handleMenu(e)}}  to ="/postavke" >
                <div>Postavke</div>
            </Link>

            <Link  className="menu-item menu-item-4 link" onClick={(e)=>{ handleMenu(e)}}  to ="/upload" >
                <div>Dokumenti</div>
            </Link>
            
          

           
        </div>
    );

}
export default NavigationBarAdmin;