import axios from "axios";
import { version } from "react";


import { APIbase, APPversion, APPid, APPplatform } from '../variables.js';


const login = (username, password, attempt, cancelTkn) => {

    const data = {
        email: username,
        pass: password,
        version: APPversion,
        platform: APPplatform,
        attempt: attempt

    }

    return axios.post(APIbase + 'LoginWeb',
    {data: data},
    { cancelToken: cancelTkn }).then(res => {


        let tmp = res.data.user;


        if (res.data.token) {

            

            let user = {
                email: '', role: '0', token: ''
            };

            //console.log("User: ");
            //console.log(res.data.user);


            user.token = res.data.token;
            user.email = res.data.user.email;

            user.role = res.data.user.role;

            tmp = user;
            
            localStorage.setItem('user', JSON.stringify(user));


        }


        return tmp;
    });



}



const logout = () => {
    localStorage.removeItem("user");
    window.location.replace(`${window.location.origin}`);
};


export default {
    login,
    logout,
};
