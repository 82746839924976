import  React, {Component }from 'react';
import  { useState,useEffect } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';

import  { Alert} from 'react';

import  './css/pocetnaOut.css';

import  Logo from './icons/slika_logo.png';

import Logo2 from './icons/EP.png';


import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {ReactComponent as DeleteIcon} from '../img/close-circle.svg';


import APIservice from "./services/api.service";

import Loader from "./Loader";

//import { history } from "../helpers/history"; this is obsolete

import { useNavigate } from 'react-router-dom';


import JoyRide from "react-joyride";
import Login from "./Login"
import Register from "./Register"
import ResetPass from "./ResetPass"
import {ReactComponent as UserIcon} from './../img/person-circle.svg';
import {ReactComponent as RegisterIcon} from './../img/register.svg';
import {ReactComponent as ResetPasswordIcon} from './../img/reset-password.svg';

function PocetnaOut(props) {


    const [list,setList]=useState([]);
    const [options, setOptions] = useState([])
    const [data, setData] = useState([])
    const [selectorOption, setSelectorOption] = useState([]);
    
    const [isLoading, setIsLoading] = useState(true);
    const [pickerChange, setPickerChange] = useState("");



    const [platform, setPlatform] = useState("3");

    const [runTour, setRunTour] = useState(false);

    const [widthScreen, setWidthScreen] = useState(window.innerWidth);
    const history = useNavigate();
  
    const [showLogIn, setShowLogIn] = useState(true)
    const [showRegister, setShowRegister] = useState(false)
    const [showResetPassword, setShowResetPassord] = useState(false);

    const [privacyIsOpen, setPrivacyIsOpen] = useState(false);

    const [viewSignIn, setViewSignIn] = useState(false);
   
    const toastError = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };

     const toastSuccess = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };

     const toastInfo = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };

     const [searchParams, setSearchParams] = useSearchParams();

    
useEffect(() => {

     console.log(searchParams.get('login'));

     console.log(searchParams.get('privacy'));

     if(searchParams.get('privacy')){

          setPrivacyIsOpen(true);
     }

     if(searchParams.get('login')){
          setViewSignIn(true);
     }

     getData();


}, []);






function getData () {
     let unmounted = false;
     
     let source = axios.CancelToken.source();

     setIsLoading(true);

     
     APIservice.GetDistListOut(source.token).then(

          (response) => {

               if(!unmounted) {

                    //console.log(response.data);

                    setIsLoading(false);



                    var dataTmp = response.data;


                    if(dataTmp.distLocations.length !=0){

                              var arrDist = [];

                              var inty2 = 0;

                              for(var propT in dataTmp.distLocations) {


                                        var areaNameTmp = "";
                                        var areaCodeTmp = "";


                                        for(var propT2 in dataTmp.distLocations[propT]){


                                                  if(propT2 === 'areaName'){

                                                       areaNameTmp = dataTmp.distLocations[propT][propT2];



                                                  }else if (propT2 === "areaCode"){

                                                       areaCodeTmp = dataTmp.distLocations[propT][propT2];





                                                  } else{

                                                       //nema ostalih


                                                  }


                                        }



                                        arrDist.push({"name": areaNameTmp, "code": areaCodeTmp, "id":inty2});
                                        inty2 = inty2+1;



                              }



                              setOptions(arrDist);

                              let areaNameToShow = '';

                              if(dataTmp.distList.length != 0){


                                        var arrDistUser = [];

                                        var inty = 0;




                                        for(var propT in dataTmp.distList) {


                                                  var areaNameTmp = "";
                                                  var areaCodeTmp = "";
                                                  var actDescTmp = "";
                                                  var actCodeTmp = 0;
                                                  var startTmp = "";
                                                  var endTmp = "";

                                                  //console.log("Activity: ");
                                                  //console.log(dataTmp.distList[propT]);

                                                  for(var propT2 in dataTmp.distList[propT]){



                                                            if(propT2 === 'areaName'){

                                                                 areaNameTmp = dataTmp.distList[propT][propT2];

                                                                 areaNameToShow = dataTmp.distList[propT][propT2];



                                                            }else if (propT2 === "areaCode"){

                                                                 areaCodeTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "actDesc"){

                                                                 actDescTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "actCode"){

                                                                 actCodeTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "start"){

                                                                 startTmp = dataTmp.distList[propT][propT2];

                                                            }else if (propT2 === "end"){

                                                                 endTmp = dataTmp.distList[propT][propT2];


                                                            } else{

                                                                 //nema ostalih


                                                            }


                                                  }





                                                  arrDistUser.push({"name": areaNameTmp, "code": areaCodeTmp, "start": startTmp, "end": endTmp,
                                                                 "actDesc": actDescTmp, "actCode": actCodeTmp, "id":inty});


                                                  inty = inty+1;


                                        }




                                        setPickerChange(areaNameToShow);

                                        let tmpArr = arrDistUser.reverse();

                                        setList(tmpArr);



                              
                              
                              }else{




                                        setPickerChange('');


                                        setList([]);


                              }



                    }else{

      
                              toast.info("Nema dostupnih distribucijskih područja", toastInfo);

                              setOptions([]);

                              setList([]);

                              setPickerChange('');

                    }

               }
          }, 
          (error) => {
 
               if (!unmounted) {
       

                 //console.log(error);



                 setIsLoading(false);

                 setOptions([]);

                 setList([]);

                 setPickerChange('');


                    if(!error.response){

                         toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                    } else {

  
                         if(!error.response.status){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                         }else{
          
                              if (error.response.status == 401) {
                    
                                   toast.error(error.response.data.message, toastError)
                         
                    
                              } else {
                    
                                   toast.error(error.response.data.message, toastError)                    
                              
                              }
                         }
                    }
               }
       
       
          }

     )
}






function getDataOne (chosenAreaCode, chosenAreaName) {
     
     let unmounted = false;
     
     let source = axios.CancelToken.source();

     setIsLoading(true);

     setList([]);

     //console.log("Data: " + chosenAreaCode + ", " + chosenAreaName);

     
     APIservice.GetDistOneOut(chosenAreaCode, source.token).then(

          (response) => {

               if(!unmounted) {

                    //console.log(response.data);

                    setIsLoading(false);


                    var dataTmp = response.data;


                    if(dataTmp.distLocations.length !=0){

                              var arrDist = [];

                              var inty2 = 0;

                              for(var propT in dataTmp.distLocations) {


                                        var areaNameTmp = "";
                                        var areaCodeTmp = "";


                                        for(var propT2 in dataTmp.distLocations[propT]){


                                                  if(propT2 === 'areaName'){

                                                       areaNameTmp = dataTmp.distLocations[propT][propT2];



                                                  }else if (propT2 === "areaCode"){

                                                       areaCodeTmp = dataTmp.distLocations[propT][propT2];





                                                  } else{

                                                       //nema ostalih


                                                  }


                                        }



                                        arrDist.push({"name": areaNameTmp, "code": areaCodeTmp, "id":inty2});

                                        inty2 = inty2+1;



                              }




                              setOptions(arrDist);


                              if(dataTmp.distList.length != 0){


                                        var arrDistUser = [];

                                        var inty = 0;

                                        //console.log("Activity: ");
                                        //console.log(dataTmp.distList[propT]);


                                        for(var propT in dataTmp.distList) {


                                                  var areaNameTmp = "";
                                                  var areaCodeTmp = "";
                                                  var actDescTmp = "";
                                                  var actCodeTmp = 0;
                                                  var startTmp = "";
                                                  var endTmp = "";

                                                  for(var propT2 in dataTmp.distList[propT]){


                                                            if(propT2 === 'areaName'){

                                                                 areaNameTmp = dataTmp.distList[propT][propT2];



                                                            }else if (propT2 === "areaCode"){

                                                                 areaCodeTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "actDesc"){

                                                                 actDescTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "actCode"){

                                                                 actCodeTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "start"){

                                                                 startTmp = dataTmp.distList[propT][propT2];

                                                            }else if (propT2 === "end"){

                                                                 endTmp = dataTmp.distList[propT][propT2];


                                                            } else{

                                                                 //nema ostalih


                                                            }


                                                  }





                                                  arrDistUser.push({"name": areaNameTmp, "code": areaCodeTmp, "start": startTmp, "end": endTmp,
                                                                 "actDesc": actDescTmp, "actCode": actCodeTmp, "id":inty});


                                                  inty = inty+1;


                                        }



            

                                        let tmpArr = arrDistUser.reverse();

                                        setList(tmpArr);
      
      
                                        setPickerChange(chosenAreaName);

                              
                              
                              }else{



                                        setPickerChange(chosenAreaName);

                                        setList([]);




                              }



                    }else{

      
                              toast.info("Nema dostupnih distribucijskih područja", toastInfo);

                              setOptions([]);

                              setList([]);

                              setPickerChange('');

                    }

               }
          }, 
          (error) => {
 
               if (!unmounted) {

       
                    console.error(error);

                    setIsLoading(false);

                    setList([]);

                    setPickerChange('');

                    if(!error.response){

                         toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                    } else {

       
                         if(!error.response.status){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                         }else{
          
                              if (error.response.status == 401) {
                    
                                   toast.error(error.response.data.message, toastError)
                         
                    
                              } else {
                    
                                   toast.error(error.response.data.message, toastError)
                    
                              
                              }
                         }
                    }
               }
       
       
          }

     )
}



     const handleCloseLoginModal = () => {

          //console.log("Closing the modal!");
     
     
          setViewSignIn(false);
     
     }


return (
     <div className="pocetnaOut">


               <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
               />   

               {viewSignIn && <div className="pocetnaOut-modal">
                    <div className="pocetnaOut-modal-content">
                              <button 
													
                                        className='menuOut-closeSignIn'
                                        onClick={() => handleCloseLoginModal()}
                                        >
                                        <DeleteIcon style={{fill: '#29648a', }} size="1x"/>
                              </button>                            

                         <div className="pocetnaOut-modal-menu">
                              <div className="pocetnaOut-modal-menu-logIn" style={{backgroundColor: showLogIn? "#93aece": "unset"}}>
                                        <button className='pocetnaOut-modal-menu-logIn-button' style={{backgroundColor: showLogIn ? "#93aece": "unset"}}
                                                
                                                  onClick={() => {
                                                       setShowLogIn(true);
                                                       setShowRegister(false);
                                                       setShowResetPassord(false);

                                                  }}
                                        >
                                                  <UserIcon style={{fill: 'white', }} size="2x"/>
                                                  <div className="pocetnaOut-modal-menu-logIn-label">
                                                       Prijava
                                                  </div>
                                        </button>    
                              </div>
                              <div className="pocetnaOut-modal-menu-register"  style={{backgroundColor: showRegister ? "#93aece": "unset"}}>
                                        <button className='pocetnaOut-modal-menu-register-button'   style={{backgroundColor: showRegister ? "#93aece": "unset"}}
                                                  onClick={() => {
                                                       setShowLogIn(false);
                                                       setShowRegister(true);
                                                       setShowResetPassord(false);
                                                  }}
                                        >
                                                  <RegisterIcon style={{fill: 'white', }} size="2x"/>
                                                  <div className="pocetnaOut-modal-menu-register-label">
                                                       Registracija
                                                  </div>
                                        </button>    
                              </div>
                              <div className="pocetnaOut-modal-menu-resetPass"  style={{backgroundColor: showResetPassword ? "#93aece": "unset"}}>
                                        <button className='pocetnaOut-modal-menu-resetPass-button'  style={{backgroundColor: showResetPassword ? "#93aece": "unset"}} 
                                                  onClick={() => {
                                                       setShowLogIn(false);
                                                       setShowRegister(false);
                                                       setShowResetPassord(true);

                                                  }}
                                        >
                                                  <ResetPasswordIcon style={{fill: 'white', }} size="2x"/>
                                                  <div className="pocetnaOut-modal-menu-resetpass-label">
                                                       Zaboravili ste lozinku
                                                  </div>
                                        </button>    
                              </div>
                         </div>
                         <div className="pocetnaOut-modal-forms">

 
                                          

                              {showLogIn && <Login />}
                              {showRegister && <Register />}
                              {showResetPassword && <ResetPass />}


                         </div>

                    </div>
               </div>}
               <div className="menuOut">
              
                                        <div className="logoMenuOut">
     
     
                                                  <img src={widthScreen>750 ? Logo : Logo2} alt="Logo"
                                                            width={widthScreen<750 ? "150px" : ""}/>
                                                                             
                                             </div>



    

                                        
                                                                     
     
     
                                        <button className="button-Out" onClick={e=>{
                                                  
                                                            //history("/login");

                                                            setViewSignIn(true);

                                                            //setRunTour(true);
                                                  
                                                            }}>Prijava
                                        </button>                                                                         
                                                     
     
               </div>
     

     
     
               <div className="pocetnaOut-containerOut">
             
     
     
                         <div className="pocetnaOut-titleOut">Planirani radovi i isključenja u mreži u narednih 48h</div>
     
     
                         <div className="pocetnaOut-selectorOut">
                              <select name="basic" id="ex-basic" 
                                        value={selectorOption} 
                                        onChange={(e)=>{
     
     
                                             let index = e.target.selectedIndex;
                                             let optionElement = e.target.childNodes[index];
                                             let optionElementId = optionElement.getAttribute('id');
               
                                             let a = optionElement.getAttribute('value');
                                             let b = optionElementId;
                                              
               
                                             //console.log("Index " + index);
                                             //console.log("Option: " + optionElement);
                                             //console.log("ID: " + optionElementId);
     
     
     
                                             setSelectorOption(a); 
                                             setPickerChange(a);
     
     
                                        
                                        
                                             //getDataOne("", platform, b, a);
                                             getDataOne(b, a);
         
     
     
                                        }}>
     
                                        <option value="" disabled hidden>Odaberite distribucijsko područje</option>
                                        {options && options.map(item=>{
                                             return (
                                             <option value={item["name"]} id={item["code"]} onClick={(e)=>{
                                           
                                             }}>{item["name"]}</option>
                                             )
                                        })}
                              </select>
                         </div>
     
                         <div className="pocetnaOut-listOut">
                              <div className="item">
                                        <div className="descriptionOut-nameOut">Ime</div>
                                        <div className="descriptionOut-actDescOut">Opis</div>
                                   <div className="date">
                                        Datum
                                   </div>
                              </div>
                              {list && list.map(item=>{
                              return(
                              <div className="item">
                                             <div className="descriptionOut-nameOut">{item["name"]}</div>
                                             <div className="descriptionOut-actDescOut">{item["actDesc"]}</div>
                                        <div className="date">
                                             <div className="startOut">Početak: {item["start"]}</div>
                                             <div className="endOut">Kraj: {item["end"]}</div>
                                        </div>
                              </div>
     
     
     
     
                              )
                              })}
                              {!list &&
                                   <div>Trenutno nema informacija o radovima i isključenjima u mreži</div>
                              }
                         </div>
     
               </div>
     
               <div className="pocetnaOut-privacy-userdata">
                    <div className="pocetnaOut-privacy" onClick={()=> {
                         console.log("privacy");
                         setPrivacyIsOpen(!privacyIsOpen);
                    }}>
                              Politika privatnosti
                    </div>



               </div>
               {privacyIsOpen && <div className="pocetnaOut-privacy-container" style={{right: !privacyIsOpen ? -1000 : 8}}>
               <div className="pocetnaOut-privacy-close" onClick={()=> {
                         setPrivacyIsOpen(false);
                    }}>
                         <div className="pocetnaOut-privacy-close-icon"> 
                              <DeleteIcon style={{fill: '#29648a', }} size="1x"/>
                         </div>
                         
                    </div>
                         <div className="pocetnaOut-privacy-text">
                                             <p>POLITIKA PRIVATNOSTI</p>

                                             <p>INFORMACIJE KOJE PRIKUPLJAMO
                         Informacije koje sustav prikuplja služe u svrhu pružanja usluge pregleda informacija dostupnih na sustavu https://korisnicka.ephzhb.ba.
                         Informacije koje se prikupljaju uključuju nužne podatke (e-mail adresa s kojom se morate registrirati na usluzi da bi mogli koristiti sve funkcije usluge) te opcionalne podatke (ime, prezime, adresa i broj telefona) koje korisnik može svojevoljno unijeti unutar sustava, a koji služe za personalizaciju usluge.
                         </p>
                         <p>
                         NAČIN PRIKUPLJANJA PODATAKA
                         Vaše osobne podatke, ili podatke vaše tvrtke, prikupljamo putem formi za registraciju, kontakt formi, te formi za osobne korisničke postavke. Korisnici svojevoljno ispunjavaju navedene forme kako bi se registrirali na uslugu ili poslali upite korisničkoj službi. 
                         Informacije koje dobijemo putem vašeg uređaja uključuju kolačiće nužne za prijavu na sustav, vrstu web preglednika koju koristite, te, u slučaju mobilnih aplikacija, operativni sustav uređaja koji pristupa na uslugu.
                         </p>
                         <p>
                         KORIŠTENJE PRIKUPLJENIH PODATAKA
                         Podatke prikupljene tijekom vašeg korištenja sustava koristimo da bi vam ispravno odgovorili na zahtjeve za pregledom i/ili uređivanjem vaših podataka na sustavu https://korisnicka.ephzhb.ba.
                         </p>
                         <p>
                         PRISTUP I AŽURIRANJE VAŠIH OSOBNIH PODATAKA
                         Prilikom korištenja usluge vam pružamo uvid u sve vaše osobne podatke koje imamo na sustavu. U slučaju potrebe za ažuriranjem vaših podataka omogućujemo vam da vaše podatke izmijenite ili obrišete.
                         Vaše osobne informacije se ne dijele s tvrtkama ni organizacijama izvan sustava https://korisnicka.ephzhb.ba. Korisnički podaci koji nisu nužni za korištenje usluge se mogu brisati unutar sustava ažuriranjem svojih podataka.
                         Korisnički račun možete obrisati slanjem zahtjeva prema korisničkoj službi preko formulara koji vam je dostupan unutar sustava. Korisnički račun se, u tom slučaju, briše u potpunosti sa sustava, te će biti potrebna nova registracija navedenog korisnika za ponovno korištenje usluge.
                         </p>
                         <p>
                         SIGURNOST INFORMACIJA
                         Serveri na usluzi https://korisnicka.ephzhb.ba koriste komercijalne SSL certifikate za pristup usluzi, te su vaši podaci pri komunikaciji klijentskih aplikacija i servera enkriptirani i zaštićeni.
                         </p>
                         <p>
                         IZMJENA POLITIKE PRIVATNOSTI
                         Svaka izmjena u politici privatnosti će biti adekvatno ažurirana na ovoj stranici.
                         </p>
                         </div>
               </div>}
     

     
          </div>
         );
     }
     
     
     
     export default PocetnaOut;