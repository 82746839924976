export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_SESS = "SET_CLEAR_SESS";
export const CLEAR_SESS = "CLEAR_SESS";
export const SET_tempName = "SET_tempName";
export const CLEAR_tempName = "CLEAR_tempName";

