import React from 'react';

import  { useState,useEffect, useRef, Linking } from 'react';

import { Link } from 'react-router-dom';

import './css/FileDownload.css';



import { useDispatch, useSelector } from "react-redux";

import { logout } from "../actions/auth";



import axios from 'axios';

import APIservice from "./services/api.service";

import { APIbase, APPversion, APPid, APPplatform } from './variables.js';

import authHeader from "./services/auth-header";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {ReactComponent as ViewIcon} from '../img/arrow-right.svg';

import {ReactComponent as AppleIcon} from '../img/logo-apple-appstore.svg';
import {ReactComponent as GoogleIcon} from '../img/logo-google-playstore.svg';

import JoyRide from "react-joyride";

import {ReactComponent as InfoIcon} from '../img/information-circle.svg';


function FileDownload(){

	const [selectedFile, setSelectedFile] = useState();

	const [documentName, setDocumentName] = useState("");

	const [typeDoc, setTypeDoc] = useState("");

	const [descDoc, setDescDoc] = useState("");

        const [isSelected, setIsSelected] = useState(false);

	const [docs, setDocs] = useState([]);



	const [isLoading, setIsLoading] = useState(true);


        const [tempType, setTempType] = useState("");

        const [documentLink, setDocumentLink] = useState("");
        const [documentLinkDL, setDocumentLinkDL] = useState("");
        
        const [showDocument, setShowDocument] = useState(false);
        const [selectedDocName, setSelectedDocName] = useState(""); 
	const [selectedDocCode, setSelectedDocCode] = useState(""); 
	const [selectedDocDesc, setSelectedDocDesc] = useState(""); 
    
        const [selectedDocType, setSelectedDocType] = useState("");

        const [documentExist, setDocumentExist] = useState(false);

        const [apkLink, setApkLink] = useState('');
    
  
  
        const { user: currentUser } = useSelector((state) => state.auth);


        const [runTour, setRunTour] = useState(false);

        const dispatch = useDispatch();

        const toastError = {                                                        
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
        };

        const toastSuccess = {                                                        
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
        };

        const toastInfo = {                                                        
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
        };


useEffect(() => {




        //setApkLink(APIbase + 'ApkDownload' + '?fileID=apk&fileType=apk&fileName=EPHZHB.apk');
        

        getData();

        
        const allMenuTabs =  Array.from(document.querySelectorAll(".menu-item"));
        allMenuTabs.map((tab, index)=> {
             tab.classList.remove("menuItemIsSelected")
        })
        document.querySelector(".menu-item-5").classList.add("menuItemIsSelected")


}, []);

	
 



const logOut = () => {
    
    dispatch(logout());
};





function getData() {


    let unmounted = false;
    let source = axios.CancelToken.source();

    setIsLoading(true);
        
    APIservice.GetDocuments(source.token).then(

            (response) => {

                    if (!unmounted) {
            
                                  //console.log(response);


                                  setIsLoading(false);

                                  //console.log(response.data.docList);

                                  var dataTmp = response.data;


                                  if(dataTmp.docList.length !=0){


                                            var arrDoc = [];
        
                                            var inty2 = 0;

                                            for(var propT in dataTmp.docList) {


                                                    var docNameTmp = "";
                                                    var docCodeTmp = "";
                                                    var docDescTmp = "";
                                                    var docLocTemp = "";
                                                    var docTypeTemp = "";


                                                    for(var propT2 in dataTmp.docList[propT]){


                                                            if(propT2 === 'fileName'){

                                                                docNameTmp = dataTmp.docList[propT][propT2];


                                                            

                                                            }else if (propT2 === "fileCode"){

                                                                docCodeTmp = dataTmp.docList[propT][propT2];

                                                            

                                                            }else if (propT2 === "docDesc"){

                                                                docDescTmp = dataTmp.docList[propT][propT2];

                                                            

                                                            }else if (propT2 === "docType"){

                                                                docTypeTemp = dataTmp.docList[propT][propT2];



                                                            } else{

                                                                //nema ostalih


                                                            }


                                                    }





                                                    arrDoc.push({"name": docNameTmp, "code": docCodeTmp, "desc": docDescTmp, 
                                                    "type":docTypeTemp, "id":inty2});

                                                    inty2 = inty2+1;

                                            }

                                            //console.log("Ok response: " + arrDoc);
                                            //console.log("ArrDoc: " + arrDoc[0].code);

                                            setDocs(arrDoc);

                                            setSelectedDocCode(arrDoc[0].code);
                                            setSelectedDocName(arrDoc[0].name); 
                                            setSelectedDocDesc(arrDoc[0].desc);
                                            
                                            setSelectedDocType(arrDoc[0].type);

                                            setDocumentLink(APIbase + 'FileViewer' + '?fileID=' + 
                                            arrDoc[0].code + '&fileType=' + arrDoc[0].type + '&fileName=' + arrDoc[0].name);

                                            setDocumentLinkDL(APIbase + 'DocumentViewer' + '?fileID=' + 
                                            arrDoc[0].code + '&fileType=' + arrDoc[0].type + '&fileName=' + arrDoc[0].name);

                                            //console.log("Donwloading file 1");

                                            //getFileDownload(arrDoc[0].code, arrDoc[0].type);


                                            setIsLoading(false);


                                            setDocumentExist(true);

         
                                            
                   
                                       
                                  }else{

     


                                            setDocs([]);
                                            setSelectedDocCode("");
                                            setSelectedDocName(""); 
                                            setSelectedDocDesc("");
                                            
                                            setSelectedDocType("");

                                            setDocumentLink("");		
                                            setDocumentLinkDL("");

                                            setDocumentExist(false);

                                            //console.log("Ne postoji nijedan dostupan dokument");


                                  }
           
                    }
            
            },

            (error) => {

                    if (!unmounted) {
            
            


                            setIsLoading(false);

                            setDocs([]);
                            setSelectedDocCode("");
                            setSelectedDocName(""); 
                            setSelectedDocDesc("");
                            
                            setSelectedDocType("");

                            setDocumentLink("");		
                            setDocumentLinkDL("");

                            setDocumentExist(false);

                    
                                if(!error.response){


                                        toast.error('Problem u povezivanju sa serverom, molimo pokušajte kasnije',
                                        toastError);

                                        


                                } else {

      
                                        if(!error.response.status){

                                                toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije",
                                                toastError);

                                        }else{
                        
                                                if (error.response.status == 401) {
                    
                                                        toast.error(error.response.data.message,
                                                                toastError);
                                                        
                                                        setTimeout(() => {
                                                                        logOut();
                                                        }, 5000); 
                                              
                                         
                                                } else {
                                         
                                                        toast.error(error.response.data.message,
                                                                toastError);
                                         
                                                   
                                                }
                                        }
                                }




                    }
            
            
            });
            
            
}            




const handleDownload = () => {        
        const authH = authHeader();

        let tmpFileExt = '';


        //console.log("Attempt download");

        //console.log(selectedDocCode);
        //console.log(selectedDocType);


        const params = {
          // Your parameters here
          fileName: selectedDocCode,
          origFile: selectedDocName,
          fileType: selectedDocType,
        };

        //console.log("Header sent: ");
        //console.log(authH.Authorization);

        //console.log(params);
    
        fetch(APIbase + 'downloadFile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
             "Authorization": authH.Authorization,
          },
          body: JSON.stringify(params),
        })


        .then((response) => {
                // Check if the request was successful

                console.log(response);
                if (!response.ok) {
                        //throw new Error('Network response was not ok');
                        //console.log("aaaaaaaa");
                        //console.log(response);

                        //console.log(response.status)

                        if(!response){
            

                                toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije",
                                toastError);


                        } else {


                                if(!response.status){

                                        toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije",
                                        toastError);
                                }else{
                
                                        if (response.status == 401) {
            


                                                toast.error("Istekla je korisnička sesija, molimo ponovite prijavu na uslugu",
                                                        toastError);
                                      
                                                setTimeout(() => {
                                                        logOut();
                                                      }, 5000);     
                                 
                                        } else {
                                 
                                                toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije",
                                                        toastError);
                                 
                                           
                                        }
                                }
                        }


                } else {

                        console.log(response.headers);

                        console.log("Tried getting all thos eheaders");

                        console.log(response);

          
                        
                        let responseType = response.headers.get('content-type')

                        console.log(responseType);

                        

                        if(responseType ==  'application/pdf'){
                                tmpFileExt = ".pdf";

                        }else if(responseType ==  'image/png'){
                                tmpFileExt = ".png";
                        }else if(responseType ==  'image/jpg'){
                                tmpFileExt = ".jpg";                                

                        }else if(responseType ==  'image/jpeg'){
                                tmpFileExt = ".jpeg";

                        }else if(responseType ==  'text/plain'){
                                tmpFileExt = ".txt";

                        }else if(responseType ==  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                                tmpFileExt=".docx"
                        }


                        return response.blob();
                }
              })
              .then((blob) => {
                        if (blob) {

                                // Create a temporary link element to trigger the download
                                const url = window.URL.createObjectURL(new Blob([blob]));
                                const link = document.createElement('a');
                                link.href = url;
                                
                                
                                link.setAttribute('download', 'dokument' + tmpFileExt); // Adjust the filename as needed

                                document.body.appendChild(link);
                                link.click();
                        
                                // Clean up by revoking the object URL
                                window.URL.revokeObjectURL(url);

                        } else {

                                //console.log("noooooot")


                        }

              })
          .catch((error) => {
                console.error('Error during file download:', error);
                toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije",
                toastError);


          });
      };



      var steps = [
        {
          target: '.list-container-download',
          content: `Na stranici "Dokumenti" možete pronaći sve dokumente koji su dostupni za preuzimanje s korisničkog sustava, 
          kao i poveznice na mobilne aplikacije korisničkog sustava.`,
          disableBeacon: true,
          placement: "right-start"
        },
        {
             target: '.listaDocument',
             content: `Ukoliko želite pregledati detalje o pojedinom dostupnom dokumentu morate kliknuti na strelicu kraj željenog dokumenta u listi, 
             čime se otvara manji preglednik s detaljnijim opisom dokumenta, te poveznicom za preuzimanje.`,
             disableBeacon: true,
             placement: "right"
        },     
                
         
   ]
   
   
        const handleJoyrideCallback = (data) => {
   
             //console.log("JOyride callback");
             //console.log(data);
             const { status, type } = data;
             //const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
             if ((data.status == "finished" || data.status == "skipped")) {
               setRunTour(false);
             }
   
        }
   
   
        const handleJoyrideStart =()=>{
   
             setRunTour(true);
        }
   


return(

        <div className="download">
    
            <div className="container-download">
    
    

                <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                />      


<div id="container1">

<JoyRide 
run={runTour}
steps={steps} 
continuous={true} 
showSkipButton={true} 
callback={handleJoyrideCallback}

locale={{
     skip: "Preskoči",
     last:"Završi", 
     back:"Natrag",
     next: "Dalje"}}
     
styles={{
     options: {
          arrowColor: "#f4f6f9",
          primaryColor: "#29648a",
          textColor: "#72a0c1",
          backgroundColor: "#f4f6f9",
          borderTopRadius: 10,
          borderWidth:0,
          width: 500,
          zIndex: 10000,
          fontSize: 14,
          
     },
     buttonNext: {
          backgroundColor: "#29648a",
          title: "Dalje",
          text:"Dalje",
          border: "none",
          borderRadius: 10,
          color: "white",
          fontSize: 14,
          "&:focus": {
          outline: "none !important",
          },
     },
     buttonBack: {
          color: "#29648a",
          marginLeft: "auto",
          marginRight: 5,
          fontSize: 14,
     },
     buttonClose: {
          color: "#29648a",
          height: 14,
          padding: 15,
          position: "absolute",
          right: 0,
          top: 0,
          width: 14,
          
     },
     buttonSkip: {
          backgroundColor: "#e31b2e",
          title: "Preskoči",
          text:"Preskoči",
          border: "none",
          borderRadius: 10,
          color: "white",
          fontSize: 14,
          "&:focus": {
          outline: "none !important",
          },                                   
     }
     }}
/>

</div>   

                {documentExist && <div className="main-content-download">
    
    
    
                    <div className="list-container-download">      
    
                        <div className="download-title">Dokumenti dostupni za preuzimanje</div>
                        {/*<div className='apkLink'><a href={apkLink}>Preuzmite Android aplikaciju</a></div>*/}


                        <div className="icons">
                                
                                <button 
													
                                className='infoButton'
                                onClick={() => handleJoyrideStart()}
                                >
                                        <InfoIcon style={{fill: '#29648a', }} size="0.7x"/>
                                </button>   

                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                <a href="https://apps.apple.com/hr/app/ephzhb/id6563141330" target="_blank"><button 
													
                                        className='viewDocMore'
                                        
                                                 >
                                                <AppleIcon style={{fill: '#2196F3', }} size="0.7x"/>
                                </button> </a>

                                <a href="https://play.google.com/store/apps/details?id=com.ephzhb&pcampaignid=web_share" target="_blank"><button 
													
                                        className='viewDocMore'
                                                 >
                                                <GoogleIcon style={{fill: '#29648a', }} size="0.7x"/>
                                </button>
                                </a>
                                </div>                                                                    
                        
                        
                        </div>     
    
                            <div className="listaDocument">
    
    
                                        {docs.map((element, index)=>{
                                            return(
    
                                                <div className="document-list-item">
                                                        
                                                                <div className="document-item-name">
                                                                    {element.name}
                                                                </div>
    

                                                
                                                                <button 
													
                                                                        className='viewDocMore'
                                                                        onClick={() => {
                                                                                     

    
                                                                                    setSelectedDocCode(element.code);
                                                                                    setSelectedDocDesc(element.desc);
                                                                                    setSelectedDocName(element.name);
                                                                                    setSelectedDocType(element.type);
            
                                                                                    setDocumentLink(APIbase + 'FileViewer' + '?fileID=' + 
                                                                                    element.code + '&fileType=' + element.type + '&fileName=' + element.name);
                                                                                    setDocumentLinkDL(APIbase + 'DocumentViewer' + '?fileID=' + 
                                                                                    element.code + '&fileType=' + element.type + '&fileName=' + element.name);  
    
                                                                            

                                                                        }}>
                                                                        <ViewIcon style={{fill: '#29648a', }} size="0.7x"/>
                                                                </button>   
                                                                                        
                                                </div>
                                            )
                                            })}
                            </div>
    
                    </div>   
    
    
                    {!showDocument &&  <div className="document-container-download">
                            <div className="document-info">
                                        <div className="stanje-stanje">Ime dokumenta: </div> 
                                        <div className="stanje-value">{selectedDocName} </div>
                                        <div className="stanje-stanje">Opis: </div> 
                                        <div className="stanje-value">{selectedDocDesc}</div>
    
                            </div>  
                                            
                           

                                    <div className="document-PDF" onClick={()=>{
                                                                    
                                                        handleDownload();
                                                        
                                                        }}> 
                                                        Preuzmi
                                      </div>

    

    
                    </div> }
    
                    {showDocument &&
                            <div className="document-container-download">
                                    <button className="document-PDF" onClick={e=>{
                                            setShowDocument(false);
    
                                    }}>Zatvori</button>     
    
    
    
                                    <object data={documentLink} type={selectedDocType} width="100%" height="100%">
                                                        <p><a href={documentLink}></a></p>
                                    </object>
                                    
                            </div>}
    
    
            
    
    
                </div>}
    
                {!documentExist && <div>Trenutno nema dostupnih dokumenata za preuzimanje</div>}
    
                
    
            </div>    
        </div>    
    
    
    
            );
    
        }
    
       export default FileDownload;