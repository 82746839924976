import React from 'react';
import './css/loader.css'

function Loader() {
 return(
    <div class="divLoader">
       <div class="container">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
    </div>
  )};

  export default Loader;