import  React, {Component }from 'react';

import  { useState,useEffect } from 'react';

import  { Alert} from 'react';

import  './css/pocetna.css';

import  Logo from './icons/slika_logo.png';

import { useDispatch, useSelector } from "react-redux";

import { logout } from "../actions/auth";



import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import APIservice from "./services/api.service";

import {ReactComponent as InfoIcon} from '../img/information-circle.svg';



import Loader from "./Loader";

import JoyRide from "react-joyride";





function Pocetna(props) {


    const [list,setList]=useState([]);
    const [options, setOptions] = useState([])
    const [data, setData] = useState([])
    
    
    const [isLoading, setIsLoading] = useState(true);
    const [pickerChange, setPickerChange] = useState("");
    const [selectorOption, setSelectorOption] = useState([]);



    const [platform, setPlatform] = useState("3");


    const [hasData, setHasData] = useState(false);

  
    const { user: currentUser } = useSelector((state) => state.auth);

    const [runTour, setRunTour] = useState(false);


    const toastError = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };

     const toastSuccess = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };

     const toastInfo = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };

    const dispatch = useDispatch();

    
useEffect(() => {

     //console.log("Homepage start");


     

     const allMenuTabs =  Array.from(document.querySelectorAll(".menu-item"));
     allMenuTabs.map((tab, index)=> {
          tab.classList.remove("menuItemIsSelected")
     })
     document.querySelector(".menu-item-1").classList.add("menuItemIsSelected")

     


     getData();





}, []);


const logOut = () => {
    
     dispatch(logout());
 };



function getData () {
     
     let unmounted = false;
     
     let source = axios.CancelToken.source();

     setIsLoading(true);

     
     APIservice.GetDistList(source.token).then(

          (response) => {

               if(!unmounted) {

                    //console.log(response.data);

                    setIsLoading(false);


                    var dataTmp = response.data;


                    if(dataTmp.distLocations.length !=0){

                              //console.log("Postoje podrucja!!!! " + dataTmp.distLocations.length + "; " + dataTmp.distList.length);

                              var arrDist = [];

                              var inty2 = 0;

                              

                              for(var propT in dataTmp.distLocations) {


                                        var areaNameTmp = "";
                                        var areaCodeTmp = "";


                                        for(var propT2 in dataTmp.distLocations[propT]){


                                                  if(propT2 === 'areaName'){

                                                       areaNameTmp = dataTmp.distLocations[propT][propT2];



                                                  }else if (propT2 === "areaCode"){

                                                       areaCodeTmp = dataTmp.distLocations[propT][propT2];





                                                  } else{

                                                       //nema ostalih


                                                  }


                                        }



                                        arrDist.push({"name": areaNameTmp, "code": areaCodeTmp, "id":inty2});

                                        inty2 = inty2+1;



                              }




                              setOptions(arrDist);


                              if(dataTmp.distList.length != 0){


                                        var arrDistUser = [];

                                        var inty = 0;


                                        for(var propT in dataTmp.distList) {


                                                  var areaNameTmp = "";
                                                  var areaCodeTmp = "";
                                                  var actDescTmp = "";
                                                  var actCodeTmp = 0;
                                                  var startTmp = "";
                                                  var endTmp = "";

                                                  for(var propT2 in dataTmp.distList[propT]){


                                                            if(propT2 === 'areaName'){

                                                                 areaNameTmp = dataTmp.distList[propT][propT2];



                                                            }else if (propT2 === "areaCode"){

                                                                 areaCodeTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "actDesc"){

                                                                 actDescTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "actCode"){

                                                                 actCodeTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "start"){

                                                                 startTmp = dataTmp.distList[propT][propT2];

                                                            }else if (propT2 === "end"){

                                                                 endTmp = dataTmp.distList[propT][propT2];


                                                            } else{

                                                                 //nema ostalih


                                                            }


                                                  }





                                                  arrDistUser.push({"name": areaNameTmp, "code": areaCodeTmp, "start": startTmp, "end": endTmp,
                                                                 "actDesc": actDescTmp, "actCode": actCodeTmp, "id":inty});


                                                  inty = inty+1;


                                        }




                                        //setPickerChange('korisničko');

                                        let tmpArr = arrDistUser.reverse();

                                        setList(tmpArr);

                                        setPickerChange('korisničko');
                                        //setSelectorOption('Korisničko')



                              
                              
                              }else{



                                        setPickerChange(arrDist[0].name);
                                        //setSelectorOption(arrDist[0].name);


                                        getDataOne(arrDist[0].code, arrDist[0].name);


                              }



                    }else{

      
                              toast.info("Nema dostupnih distribucijskih područja", toastInfo);

                              setOptions([]);

                              setList([]);

                              setPickerChange('');
                              //setSelectorOption('');
                              

                    }

               }
          }, 
          (error) => {
 
               if (!unmounted) {
       
                 //console.log("Error rq");
       
                 console.error(error);

                 setIsLoading(false);

                 setOptions([]);

                 setList([]);

                 setPickerChange('');
                 //setSelectorOption('');

       
                    if(!error.response){

                         toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                    } else {

  
                         if(!error.response.status){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                         }else{
     
                              if (error.response.status == 401) {
                    
                                   toast.error(error.response.data.message,
                                        toastError);
                                
                                   setTimeout(() => {
                                                  logOut();
                                   }, 5000); 
                         
                    
                              } else {
                    
                                   toast.error(error.response.data.message, toastError)
                    
                              
                              }
                         }
                    }
               }
       
       
          }

     )
}





function getDataOne (chosenAreaCode, chosenAreaName) {
     
     let unmounted = false;
     
     let source = axios.CancelToken.source();

     setIsLoading(true);

     setList([]);

     
     APIservice.GetDistOne(chosenAreaCode, source.token).then(

          (response) => {

               if(!unmounted) {

                    //console.log(response.data);

                    setIsLoading(false);


                    var dataTmp = response.data;


                    if(dataTmp.distLocations.length !=0){

                              var arrDist = [];

                              var inty2 = 0;

                              for(var propT in dataTmp.distLocations) {


                                        var areaNameTmp = "";
                                        var areaCodeTmp = "";


                                        for(var propT2 in dataTmp.distLocations[propT]){


                                                  if(propT2 === 'areaName'){

                                                       areaNameTmp = dataTmp.distLocations[propT][propT2];



                                                  }else if (propT2 === "areaCode"){

                                                       areaCodeTmp = dataTmp.distLocations[propT][propT2];





                                                  } else{

                                                       //nema ostalih


                                                  }


                                        }



                                        arrDist.push({"name": areaNameTmp, "code": areaCodeTmp, "id":inty2});

                                        inty2 = inty2+1;



                              }




                              setOptions(arrDist);


                              if(dataTmp.distList.length != 0){


                                        var arrDistUser = [];

                                        var inty = 0;


                                        for(var propT in dataTmp.distList) {


                                                  var areaNameTmp = "";
                                                  var areaCodeTmp = "";
                                                  var actDescTmp = "";
                                                  var actCodeTmp = 0;
                                                  var startTmp = "";
                                                  var endTmp = "";

                                                  for(var propT2 in dataTmp.distList[propT]){

                                                            

                                                            if(propT2 === 'areaName'){

                                                                 areaNameTmp = dataTmp.distList[propT][propT2];

                                                                 //console.log("AreaNAme: " + areaNameTmp);



                                                            }else if (propT2 === "areaCode"){

                                                                 areaCodeTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "actDesc"){

                                                                 actDescTmp = dataTmp.distList[propT][propT2];

                                                                 //console.log("Activity description:" + actDescTmp);


                                                            }else if (propT2 === "actCode"){

                                                                 actCodeTmp = dataTmp.distList[propT][propT2];


                                                            }else if (propT2 === "start"){

                                                                 startTmp = dataTmp.distList[propT][propT2];

                                                            }else if (propT2 === "end"){

                                                                 endTmp = dataTmp.distList[propT][propT2];


                                                            } else{

                                                                 //nema ostalih


                                                            }


                                                  }





                                                  arrDistUser.push({"name": areaNameTmp, "code": areaCodeTmp, "start": startTmp, "end": endTmp,
                                                                 "actDesc": actDescTmp, "actCode": actCodeTmp, "id":inty});


                                                  inty = inty+1;


                                        }



            

                                        let tmpArr = arrDistUser.reverse();

                                        setList(tmpArr);
      
      
                                        setPickerChange(chosenAreaName);
                                        //setSelectorOption(chosenAreaName);

                              
                              
                              }else{



                                        setPickerChange(chosenAreaName);
                                        //setSelectorOption(chosenAreaName);

                                        setList([]);




                              }



                    }else{

      
                              toast.info("Nema dostupnih distribucijskih područja", toastInfo);

                              setOptions([]);

                              setList([]);

                              setPickerChange('');
                              //setSelectorOption('');

                    }

               }
          }, 
          (error) => {
 
               if (!unmounted) {
       
                 //console.log("Error rq");
       
                 console.error(error);

                 setIsLoading(false);

                 setList([]);

                 setPickerChange('');
                 //setSelectorOption('');

       

                    if(!error.response){

                         toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                    } else {

  
                         if(!error.response.status){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                         }else{
          
                              if (error.response.status == 401) {
                    
                                   toast.error(error.response.data.message,
                                        toastError);
                                
                                   setTimeout(() => {
                                                  logOut();
                                   }, 5000); 
                         
                    
                              } else {
                    
                                   toast.error(error.response.data.message, toastError)
                    
                              
                              }
                         }
                    }
                 
               }
       
       
          }

     )
}



var steps = [
     {
          target: '.infoButton',
          content: `Dobro došli na korisnički portal JP Elektroprivrede HZ HB d.d. Na svim stranicama korisničkog portala gdje vidite ikonu informacija možete pronaći objašnjenje funkcionalnosti i postavki korisničke web aplikacije. Savjetujemo da iskoristite 
          mogućnost vodiča i pobliže se upoznate s dostupnim funkcionalnostima.`,
          disableBeacon: true,
          placement: "left"
     },
     {
          target: '.menu-selector',
          content: 'Odabirom ikone u gornjem desnom kutu svakog ekrana web portala možete pregledati i izmijeniti svoje korisničke postavke, kao i odjaviti se iz aplikacije.',
          disableBeacon: true
     },
     {
          target: '.pocetna-selector',
          content: `Na početnoj stranici korisničkog web portala imate dostupne informacije o planiranim radovima i isključenjima na mreži u narednih 48h. Odabirom pojedinog 
          distribucijskog područja možete pregledati najave radova i prekida u opskrbi za odabrano distribucijsko područje.`,
          disableBeacon: true,
          placement: "left"
     },

   ]


     const handleJoyrideCallback = (data) => {

          //console.log("JOyride callback");
          //console.log(data);
          const { status, type } = data;
          //const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
          if ((data.status == "finished" || data.status == "skipped")) {
            setRunTour(false);
          }

     }


     const handleJoyrideStart =()=>{

          setRunTour(true);
     }


return (
     <div className="pocetna">
     


          	<ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                />   

               <div id="container1">

               <JoyRide 
               run={runTour}
               steps={steps} 
               continuous={true} 
               showSkipButton={true} 
               callback={handleJoyrideCallback}

               locale={{
                    skip: "Preskoči",
                    last:"Završi", 
                    back:"Natrag",
                    next: "Dalje"}}
                    
               styles={{
                    options: {
                         arrowColor: "#f4f6f9",
                         primaryColor: "#29648a",
                         textColor: "#72a0c1",
                         backgroundColor: "#f4f6f9",
                         borderTopRadius: 10,
                         borderWidth:0,
                         width: 500,
                         zIndex: 10000,
                         fontSize: 14,
                         
                    },
                    buttonNext: {
                         backgroundColor: "#29648a",
                         title: "Dalje",
                         text:"Dalje",
                         border: "none",
                         borderRadius: 10,
                         color: "white",
                         fontSize: 14,
                         "&:focus": {
                         outline: "none !important",
                         },
                    },
                    buttonBack: {
                         color: "#29648a",
                         marginLeft: "auto",
                         marginRight: 5,
                         fontSize: 14,
                    },
                    buttonClose: {
                         color: "#29648a",
                         height: 14,
                         padding: 15,
                         position: "absolute",
                         right: 0,
                         top: 0,
                         width: 14,
                         
                    },
                    buttonSkip: {
                         backgroundColor: "#e31b2e",
                         title: "Preskoči",
                         text:"Preskoči",
                         border: "none",
                         borderRadius: 10,
                         color: "white",
                         fontSize: 14,
                         "&:focus": {
                         outline: "none !important",
                         },                                   
                    }
                    }}
               />

               </div>



               <div className="pocetna-container">
             
     
                         <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-between'}}>
                         <div className="pocetna-title">Planirani radovi i isključenja u mreži u narednih 48h</div>
     
                         <button 
													
                                                                 className='infoButton'
                                                                 onClick={() => handleJoyrideStart()}
                                                                 >
                                                                 <InfoIcon style={{fill: '#29648a', }} size="0.7x"/>
                         </button>   
                         </div>


     
                         <div className="pocetna-selector">
                              <select name="basic" id="ex-basic" 
                                        value={selectorOption}
                                        //value={pickerChange} 
                                        onChange={(e)=>{
     
     
                                             let index = e.target.selectedIndex;
                                             let optionElement = e.target.childNodes[index];
                                             let optionElementId = optionElement.getAttribute('id');
               
                                             let a = optionElement.getAttribute('value');
                                             let b = optionElementId;
                                              
               
                                             //console.log("Index " + index);
                                             //console.log("Option: " + optionElement);
                                             //console.log("ID: " + optionElementId);
     
     
     
                                             //var a = e.target.value;
                                             //var b = e.target.id;
     
                                             setSelectorOption(a); 
                                             setPickerChange(a);
     
     
     
      
                                        
                                        
                                             getDataOne(b, a);
     
                                               
     
     
                                        }}>
     
                                        <option value="" disabled hidden>Odaberite distribucijsko područje</option>
                                        {options && options.map(item=>{
                                             return (
                                             <option value={item["name"]} id={item["code"]} onClick={(e)=>{
                                           
                                             }}>{item["name"]}</option>
                                             )
                                        })}
                              </select>
                         </div>
     
                                        
     
                         <div className="pocetna-list">
                              <div className="item">
                                        <div className="description-name">Ime</div>
                                        <div className="description-actDesc">Opis</div>
                                   <div className="date">
                                        Datum
                                   </div>
                              </div>
                              {list && list.map(item=>{
                              return(
                              <div className="item">
                                             <div className="description-name">{item.name}</div>
                                             <div className="description-actDesc">{item["actDesc"]}</div>
                                        <div className="date">
                                             <div className="start">Početak: {item["start"]}</div>
                                             <div className="end">Kraj: {item["end"]}</div>
                                        </div>
                              </div>
     
     
     
     
                              )
                              })}
                              {!list &&
                                   <div>Trenutno nema informacija o radovima i isključenjima u mreži</div>
                              }
                         </div>
     
     
     
     
               </div>
     
             
          </div>
         );
     }
     
     
     
     export default Pocetna;