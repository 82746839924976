import React,{Component }from 'react';
import  { useState,useEffect } from 'react';
import  { Alert} from 'react';
import './css/brojila.css';
import {Line} from 'react-chartjs-2';
import Chart from 'chart.js';

import { format } from 'date-fns';

import { useDispatch, useSelector } from "react-redux";

import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";



import axios from 'axios';

import APIservice from "./services/api.service";

import { history } from "../helpers/history";


import PropTypes from 'prop-types';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//import {ReactComponent as DeleteIcon} from '../img/close-circle-outline.svg';
import {ReactComponent as DeleteIcon} from '../img/close-circle.svg';

import {ReactComponent as SearchIcon} from '../img/search.svg';

import {ReactComponent as AddIcon} from '../img/add-circle.svg';

import JoyRide from "react-joyride";

import Swal from 'sweetalert2';

import {ReactComponent as InfoIcon} from '../img/information-circle.svg';



import Loader from "./Loader";


import { APIvar } from './variables';

const API = APIvar.address;

const version = APIvar.appVersion;

//const API = "https://korisnicka.ephzhb.ba/EPHZHBSrv/";
//const version = "1.0";


function BrojilaAdmin(props) {

    const [list,setList]=useState([]);
    const [data, setData] = useState([])
    const [brojiloListStart, setBrojiloListStart] = useState([]);  

    const [text, setText ]=useState()
    const [xAxis, setXAxis] = useState([])
    const [yAxis, setYAxis] = useState([])
    const [r, setR] = useState("r1");

    const [date, setDate] = useState("");




    const [isLoadingBill, setIsLoadingBill] = useState(false);



    const [platform, setPlatform] = useState("3");
    const [isLoading, setIsLoading] = useState(true);

    const [nameOwner, setNameOwner] = useState("");
    const [stanje, setStanje] = useState("");
    const [lastDate, setLastDate] = useState("");
    const [R1, setR1] = useState(0);
    const [R2, setR2] = useState(0);
    const [R3, setR3] = useState(0);
    const [adresa, setAdresa] = useState("");
  
    const [buyerCode, setBuyerCode] = useState("");

    const [sifraKpc, setSifraKpc] = useState("");

    const [idBrojila, setIdBrojila] = useState('');

    const [OMM, setOMM] = useState('');
    const [userId, setUserId] = useState("")

    const [uInfo, setUserInfo] = React.useState({});

  
    const [listaTrosak, setListaTrosak] = React.useState([]);
    const [listaOcitanje, setListaOcitanje] = React.useState([]);

    
    const [BrojiloListOwed, setBrojiloListOwed] = React.useState([]);
    const [BrojiloListPaid, setBrojiloListPaid] = React.useState([]);
 




    const [numLines, setNumLines] = React.useState('30');

    const [activeType, setActiveType] = React.useState("R1");
    const [activeYear, setActiveYear] = React.useState("20");
    const [activeYearName, setActiveYearName] = React.useState("Sve")
  
    const [yearList, setYearList] = React.useState([{id:0, value:"20", name:"Sve"},{id:1, value:"2021", name:"2021"}, {id:2, value:"2020", name:"2020"}]);

    const [listaGrafova, setListaGrafovi] = React.useState([]);





    const [actBillDate, setActBillDate] = useState("");
    const [actBillOwed, setActBillOwed] = useState("");
    const [actBillDue, setActBillDue] = useState("");
    const [actBillPaid, setActBillPaid] = useState("");
    const [actBIllPNB, setActBillPNB] = useState("");





     const [actProperty, setActProperty] = useState("stanje");

     const [billBack, setBillBack] = useState("");

     const [pdfLink, setPdfLink] = useState("");

     const [showBill, setShowBill] = useState(false);

     const [checkMjMjesto, setCheckMjMjesto]= useState(true)
     const [checkKorisnika, setCheckKorisnika]= useState(false)
     const [inputMjMjesto, setInputMjMjesto] = useState("");
     const [inputKorisnika, setInputKorisnika] = useState("");
     const [inputKorisnikaShow, setInputKorisnikaShow] = useState('');
     const [fctCode, setFctCode] = useState("")
     const { user: currentUser } = useSelector((state) => state.auth);

     const[allMeters,setAllMeters]=useState([])

     const [addName, setAddName] = useState("");
     const [addFctCode, setAddFctCode] = useState("");
     const [addBuyCode, setAddBuyCode] = useState("");

     const [showAddingModal, setShowAddingModal] = useState(false);


     const [runTour, setRunTour] = useState(false);
     const [userSubMenuIsVisible, setUserSubMenuIsVisible] = useState(false)

     const toastError = {                                                        
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
     };

     const toastSuccess = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };

     const toastInfo = {                                                        
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "light",
     };



     const dispatch = useDispatch();


    const state = {
        labels: xAxis,
        datasets: [
            {
              label: 'Potrošnja',
              fill: false,
              lineTension: 0,
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: '#29648a',
              borderWidth: 1,
              data: yAxis
            }
        ]
    };




    function handlePrimaryListItem (e) {



            var x = Array.from(document.querySelectorAll(".main-list-item")).map(item=>{
               item.classList.remove("mainListItemIsSelected")
             });
             var y =Array.from(document.querySelectorAll(".main-content")).map(item=>{
               item.classList.add("isHidden")
             });
             var z = document.querySelector(`.main-content-${e.target.id}`).classList.remove("isHidden")
             
             e.target.classList.add("mainListItemIsSelected");
 
           
 
             setActProperty(e.target.id);
 
 
             setShowBill(false);



    }


    function handleSideList(e) {
            var x = Array.from(document.querySelectorAll(".side-list-item")).map(item=>{
              item.classList.remove("sideListItemIsSelected")
            })
            e.target.classList.add("sideListItemIsSelected")


    }



    function handleSubOption(e) {


      var x = Array.from(document.querySelectorAll(".potrosnja-option")).map(item=>{
          item.classList.remove("potrosnjaOptionIsSelected")
          });
          var y = e.classList.add("potrosnjaOptionIsSelected")
          
          var z =Array.from(document.querySelectorAll(".lista-potrosnja")).map(item=>{
          item.classList.add("isHidden")
          })
          document.querySelector(`.${e.id}`).classList.remove("isHidden")




}    



function handleSubOptionPromet(e) {



     setShowBill(false);
     var x = Array.from(document.querySelectorAll(".promet-option")).map(item=>{
          item.classList.remove("prometOptionIsSelected")
     });
     var y = e.classList.add("prometOptionIsSelected")
     
     var z =Array.from(document.querySelectorAll(".lista-racun")).map(item=>{
          item.classList.add("isHidden")
     })
     document.querySelector(`.${e.id}`).classList.remove("isHidden")


}   


    function handleSubOptionAnaliza(e) {


            var x = Array.from(document.querySelectorAll(".analiza-option")).map(item=>{
               item.classList.remove("analizaOptionIsSelected")
               });
               var y = e.classList.add("analizaOptionIsSelected")
               
               var z =Array.from(document.querySelectorAll(".lista-potrosnja")).map(item=>{
                    item.classList.add("isHidden");
               })
               document.querySelector(`.${e.id}`).classList.remove("isHidden");

              



    }



function handleItemList(id) {
//function handleItemList() {



     setShowBill(false);


     //let aaa = buyerCode;
     let aaa = id;

     //setBuyerCode(aaa);


     if(actProperty == "stanje"){


              getItemsStanje (aaa); 

     }else if(actProperty =="promet"){


              //getDataPromet(aaa);

     }else if(actProperty == "analiza"){


              //getDataAnaliza (aaa); 


     }else{

         //console.log("Nepoznat property: " + actProperty);
     }
     

};




    
useEffect(() => {


               setShowBill(false);



   
                      const allMenuTabs =  Array.from(document.querySelectorAll(".menu-item"));
                      allMenuTabs.map((tab, index)=> {
                           tab.classList.remove("menuItemIsSelected")
                      })
                      document.querySelector(".menu-item-2").classList.add("menuItemIsSelected")
      
            

}, []);


const logOut = () => {
     
     dispatch(logout());
};



    




    function getDataBrojila (user_id) {


     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);

         
     APIservice.GetMeterListAdmin(user_id, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);

                                   

                                   setBuyerCode('');
                                   setBrojiloListStart([]);

                                   setStanje(0);
                                   setNameOwner('');


                                   setAllMeters([])



                                   setIsLoading(false);


                                   var dataTmp = response.data;
                                   //setAllMeters(dataTmp.meterList)

                                   //console.log(dataTmp.meterList)

                                   if(dataTmp.meterList.length !=0){


                                             //dobavi listu brojila

                                             setInputKorisnikaShow(user_id);

                                             var arrBrojilo = [];
                                             var inty = 0;


                                             for(var propT in dataTmp.meterList) {


                                                  var flagT = 0;


                                                  var MeterNameTmp = "";
                                                  var MeterAddressTmp = "";
                                                  var MeterBuyerCodeTmp = "";
                                                  var MeterFactCodeTmp = "";
                                                  var MeterR1Tmp = "";
                                                  var MeterR2Tmp = "";
                                                  var MeterR3Tmp = "";
                                                  var MeterTipSaldoTmp ="";
                                                  var MeterSaldoTmp = "";


                                                  let MeterCodeTmp = "";
                                                  let MeterDateTmp = "";

                                                  var tempMeterObj;


                                                  var tempOMM = '';

                                                  for(var propT2 in dataTmp.meterList[propT]){



                                                       if(propT2 === 'MeterName'){

                                                            MeterNameTmp = dataTmp.meterList[propT][propT2];



                                                       }else if (propT2 === "MeterAddress"){

                                                            MeterAddressTmp = dataTmp.meterList[propT][propT2];


                                                       }else if (propT2 === "MeterBuyerCode"){

                                                            MeterBuyerCodeTmp = dataTmp.meterList[propT][propT2];


                                                       }else if (propT2 === "MeterFactCode"){

                                                            MeterFactCodeTmp = dataTmp.meterList[propT][propT2];

                                                       }else if (propT2 === "MeterCode"){

                                                            MeterCodeTmp = dataTmp.meterList[propT][propT2];

                                                       }else if (propT2 === "MeterDate"){

                                                            let tmpDate = dataTmp.meterList[propT][propT2];

                                                       
                                                            MeterDateTmp = format(new Date(tmpDate), 'dd-MM-yyyy');




                                                       }else if (propT2 === "MeterR1"){

                                                            MeterR1Tmp = dataTmp.meterList[propT][propT2];

                                                       }else if (propT2 === "MeterR2"){

                                                            MeterR2Tmp = dataTmp.meterList[propT][propT2];


                                                       }else if (propT2 === "MeterR3"){

                                                            MeterR3Tmp = dataTmp.meterList[propT][propT2];


                                                       }else if (propT2 === "MeterSaldo"){

                                                            MeterSaldoTmp = dataTmp.meterList[propT][propT2];



                                                       }else if (propT2 === "MeterTipSalda"){

                                                            MeterTipSaldoTmp = dataTmp.meterList[propT][propT2];


                                                       }else if (propT2 === "OMM"){

                                                            tempOMM = dataTmp.meterList[propT][propT2];



                                                       } else{

                                                            //nema ostalih


                                                       }


                                                  }




                                                  let tmpValue = parseFloat(MeterSaldoTmp);
                                                  
                                                  tmpValue = tmpValue.toFixed(2);


                                                  let tmpValueString = "";

                                                  if(tmpValue!=0){
                                                       

                                                       //tmpValue = - tmpValue;

                                                       if(tmpValue > 0){

                                                            //stanje je dug

                                                            tmpValueString = tmpValue;

                                                            

                                                       }else{

                                                            //stanje je preplata

                                                            tmpValue = -tmpValue;

                                                            tmpValueString = 'U preplati: ' + tmpValue; 

                                                       }

                                                  }else{

                                                       //stanje je nula, dodaj string

                                                       tmpValueString = tmpValue;

                                                  }


                                                  //arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, "stanje": tmpValue, "id":inty, "OMM": tempOMM, "meterCode": MeterFactCodeTmp});
                                                  arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, 
                                                  "stanje": tmpValueString, "id":inty, 
                                                  "OMM": tempOMM, "meterCode": MeterFactCodeTmp, "buyerCode": MeterCodeTmp,
                                                  "date": MeterDateTmp, "address": MeterAddressTmp, "user_id": user_id,
                                                  "MeterR1": MeterR1Tmp, "MeterR2": MeterR2Tmp});






                                                  inty = inty+1;


               

                                             }

                                             //console.log(arrBrojilo[0])


                                             setBuyerCode(arrBrojilo[0].text);
                                             setBrojiloListStart(arrBrojilo);

                                             setStanje(arrBrojilo[0].stanje);
                                             setNameOwner(arrBrojilo[0].name);


                                             setAllMeters(arrBrojilo)


                                             //handleItemList(arrBrojilo[0].text);


                                        
                                   }else{

      
                                             toast.info(dataTmp.message, toastInfo);

                                   }
            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoading(false);

             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                         } else {


                              if(!error.response.status){

                                        toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{
     
                                        if (error.response.status == 401) {

                                             toast.error(error.response.data.message,
                                                  toastError);
                                          
                                             setTimeout(() => {
                                                            logOut();
                                             }, 5000); 
                                   
                              
                                        } else {
                              
                                                  toast.error(error.response.data.message, toastError)
                              
                                        
                                        }
                              }
                         }
                     }
             
             
             });
             
             
}            








function getItemsStanje (meterID) {


     //console.log(meterID)
     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         
     APIservice.GetMeterStateAdmin(meterID, numLines, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoading(false);

                                   //console.log(response.data.meterItemList);

                                   var dataTmp = response.data;


                                   if(dataTmp.detailedMeter){



                                                  var arrBrojilo = [];
                                                  var inty = 0;





                                                  //var tmpStanje = paid - owed;
                                                  var tmpStanje = parseFloat(dataTmp.detailedMeter.MeterSaldo);

                                                  tmpStanje = tmpStanje.toFixed(2);

                                                  let tmpValue = parseFloat(dataTmp.detailedMeter.MeterSaldo);

                                                  tmpValue = tmpValue.toFixed(2);




                                                  let tmpValueString = "";

                                                  if(tmpValue!=0){
                                                       

                                                       //tmpValue = - tmpValue;

                                                       if(tmpValue > 0){

                                                            //stanje je dug


                                                            tmpValueString = tmpValue;

                                                       

                                                       }else{

                                                            //stanje je preplata

                                                            tmpValue = -tmpValue;


                                                            tmpValueString = 'U preplati: ' + tmpValue; 

                                                       }

                                                  }else{

                                                       //stanje je nula, dodaj string


                                                       tmpValueString = tmpValue;

                                                  }





                                                  tmpStanje = tmpValue;

                                                  //console.log(dataTmp)

                                                  let startDate = dataTmp.detailedMeter.Datum;
                                                       
                                                  let endDate = format(new Date(startDate), 'dd-MM-yyyy');



                                                  arrBrojilo.push({"name": dataTmp.detailedMeter.MeterName, 
                                                  "text": dataTmp.detailedMeter.MeterBuyerCode, "stanje": tmpValueString, 
                                                  "id":inty, "address": dataTmp.detailedMeter.MeterAddress,
                                                  "date": endDate, "factCode": dataTmp.detailedMeter.MeterFactCode, 
                                                  "R1": dataTmp.detailedMeter.MeterR1, "R2": dataTmp.detailedMeter.MeterR2, 
                                                  "R3": dataTmp.detailedMeter.MeterR3, "user_id": dataTmp.detailedMeter.user_id});

                                                  //console.log(" dataTmp.detailedMeter.user_id" +  dataTmp.detailedMeter.user_id)

                                                  setNameOwner(dataTmp.detailedMeter.MeterName);
                                                  //setStanje(tmpStanje);
                                                  setStanje(tmpValueString);


                                                  
                                                  //setLastDate(MeterDateTmp);
                                                  setLastDate(endDate);
                                                  
                                                  setR1(dataTmp.detailedMeter.MeterR1);
                                                  setR2(dataTmp.detailedMeter.MeterR2);
                                                  setR3(dataTmp.detailedMeter.MeterR3);
                                                  setAdresa(dataTmp.detailedMeter.MeterAddress);
                                                  setBuyerCode(dataTmp.detailedMeter.MeterBuyerCode);
                                                  setFctCode(dataTmp.detailedMeter.MeterFactCode);
               
                                                  setOMM(dataTmp.detailedMeter.OMM);
                                                  setUserId( dataTmp.detailedMeter.user_id)


                                             //dohvati ocitanja


                                             var arrOcitanja = [];
                                             var arrTrosak = [];



                                             inty = 0;
                                             var inty2 = 0;

                                             var initID = 0;

                                             if(dataTmp.readingList.length != 0){


                                                       for(var propT in dataTmp.readingList) {


                                                            var flagT = 0;


                                                            var readingDateTmp = "";

                                                            var readingR1Tmp = 0;
                                                            var readingR2Tmp = 0;
                                                            var readingR3Tmp = 0;

                                                            var readingObrTmp ="";

                                                            var readingNacinTmp = "";
                                                            var readingKPCTmp = "";

                                                            var readingOMM = "";
                                                            var readingStorno = 0;


                                                            var tempMeterObj;

                                                            for(var propT2 in dataTmp.readingList[propT]){


                                                                 if(propT2 === 'NACIN'){

                                                                      readingNacinTmp = dataTmp.readingList[propT][propT2];


                                                                 }else if (propT2 === "DATUM"){

                                                                      readingDateTmp = dataTmp.readingList[propT][propT2];



                                                                 }else if (propT2 === "KPC_ID"){

                                                                      readingKPCTmp = dataTmp.readingList[propT][propT2];



                                                                 }else if (propT2 === "OBRACUNATO"){

                                                                      readingObrTmp = dataTmp.readingList[propT][propT2];


                                                                 }else if (propT2 === "R1"){

                                                                      readingR1Tmp = dataTmp.readingList[propT][propT2];



                                                                 }else if (propT2 === "R2"){

                                                                      readingR2Tmp = dataTmp.readingList[propT][propT2];


                                                                 }else if (propT2 === "R3"){

                                                                      readingR3Tmp = dataTmp.readingList[propT][propT2];


                                                                 }else if (propT2 === "OMM"){

                                                                      readingOMM = dataTmp.readingList[propT][propT2];



                                                                 }else if (propT2 === "STORNO"){

                                                                      readingStorno = dataTmp.readingList[propT][propT2];


                                                       
                                                                 }else{

                                                                      //nema ostalih


                                                                 }

                                                            }


                                                                      
                                                            let startDateR = readingDateTmp;
                                                                      
                                                            let endDateR = format(new Date(startDateR), 'dd-MM-yyyy');




                                                            //arrOcitanja.push({"name": readingNacinTmp, "kupacId": readingKPCTmp, "date":"Datum očitanja: " + readingDateTmp,
                                                            arrOcitanja.push({"name": readingNacinTmp, "kupacId": readingKPCTmp, "date":"Datum očitanja: " + endDateR,
                                                                                "r1":readingR1Tmp, "r2":readingR2Tmp,"r3":readingR3Tmp,
                                                                                "obrac": readingObrTmp, "id":inty,
                                                                                "omm": readingOMM, "storno": readingStorno});


                                                            inty = inty +1;



                                                       }



                                                       setListaOcitanje(arrOcitanja);


                                             }else{


                                                       setListaOcitanje([]);

                                             }

                                             

                                             if(dataTmp.spentList.length != 0){

                                                            
                                                       for(var propT in dataTmp.spentList) {


                                                            var flagT = 0;


                                                            var spentDateODTmp = "";
                                                            var spentDateDOTmp = "";
                                                            var spentR1Tmp = 0;
                                                            var spentR2Tmp = 0;
                                                            var spentR3Tmp = 0;

                                                            var spentKPCTmp = "";


                                                            for(var propT2 in dataTmp.spentList[propT]){


                                                                 if(propT2 === 'DATUMOD'){

                                                                      spentDateODTmp = dataTmp.spentList[propT][propT2];


                                                                 }else if (propT2 === "DATUMDO"){

                                                                      spentDateDOTmp = dataTmp.spentList[propT][propT2];



                                                                 }else if (propT2 === "KPC_ID"){

                                                                      spentKPCTmp = dataTmp.spentList[propT][propT2];


                                                                 }else if (propT2 === "R1"){

                                                                      spentR1Tmp = dataTmp.spentList[propT][propT2];



                                                                 }else if (propT2 === "R2"){

                                                                      spentR2Tmp = dataTmp.spentList[propT][propT2];


                                                                 }else if (propT2 === "R3"){

                                                                      spentR3Tmp = dataTmp.spentList[propT][propT2];


                                                                 
                                                                 }else{

                                                                      //nema ostalih


                                                                 }

                                                            }



                                                            let startDateS = spentDateODTmp;
                                                                                
                                                            let endDateS = format(new Date(startDateS), 'dd-MM-yyyy');
                                   


                                                            let startDate2 = spentDateDOTmp;
                                                                                
                                                            let endDate2 = format(new Date(startDate2), 'dd-MM-yyyy');
                                                            

                                                            //arrTrosak.push({"name": "Potrošnja po brojilima", "date": "Razdoblje: " + spentDateODTmp + " do " + spentDateDOTmp, "kupacId": spentKPCTmp,
                                                            arrTrosak.push({"name": "Potrošnja po brojilima", "date": "Razdoblje: " + endDateS + " do " + endDate2, "kupacId": spentKPCTmp,                                          
                                                                      "r1":spentR1Tmp, "r2":spentR2Tmp,"r3":spentR3Tmp, "id":inty2});


                                                            inty2 = inty2 +1;




                                                       }


                                                       setListaTrosak(arrTrosak);

                                             }else{


                                                       setListaTrosak([]);
                                             }


                                             setIsLoading(false);




                                        
                                   }else{

      
                                             toast.info(dataTmp.message, toastInfo);

                                   }
            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoading(false);

             
                       if (error.response.status == 401) {
             
                         toast.error(error.response.data.message,
                              toastError);
                      
                         setTimeout(() => {
                                        logOut();
                         }, 5000); 
             
                       } else {
             
                         toast.error(error.response.data.message, toastError);
             
                         
                       }
                     }
             
             
             });
             
             
}            



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Podaci o računima
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*

function getDataPromet (opcija){


     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         
     APIservice.GetBillListAdmin(opcija, numLines, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoading(false);


                                   var dataTmp = response.data;


                                   if(dataTmp.bills.length !=0){



                                             var arrBrojiloOwed = [];
                                             var arrBrojiloPaid = [];
                                             var inty = 0;
                                             var inty2 = 0;

                                             var initID = 0;


                                             for(var propT in dataTmp.bills) {


                                                       var flagT = 0;


                                                       var BillNameTmp = "";
                                                       var BillDateTmp = "";
                                                       var BillBuyerCodeTmp = "";

                                                       var BillOwed =0.00;
                                                       var BillPaid = 0.00;
                                                       var PNB = "";

                                                       var BillSnaga = 0.00;
                                                       var BillKamata = 0.00;
                                                       var BillDospijece = "";
                                                       var BillVte = "";
                                                       var BillStorno = 0;
                                                       var BillMonth = 0;
                                                       var BillYear = 0;

                                                       let paidBill = "";



                                                       for(var propT2 in dataTmp.bills[propT]){



                                                                 if(propT2 === 'NAZIV'){

                                                                 BillNameTmp = dataTmp.bills[propT][propT2];



                                                                 }else if (propT2 === "DATUM"){

                                                                      BillDateTmp = dataTmp.bills[propT][propT2];


                                                                 }else if (propT2 === "KPC_ID"){

                                                                      BillBuyerCodeTmp = dataTmp.bills[propT][propT2];


                                                                 }else if (propT2 === "PNB"){

                                                                      PNB = dataTmp.bills[propT][propT2];


                                                                 }else if (propT2 === "UPLATA"){

                                                                      BillPaid = dataTmp.bills[propT][propT2];


                                                                 }else if (propT2 === "DUG"){

                                                                      BillOwed = dataTmp.bills[propT][propT2];



                                                                 }else if (propT2 == "PAID"){

                                                                      paidBill = dataTmp.bills[propT][propT2];

                                                                 }else if (propT2 === "VTE"){

                                                                      BillVte = dataTmp.bills[propT][propT2];

                                                                 }else if (propT2 === "SNAGA"){

                                                                      BillSnaga = dataTmp.bills[propT][propT2];

                                                                 }else if (propT2 === "KAMATA"){

                                                                      BillKamata = dataTmp.bills[propT][propT2];

                                                                 }else if (propT2 === "DOSPIJECE"){

                                                                      BillDospijece = dataTmp.bills[propT][propT2];

                                                                 }else if (propT2 === "STORNO"){


                                                                      

                                                                      BillStorno = dataTmp.bills[propT][propT2];

                                                                 
                                                                 }else if (propT2 === "MONTH"){

                                                                      BillMonth = dataTmp.bills[propT][propT2];


                                                                 }else if (propT2 === "YEAR"){

                                                                      BillYear = dataTmp.bills[propT][propT2];




                                                                 } else{

                                                                 //nema ostalih


                                                                 }


                                                       }




                                                       var tmpOwed = parseFloat(BillOwed).toFixed(2);

                                                  

                                                       let startDate = BillDateTmp;
                                                                      
                                                       let endDate = format(new Date(startDate), 'dd-MM-yyyy');

                                                       BillDateTmp = endDate;

                                                       startDate = BillDospijece;
                                                                      
                                                       endDate = format(new Date(startDate), 'dd-MM-yyyy');

                                                       BillDospijece = endDate;
               



                                                       if(parseFloat(BillOwed) >= 0 && BillStorno == 0 && BillNameTmp=="Račun"){



                                                                 var tmpPaid = "";

                                                                 var tmpBol;

                                                                 if(paidBill == 0){

                                                                      tmpPaid = "Račun nije podmiren";

                                                                      tmpBol = false;

                                                                 }else{

                                                                      tmpPaid = "Račun je podmiren";

                                                                      tmpBol = true;
                                                                 }

                                                                 

                                                                 arrBrojiloOwed.push({"name": BillNameTmp, "kupacId": BillBuyerCodeTmp, "date": BillDateTmp, "owed":parseFloat(BillOwed).toFixed(2), 
                                                                      "id":inty,
                                                                      "dospijece": BillDospijece, "VTE": BillVte, "kamata": BillKamata, "snaga": BillSnaga, "pnb": PNB, "paid":tmpPaid, 
                                                                      "paidFlag": paidBill, "dateOwed": BillMonth + "/" + BillYear});


                                                                 inty = inty +1;
                                                       
                                                       
                                                       }else if(parseFloat(BillPaid) > 0 || BillNameTmp=="Uplata"){

                                                            arrBrojiloPaid.push({"name": BillNameTmp, "kupacId": BillBuyerCodeTmp, "date": BillDateTmp, "owed":parseFloat(BillPaid).toFixed(2),
                                                            "id":inty2, "pnb": PNB,
                                                            "dospijece": BillDospijece, "VTE": BillVte, "kamata": BillKamata, "snaga": BillSnaga});




                                                            inty2 = inty2 +1;

                                                       }else if(BillStorno == 1){


                                                                 arrBrojiloOwed.push({"name": BillNameTmp, "kupacId": BillBuyerCodeTmp, "date": BillDateTmp, "owed":parseFloat(BillOwed).toFixed(2), 
                                                                 "id":inty,
                                                                 "dospijece": BillDospijece, "VTE": BillVte, "kamata": BillKamata, "snaga": BillSnaga, "pnb": PNB, "paid":tmpPaid, 
                                                                 "paidFlag": paidBill, "dateOwed": BillDateTmp});



                                                                 inty = inty +1;

                                                       }



                                             }



                                             setBrojiloListOwed(arrBrojiloOwed);

                                             setActBillDate(arrBrojiloOwed[0].date);
                                             setActBillOwed(arrBrojiloOwed[0].owed);
                                             setActBillDue(arrBrojiloOwed[0].dospijece);
                                             setActBillPaid(arrBrojiloOwed[0].paid);
                                             setActBillPNB(arrBrojiloOwed[0].pnb);

                                             setBrojiloListPaid(arrBrojiloPaid);



                                        
                                   }else{

      
                                             alert(dataTmp.message);

                                   }
            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoading(false);

             
                       if (error.response.status == 401) {
             
                         alert(error.response.data.message)
             
                         logOut();
             
                       } else {
             
                         alert(error.response.data.message);
             
                         
                       }
                     }
             
             
             });
             
             
}            



///////////////////////////////////////////////////////////////////////////////////////////////////////////////
//dobavi podatke za graf
///////////////////////////////////////////////////////////////////////////////////////////////////////////////



function getDataAnaliza (opcija)  {


     setRDefault();

     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         
     APIservice.GetMeterStateAdmin(opcija, numLines, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoading(false);

                                   //console.log(response.data.meterItemList);

                                   var dataTmp = response.data;


                                   if(dataTmp.meterItemList.length !=0){



                                             var arrOcitanja = [];
                                             var arrTrosak = [];



                                             var inty = 0;
                                             var inty2 = 0;

                                             var initID = 0;

                                             let dataArrAll = [];
                                             let labelArrayAll = [];

                                             let dataArr = [];
                                             let labelArray = [];
                                             let dataArrR1 = [];
                                             let dataArrR2 = [];
                                             let dataArrR3 = [];
                                             

                                             if(dataTmp.spentList.length != 0){




                                                       for(var propT in dataTmp.spentList) {


                                                            var flagT = 0;


                                                            var spentDateODTmp = "";
                                                            var spentDateDOTmp = "";
                                                            var spentR1Tmp = 0;
                                                            var spentR2Tmp = 0;
                                                            var spentR3Tmp = 0;

                                                            var spentKPCTmp = "";


                                                            for(var propT2 in dataTmp.spentList[propT]){


                                                                 if(propT2 === 'DATUMOD'){

                                                                      spentDateODTmp = dataTmp.spentList[propT][propT2];


                                                                 }else if (propT2 === "DATUMDO"){

                                                                      spentDateDOTmp = dataTmp.spentList[propT][propT2];



                                                                 }else if (propT2 === "KPC_ID"){

                                                                      spentKPCTmp = dataTmp.spentList[propT][propT2];


                                                                 }else if (propT2 === "R1"){

                                                                      spentR1Tmp = dataTmp.spentList[propT][propT2];



                                                                 }else if (propT2 === "R2"){

                                                                      spentR2Tmp = dataTmp.spentList[propT][propT2];


                                                                 }else if (propT2 === "R3"){

                                                                      spentR3Tmp = dataTmp.spentList[propT][propT2];



                                                                 } else{

                                                                      //nema ostalih


                                                                 }

                                                            }


                                                            arrTrosak.push({"name": "Potrošnja po brojilima", "date": "Razdoblje: " + spentDateODTmp + " do " + spentDateDOTmp, "kupacId": spentKPCTmp,
                                                                                "r1":spentR1Tmp, "r2":spentR2Tmp,"r3":spentR3Tmp, "id":inty2});


                                                            labelArray.push(spentDateDOTmp);

                                                            let tmpR1 = 0;
                                                            let tmpR2 = 0;
                                                            let tmpR3 = 0;



                                                                 
                                                            if(spentR1Tmp == 0){


                                                                      dataArrR1.push(0);


                                                                 
                                                            }else{

                                                                      dataArrR1.push(spentR1Tmp);

                                                                      tmpR1 = spentR1Tmp;
                                                            }

                                                                 
                                                            if(spentR2Tmp == 0){

                                                                      dataArrR2.push(0);

                                                            }else{

                                                                      dataArrR2.push(spentR2Tmp);


                                                                      tmpR2 = spentR2Tmp;
                                                            }



                                                                 
                                                            if(spentR3Tmp == 0){


                                                                      dataArrR3.push(0);

                                                            }else{

                                                                      dataArrR3.push(spentR3Tmp);

                                                                      tmpR3 = spentR3Tmp;
                                                            }




                                                                 
                                                            dataArrAll.push({"id": inty2, "label": spentDateDOTmp, "R1": tmpR1, "R2": tmpR2, "R3": tmpR3});


                                                            inty2 = inty2 +1;




                                                       }


                                                       setListaTrosak(arrTrosak);

                                                       


                                                       let tmpLabels = labelArray.reverse();
                                                       let tmpData = dataArrR1.reverse();

                                                       let tmpAll = dataArrAll.reverse();

                                                       setListaGrafovi(tmpAll);

                                                       setXAxis(tmpLabels);
                                                       setYAxis(tmpData);


                                                       setActiveType("R1");
                                                       setActiveYear("20");
                                                       setActiveYearName("Sve");


                                                       
                                             }else{


                                                       setListaTrosak([]);
                                                       alert( "Nema podataka o potrošnji za odabrano mjerno mjesto");
                                             }



                                             //ucitaj sve KPC_ID

                                             var arrBrojilo = [];
                                             var inty3 = 0;

                                             var paid = 0.00;
                                             var owed = 0.00;


                                             for(var propT in dataTmp.meterList) {


                                                       var flagT = 0;


                                                       var MeterNameTmp = "";
                                                       var MeterAddressTmp = "";
                                                       var MeterBuyerCodeTmp = "";
                                                       var MeterFactCodeTmp = "";
                                                       var MeterR1Tmp = 0;
                                                       var MeterR2Tmp = 0;
                                                       var MeterR3Tmp = 0;
                                                       var MeterTipSaldoTmp ="";
                                                       var MeterSaldoTmp = 0.00;
                                                       var MeterDateTmp = "";

                                                       var MeterOMM = "";

                                                       var tempMeterObj;

                                                       for(var propT2 in dataTmp.meterList[propT]){




                                                                      if(propT2 === 'MeterName'){

                                                                           MeterNameTmp = dataTmp.meterList[propT][propT2];



                                                                      }else if (propT2 === "MeterAddress"){

                                                                           MeterAddressTmp = dataTmp.meterList[propT][propT2];


                                                                      }else if (propT2 === "MeterBuyerCode"){

                                                                           MeterBuyerCodeTmp = dataTmp.meterList[propT][propT2];


                                                                      }else if (propT2 === "MeterFactCode"){

                                                                           MeterFactCodeTmp = dataTmp.meterList[propT][propT2];


                                                                      }else if (propT2 === "MeterR1"){

                                                                           MeterR1Tmp = dataTmp.meterList[propT][propT2];

                                                                      }else if (propT2 === "MeterR2"){

                                                                           MeterR2Tmp = dataTmp.meterList[propT][propT2];


                                                                      }else if (propT2 === "MeterR3"){

                                                                           MeterR3Tmp = dataTmp.meterList[propT][propT2];


                                                                      }else if (propT2 === "MeterSaldo"){

                                                                           MeterSaldoTmp = dataTmp.meterList[propT][propT2];



                                                                      }else if (propT2 === "MeterTipSalda"){

                                                                           MeterTipSaldoTmp = dataTmp.meterList[propT][propT2];


                                                                      }else if (propT2 === "Datum"){

                                                                           MeterDateTmp = dataTmp.meterList[propT][propT2];


                                                                      }else if (propT2 === "OMM"){

                                                                           MeterOMM = dataTmp.meterList[propT][propT2];



                                                                      } else{

                                                                           //nema ostalih


                                                                      }


                                                       }

                                                       let tmpValue = parseFloat(MeterSaldoTmp);
                                                       
                                                       tmpValue = tmpValue.toFixed(2);


                                                       let tmpValueString = "";

                                                       if(tmpValue!=0){
                                                       

                                                            //tmpValue = - tmpValue;

                                                            if(tmpValue > 0){

                                                                      //stanje je dug

                                                                      tmpValueString = tmpValue;

                                                            

                                                            }else{

                                                                      //stanje je preplata

                                                                      tmpValue = -tmpValue;

                                                                      tmpValueString = 'U preplati: ' + tmpValue; 

                                                            }

                                                       }else{

                                                            //stanje je nula, dodaj string

                                                            tmpValueString = tmpValue;

                                                       }


                                                       //arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, "stanje": tmpValue, "id":inty});
                                                       arrBrojilo.push({"name": MeterNameTmp, "text": MeterBuyerCodeTmp, "stanje": tmpValueString, "id":inty});






                                                       inty3 = inty3+1;


                                             }



                                        
                                   }else{

      
                                             alert(dataTmp.message);

                                   }
            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoading(false);

             
                       if (error.response.status == 401) {
             
                         alert(error.response.data.message)
             
                         logOut();
             
                       } else {
             
                         alert(error.response.data.message);
             
                         
                       }
                     }
             
             
             });
             
             
}            



*/

function filterYear (year, type){


     let tmpArray = listaGrafova.filter(data => (data.label.startsWith(year)));
   
   
     let privremenoArr = [];
   
     let privremenoLabels = [];
   
     tmpArray.forEach(myFunction);
   
     function myFunction(item, index) {
   
         privremenoLabels.push(item.label);
   
         if(type=="R2"){
   
                 if(item.R2==0){
   
   
                         privremenoArr.push(0);
                 }else{
   
                         privremenoArr.push(item.R2);
                 }
   
   
   
   
   
         }else if(type=="R3"){
   
                 if(item.R3==0){
   
   
                         privremenoArr.push(0);
                 }else{
   
                         privremenoArr.push(item.R3);
                 }
   
   
         }else{
   
                 if(item.R1==0){
   
   
                         privremenoArr.push(0);
                 }else{
   
                         privremenoArr.push(item.R1);
                 }
   
   
         }
   
   
     }
   
   
     setXAxis(privremenoLabels);
     setYAxis(privremenoArr);
   
   
   }
   
   
   
   function setRDefault(){
   
        var x = Array.from(document.querySelectorAll(".analiza-option")).map(item=>{
             item.classList.remove("potrosnjaOptionIsSelected")
             });
        
        var y = document.querySelector(".analiza-option.potrosnja-option1").classList.add("potrosnjaOptionIsSelected");
        
   
   }


   



    function getStanjeData() {
        


        getItemsStanje(buyerCode); 
    }



    /*
    function getPrometData() {
        

        getDataPromet (buyerCode);

    }
    function getAnalizaData() {
        

        getDataAnaliza (buyerCode); 
    }
    function getKontaktData() {
        

        alert("Komunikacijska sučelja prema korisničkoj službi nisu postavljena");
    }
*/


/*
    
function convertPDF (kpc_id, pnb)  {


     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoadingBill(true);
     setIsLoading(true);
         



     APIservice.ConvertPDF(kpc_id, pnb, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoadingBill(false);
                                   setIsLoading(false);


                                   var pdfLinkTmp = response.data.PDFLink;



                                   let pdfString = API + 'PDFViewer';

                                   //let pdfFinal = pdfString + '?appId=' + appId + '&token=' + token + '&fileID=' + pdfLinkTmp;
                                   let pdfFinal = pdfString + '?fileID=' + pdfLinkTmp;


                                   setPdfLink(pdfFinal);

                                   setShowBill(true);


            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoadingBill(false);

                       setIsLoading(false);


             
                       if (error.response.status == 401) {
             
                              alert(error.response.data.message)
               
                              logOut();
             
                       } else {
             
                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);
             
                         
                       }
                     }
             
             
             });
             
             
}       

*/



function AddMeterAdmin (userID, addFctCode, addBuyCode, addName) {


     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         



     APIservice.AddMeterAdmin(userID, addFctCode, addBuyCode, addName, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoading(false);

                                   //console.log(response.data.message);


                                   setAddBuyCode("");
                                   setAddFctCode("");
                                   setAddName("");
  
                                   toast.success(response.data.message, toastSuccess);
  

  
                                   getDataBrojila(userID);


            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);



                       

                       setIsLoading(false);

                       //setAddName("");
                       //setAddFctCode("");
                       //setAddBuyCode("");
       
                       //setAddingMeter(false);
             


                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                         } else {


                              if(!error.response.status){

                                   toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{

                                   if (error.response.status == 401) {
               
                                        toast.error(error.response.data.message,
                                             toastError);
                                     
                                        setTimeout(() => {
                                                       logOut();
                                        }, 5000); 
                                   
                              
                                   } else {
                              
                                             toast.error(error.response.data.message, toastError)
                              
                                        
                                   }
                              }
                         }



                     }
             
             
             });
             
             
}            



function deleteMeterAdmin (meterID) {


     //console.log("Attempting delete: " + meterID);

     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         



     APIservice.DeleteMeterAdmin(meterID, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoading(false);

                                   //console.log(response.data.message);


  
                                   toast.success(response.data.message, toastSuccess);
  

  
                                   getDataBrojila(inputKorisnika);


            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoading(false);


             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                         } else {


                                   if(!error.response.status){

                                        toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                                   }else{

                                        if (error.response.status == 401) {
                    
                                             toast.error(error.response.data.message,
                                                  toastError);
                                          
                                             setTimeout(() => {
                                                            logOut();
                                             }, 5000); 
                                        
                                   
                                        } else {
                                   
                                                  toast.error(error.response.data.message, toastError)
                                   
                                             
                                        }
                                   }
                         }
                     }
             
             
             });
             
             
}       




function DeleteUserAccount () {


     console.log("Attempting delete: " + inputKorisnika);

     let unmounted = false;
     let source = axios.CancelToken.source();

     setIsLoading(true);
         



     APIservice.DeleteUserAccount(inputKorisnika, source.token).then(

             (response) => {
 
                     if (!unmounted) {
             
                                   //console.log(response);


                                   setIsLoading(false);

                                   //console.log(response.data.message);


  
                                   toast.success(response.data.message, toastSuccess);
  


                                   setInputKorisnika('');
                                   setInputKorisnikaShow('');

                                   setAllMeters([]);

                                   setStanje('');
                                   setNameOwner('');
                                   setAdresa('');
                                   setFctCode('');
                                   setBuyerCode('');

                                   setLastDate('');
                                   setR1('');
                                   setR2('');
                                   setUserId('');

                                   setListaOcitanje([]);
                                   setListaTrosak([]);                                  


            
                     }
             
             },
 
             (error) => {
 
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);

                       setIsLoading(false);


             
                         if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                         } else {


                                   if(!error.response.status){

                                        toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                                   }else{

                                        if (error.response.status == 401) {
                    
                                             toast.error(error.response.data.message,
                                                  toastError);
                                          
                                             setTimeout(() => {
                                                            logOut();
                                             }, 5000); 
                                        
                                   
                                        } else {
                                   
                                                  toast.error(error.response.data.message, toastError)
                                   
                                             
                                        }
                                   }
                         }
                     }
             
             
             });
             
             
}       



const handleDeleteMeter = (meterID) => {



     Swal.fire({
          title: `Jeste li sigurni da želite obrisati mjerno mjesto?`,
          text: "Ova radnja se ne može poništiti",
          icon: "warning",
          iconSize: '5',

          showCancelButton: true,
          confirmButtonColor: "#e31b2e",
          cancelButtonColor: "#29648a",
          showCancelButton: true,
          confirmButtonText: "Da, obriši mjerno mjesto!",
          cancelButtonText: "Odustani",
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {

               deleteMeterAdmin(meterID)
               var copyAllMeters = allMeters;
               var filteredCopyAllMeters = copyAllMeters.filter(meter2 => {
                    return meter2.text !== meterID
               })
               
               setAllMeters(filteredCopyAllMeters)
          }
        });


};

const handleAddMeter = () => {

	//console.log("add the meter!");
	setShowAddingModal(true);
}

const handleSearchMeter = () => {

     //console.log("Checking my meter");

     //console.log(inputMjMjesto);

     if(inputMjMjesto && inputMjMjesto != ''){

          getItemsStanje(inputMjMjesto);


     }
}

const handleSearchUser = () => {

     //console.log("Checking my user");

     //console.log(inputKorisnika);

     if(inputKorisnika && inputKorisnika != ''){

          getDataBrojila(inputKorisnika)


     }
}

const handleCloseModal = () => {

	//console.log("Closing the modal!");

	setAddName("");
	setAddFctCode("");
	setAddBuyCode("");

	setShowAddingModal(false);

}


const handleDeleteUserAcc = () => {

	console.log("Deleting the user!");

     Swal.fire({
          title: `Jeste li sigurni da želite obrisati korisnika ${inputKorisnika}?`,
          text: "Ova radnja se ne može poništiti",
          icon: "warning",
          iconSize: 5,

          showCancelButton: true,
          confirmButtonColor: "#e31b2e",
          cancelButtonColor: "#29648a",
          showCancelButton: true,
          confirmButtonText: "Da, obriši korisnika!",
          cancelButtonText: "Odustani",
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {



               DeleteUserAccount();
          }
        });

   
	
}




var steps = [
     {
       target: '.menuLists',
       content: `Kao administrator imate dozvolu pregleda svih mjernih mjesta i korisničkih računa na sustavu. U izborniku na 
       lijevoj strani morate unijeti kriterij pretrage.`,
       disableBeacon: true,
       placement: "right-start"
     },
     {
          target: '.brojila-admin-checkbox-mjMjesto',
          content: `Ukoliko želite pregledati sve dostupne informacije o pojedinačnom mjernom mjestu odaberite opciju "Mjerno mjesto" te unesite tvornički broj mjerila kojeg želite provjeriti. Više informacija o odabranom mjernom mjestu, 
          trenutno stanje, kao i pregled dostupnih očitanja i potrošnje će se nakon pretrage pojaviti na desnoj strani ekrana.
          Na istom mjestu možete vidjeti i je li traženo mjerno mjesto pridijeljeno nekom korisničkom računu unutar korisničkog sustava.`,
          disableBeacon: true,
          placement: "right"
     },     

     {
          target: '.brojila-admin-checkbox-korisnika',
          content: `Pretragom prema korisničkom imenu možete, nakon unosa e-mail adrese korisnika, vidjeti podatke o mjernim mjestima koja su vezana za traženog korisnika. 
          Kao administrator usluge možete, po potrebi, brisati ili dodavati mjerna mjesta korisnicima koji to nisu u mogućnosti napraviti sami. Mjerna mjesta korisnicima dodajete odabirom ikone "+" koja je dostupna na desnoj strani ekrana
           pri pregledu korisničkih informacija, a brišete odabirom ikone "x" koja se nalazi pokraj imena mjernog mjesta koje želite obrisati. Također možete u potpunosti obrisati korisnički račun ukoliko to korisnik zatraži tako što ćete kliknuti na 
           e-mail adresu korisnika na desnoj strani ekrana te u padajućem izborniku odabrati opciju "Obriši korisnički račun". Ta akcija automatski miče sva vezana mjerna mjesta s korisničkog računa te briše sve korisničke postavke sa sustava.`,
          disableBeacon: true,
          placement: "right"
     },                  
      
]


     const handleJoyrideCallback = (data) => {

          //console.log("JOyride callback");
          //console.log(data);
          const { status, type } = data;
          //const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
          if ((data.status == "finished" || data.status == "skipped")) {
            setRunTour(false);
          }

     }


     const handleJoyrideStart =()=>{

          setRunTour(true);
     }




return (
     <div className="brojila">


							<ToastContainer
                                        position="top-center"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme="colored"
                                   />   




<div id="container1">

<JoyRide 
run={runTour}
steps={steps} 
continuous={true} 
showSkipButton={true} 
callback={handleJoyrideCallback}

locale={{
     skip: "Preskoči",
     last:"Završi", 
     back:"Natrag",
     next: "Dalje"}}
     
styles={{
     options: {
          arrowColor: "#f4f6f9",
          primaryColor: "#29648a",
          textColor: "#72a0c1",
          backgroundColor: "#f4f6f9",
          borderTopRadius: 10,
          borderWidth:0,
          width: 500,
          zIndex: 10000,
          fontSize: 14,
          
     },
     buttonNext: {
          backgroundColor: "#29648a",
          title: "Dalje",
          text:"Dalje",
          border: "none",
          borderRadius: 10,
          color: "white",
          fontSize: 14,
          "&:focus": {
          outline: "none !important",
          },
     },
     buttonBack: {
          color: "#29648a",
          marginLeft: "auto",
          marginRight: 5,
          fontSize: 14,
     },
     buttonClose: {
          color: "#29648a",
          height: 14,
          padding: 15,
          position: "absolute",
          right: 0,
          top: 0,
          width: 14,
          
     },
     buttonSkip: {
          backgroundColor: "#e31b2e",
          title: "Preskoči",
          text:"Preskoči",
          border: "none",
          borderRadius: 10,
          color: "white",
          fontSize: 14,
          "&:focus": {
          outline: "none !important",
          },                                   
     }
     }}
/>

</div>


                              {showAddingModal && <div id="myModal" class="brojilaAdmin-modal" >




                                   <div class="brojilaAdmin-modal-content">

								<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height:'30px',
									background: '#72a0c1', height: '35px',
									borderTopLeftRadius: '8px', borderTopRightRadius: '8px', padding: '10px'}}>

									<div style={{color: 'white', fontSize: '1.1rem', 
										fontWeight:'bold'}}>Dodavanje mjernog mjesta korisniku</div>
									
									<button 
													
										className='modal-closeModal'
										onClick={() => handleCloseModal()}
										>
										<DeleteIcon style={{fill: '#ffffff', }} size="0.5x"/>
									</button>   

								</div> 

                                             {checkKorisnika && <div className="addMeterInfoAdmin">
                                             
                                             <input className="addMeterName" type="text" placeholder="Unesite ime kupca" value={addName}

                                                  onChange={e => {
                                                  
                                                  setAddName(e.target.value) ;
                                                  }}>

                                             </input>                             

                                             


                                             <input className="addMeterFctCode" type="text" pattern="[0-9]*" maxLength="30" placeholder="Unesite broj brojila" value={addFctCode}

                                                  onChange={e => {
                                                  
                                                  
                                                       setAddFctCode(e.target.value.replace(/\D/,'')) ;
                                                  }}>

                                             </input>

                                             <input className="addMeterBuyer" type="text" pattern="[0-9]*" maxLength="30" placeholder="Unesite šifru kupca" value={addBuyCode}

                                                  onChange={e => {
                                                  
                                                  
                                                       setAddBuyCode(e.target.value.replace(/\D/,'')) ;
                                                  }}>

                                             </input>
                                             <button className="addMeterInfoBtn" disabled={!(addFctCode&&addBuyCode)} onClick={e=>{
                                                            

                                                            if(addFctCode != ''){
                                                                   
                                                                 if(addBuyCode != ''){

                                                                           AddMeterAdmin(inputKorisnika, addFctCode, addBuyCode, addName);

                                                                 } else {

                                                                           
                                                                           toast.error("Morate unijeti šifru kupca", toastError);
                                                                 }

                                                            } else {

                                                                 toast.error("Morate unijeti tvornički broj brojila", toastError);
                                                            }
                                                            

                                                            
                                                            }}>Dodaj</button>
                                                                                      
                                             </div>}                   

                                   </div>

                              </div>}





           <div className="menuLists">
 
               <ul>
           
                     <div className="brojila-admin-menuTitle">Unesite</div>
                     
                         <label className='brojila-admin-label brojila-admin-label-mjMjesto'>
                              Mjerno mjesto:
                              <input    
                                        className='brojila-admin-checkbox brojila-admin-checkbox-mjMjesto'
                                        name="mjMjesto"
                                        type="checkbox"
                                        checked={checkMjMjesto}
                                        onChange={(e)=>{
                                             setCheckMjMjesto(true) ;
                                             setCheckKorisnika(false);
                                             setInputKorisnika('');
                                             setInputKorisnikaShow('');
                                             setAllMeters([]);

                                             setStanje('');
                                             setNameOwner('');
                                             setAdresa('');
                                             setFctCode('');
                                             setBuyerCode('');

                                             setLastDate('');
                                             setR1('');
                                             setR2('');
                                             setUserId('');
 
                                             setListaOcitanje([]);
                                             setListaTrosak([]);


                                        }} 
                                        />


                         </label>
                         
                         <div className='search-mjerno-mjesto'>
                         <input 
                              className="brojila-admin-inputCode brojilla-admin-inputCode-mjMjesto" 
                              type="text" placeholder="Unesite tvornički broj" 
                              pattern="[0-9]*" maxLength="30"
                              value={inputMjMjesto}
                              disabled={checkKorisnika ? "disabled" : ""}          
                                        onChange={e => {
                                             setInputMjMjesto(e.target.value) ;
                                             }}>
     
                         </input>

                         <div style={{color: 'white'}}>
                                        <button 
                                                            
                                             className='mjerno-mjesto-icon'
                                             onClick={() => handleSearchMeter()}
                                        >
                                                  <SearchIcon style={{fill: '#29648a', margin: '0 0 10px 0'}} size="1x"/>
                                        </button>    

                         </div>  

                         </div>                      
 
                     
                         <label className='brojila-admin-label brojila-admin-label-korisnika'>
                              Korisničko ime:
                              <input    
                                        className='brojila-admin-checkbox brojila-admin-checkbox-korisnika'
                                        name="korisnika"
                                        type="checkbox"
                                        checked={checkKorisnika}
                                        onChange={(e)=>{

                                             setCheckMjMjesto(false);
                                             setCheckKorisnika(true);
                                             setInputMjMjesto('');
                                             setAllMeters([]);

                                             setStanje('');
                                             setNameOwner('');
                                             setAdresa('');
                                             setFctCode('');
                                             setBuyerCode('');

                                             setLastDate('');
                                             setR1('');
                                             setR2('');
                                             setUserId('');

                                             setListaOcitanje([]);
                                             setListaTrosak([]);



                                        }} 
                                        />

                         </label>

                         <div className='search-mjerno-mjesto'>
                         <input 
                              className="brojila-admin-inputCode brojilla-admin-inputCode-Korisnika" 
                              type="text" placeholder="Unesite korisničko ime" 
                              maxLength="40"
                              value={inputKorisnika}
                              disabled={checkMjMjesto ? "disabled" : ""}
                                        
                                        onChange={e => {
                                        
                                             setInputKorisnika(e.target.value);
     
     
                                        }}>
     
                         </input>

                         <div style={{color: 'white'}}>
                                        <button 
                                                            
                                             className='mjerno-mjesto-icon'
                                             onClick={() => handleSearchUser()}
                                        >
                                                  <SearchIcon style={{fill: '#29648a', margin: '0 0 10px 0'}} size="1x"/>
                                        </button>    

                         </div>  

                         </div>     
 
                     

                                 
 
               </ul>


               <div style={{marginTop: 20}}><button 
													
                                                                 className='infoButton'
                                                                 onClick={() => handleJoyrideStart()}
                                                                 >
                                                                 <InfoIcon style={{fill: '#29648a', }} size="0.7x"/>
                         </button>   
                    </div>                       
           </div>
           
           <div className="container-home" style={{display:checkKorisnika? 'none' : 'unset'}}>
 
                   <div className="main-list">
                           <div id="stanje" className="main-list-item-admin main-list-item1 mainListItemIsSelected" onClick={(e)=>{
                                   handlePrimaryListItem(e);
                                   getStanjeData();
                             }}>Stanje</div>
 

 
                   </div>
 
                   <div className="main-content main-content-stanje">

                     <div className="stanje-container">
                           <div className="stanje-body">
                                 
                                 <div className="stanje-first-column">
                                 <div className="stanje-stanje"> Stanje duga: </div> 
                                         <div className="stanje-val">{stanje} KM</div>
                                         <div className="stanje-stanje"> Ime vlasnika: </div>
                                         <div className="stanje-val">{nameOwner}</div>
                                         <div className="stanje-stanje">Adresa: </div>
                                         <div className="stanje-val">{adresa}</div>
 
                                         <div className="stanje-stanje">Šifra brojila:</div> 
                                         <div className="stanje-val">{fctCode}</div>
                                         <div className="stanje-stanje">Šifra kupca:</div> 
                                         <div className="stanje-val">{buyerCode}</div>
                                 </div>
                                 <div className="stanje-second-column">
                                         <div className="stanje-stanje">Datum zadnjeg očitanja: </div>
                                         <div className="stanje-val">{lastDate}</div>
                                         <div className="stanje-stanje">Tarifa R1: </div>
                                         <div className="stanje-val">{R1}</div>
                                         <div className="stanje-stanje">Tarifa R2: </div>
                                         <div className="stanje-val">{R2}</div>
                                         <div className="stanje-stanje">Korisnik </div>
                                         <div className="stanje-val">{userId}</div>
                                         {/*<div className="stanje-stanje">Tarifa R3: </div>
                                         <div className="stanje-val">{R3}</div>*/}
 
                                 </div>
                         </div>
                     </div>
 
                     <div className="main-content-potrosnja">
                           
                           <div className="potrosnja-options">
                                   <div id="listaTrosak" className="potrosnja-option potrosnja-option1 potrosnjaOptionIsSelected" onClick={(e)=>handleSubOption(e.target)}>Potrošnja</div>
                                   <div id="listaOcitanje" className="potrosnja-option potrosnja-option1" onClick={(e)=>handleSubOption(e.target)}>Očitanja</div>
                           </div>
 
                           <div className="potrosnja-body">
                                   <div  className="lista-potrosnja listaTrosak">
                                           {listaTrosak && listaTrosak.map((item, index)=> {
                                            
                                             return (
                                               <div className="potrosnja-item">
                                                       <div className="potrosnja-item-date">
                                                            {item.date}
                                                       </div>

                                                       <div className="potrosnja-item-r">
                                                             <div className="potrosnja-item-r1">
                                                                 R1:     {item["r1"]}
                                                             </div>
                                                             <div className="potrosnja-item-r2">
                                                                 R2:     {item["r2"]}
                                                             </div>
                                                             {/*<div className="potrosnja-item-r3">
                                                                 R3:     {item["r3"]}
                                                             </div>*/}
                                                       </div>
 
                                               </div>
                   
                                               )
                                               })}
                                   </div>
 
 
                                   <div  className="lista-potrosnja listaOcitanje isHidden">
                                             {listaOcitanje && listaOcitanje.map((item, index)=> {
                                                   return (
                                                     <div className="potrosnja-item">
                                                           <div className="potrosnja-item-date">
                                                                {item.date}
                                                           </div>

                                                           <div className="potrosnja-item-nacin">
                                                                 Nacin:&nbsp;{item.name}&nbsp;&nbsp;  Obracunato:&nbsp;{item.obrac}
                                                            </div>
                                                                                                                 
                                                            <div className="potrosnja-item-r">
                                                               <div className="potrosnja-item-r1">
                                                                   R1:     {item["r1"]}
                                                               </div>
                                                               <div className="potrosnja-item-r2">
                                                                   R2:     {item["r2"]}
                                                               </div>
                                                               {/*<div className="potrosnja-item-r3">
                                                                   R3:     {item["r3"]}
                                                                  </div>*/}
                                                           </div>
 
                                                     </div>
                                                     
                                                   )
                                                 })}
                                   </div>
                           </div>
           
 
                   </div>
                          
                           
                   </div>
 
                   
 
                     <div className="main-content main-content-promet isHidden">
                               <div className="promet-container">      
                          {/*-------------------------------------*/}
                          <div className="potrosnja-options">
                                         <div id="listaRacuni" className="promet-option potrosnja-option1 prometOptionIsSelected" onClick={(e)=>handleSubOptionPromet(e.target)}>Računi</div>
                                         <div id="listaUplata" className="promet-option potrosnja-option1" onClick={(e)=>handleSubOptionPromet(e.target)}>Uplate</div>
                               </div>
                          {/*-------------------------------------*/}
 
                          {/*-------------------------------------*/}
 
 
                               <div className="potrosnja-body">
                                    <div  className="lista-racun listaRacuni listaTrosak">
                                           {BrojiloListOwed && BrojiloListOwed.map((item, index)=> {
                                             //console.log(item)
                                             return (
                                               <div className="potrosnja-item" onClick={e=>{
 
                                                   
                                                   
                                                   setActBillDate(item.dateOwed);
                                                   setActBillOwed(item.owed);
                                                   setActBillPaid(item.paid);
                                                   setActBillDue(item.dospijece);
                                                   setActBillPNB(item.pnb);
 
                                                   //setBillBack("red");
 
 
 
 
                                              }}> 
                                                       <div className="potrosnja-item-date">
                                                         {item.name}
                                                       </div>
                                                       <div className="potrosnja-item-r">
                                                             <div className="potrosnja-item-r1">
                                                                  Datum: {item.dateOwed}
                                                             </div>
                                                             <div className="potrosnja-item-r2">
                                                                 Iznos:     {item.owed} KM
                                                             </div>
                                                       </div>
 
                                               </div>
                   
                                               )
                                               })}
                                    </div>
 
 
 
                                    <div  className="lista-racun listaUplata listaOcitanje isHidden">
                                             {BrojiloListPaid && BrojiloListPaid.map((item, index)=> {
                                                   return (
                                                     <div className="potrosnja-item">
                                                           <div className="potrosnja-item-date">
                                                                 {item.name}
                                                           </div>
                                                           <div className="potrosnja-item-r">
                                                               <div className="potrosnja-item-r1">
                                                                    Datum:  {item.date}
                                                               </div>
                                                               <div className="potrosnja-item-r2">
                                                                   Iznos:     {item.owed} KM
                                                               </div>
 
                                                           </div>
 
                                                     </div>
                                                     
                                                   )
                                                 })}
                                    </div>
 
                                     {/*-------------------------------------*/}
                               </div>
                          </div>   
 
 
                          {!showBill && !isLoadingBill && <div className="racun-container">
                                    <div className="racun-info">
                                              <div className="stanje-stanje">Datum: </div> 
                                              <div className="stanje-value">{actBillDate} </div>
                                              <div className="stanje-stanje">Iznos: </div> 
                                              <div className="stanje-value">{actBillOwed} KM</div>
                                              {/*<div className="stanje-stanje">Račun </div> 
                                              <div className="stanje-value">{actBillPaid} </div>*/}
                                              <div className="stanje-stanje">Datum dospijeća: </div> 
                                              <div className="stanje-value">{actBillDue} </div>
                                              <div className="stanje-stanje">Poziv na broj </div> 
                                              <div className="stanje-value">{actBIllPNB} </div>
                                    </div>  
 
                                    <div className="racun-action">
 
                                         <button className="racun-PDF" onClick={e=>{
                                                   setIsLoadingBill(true);
                                                   //convertPDF (buyerCode, actBIllPNB);
                                                   }}>Učitaj račun</button>
 
 
 
                                    </div>
 
                          </div> }
 
 
                          {showBill && !isLoadingBill &&
                          <div className="racun-container">
                                    <button className="racun-PDF" onClick={e=>{
                                         setShowBill(false);
 
                                         }}>Zatvori</button>                        
                                    <object data={pdfLink} type="application/pdf" width="100%" height="100%">
                                                   <p><a href={pdfLink}></a></p>
                                    </object>
                          </div>}
 
                          {isLoadingBill && <Loader />}
 
 
                     </div> 
           
 
                     
  
 
 
 
                     <div className="main-content main-content-analiza isHidden">
                          <div className="analiza-content">
                         
                          <div className="brojila-selector">
                          <select className="brojila-selector-select" name="basic" id="ex-basic" 
                                                        value={activeYearName} 
                                                        onChange={(e)=>{
 
                                                        }}>
 
 
 
 
                                                        {yearList && yearList.map(item=>{
                                                             return (
                                                             <option value={item["name"]} id={item["value"]} onClick={(e)=>{
 
                                                                       var a = e.target.value;
                                                                       var b = e.target.id;
 
                                                                       setActiveYearName(a); 
                                                                       setActiveYear(b);
                                                                       
                                                                       setRDefault();
 
                                                                       filterYear(b, "R1");
                                                                       
                                                                       
 
                                                             }}>{item["name"]}</option>
                                                             )
                                                        })}
                                              </select>
                                    </div>
 
 
                                    <div className="potrosnja-options">
                                       <div id="listaTrosak" className="analiza-option potrosnja-option1 analizaOptionIsSelected" onClick={(e)=>{
                                                         handleSubOptionAnaliza(e.target); 
                                                         //createDataToGraphs(listaTrosak,"r1");
                                                         filterYear(activeYear, "R1");
                                                         
                                                         }}> R1 </div>
                                       <div id="listaOcitanje" className="analiza-option potrosnja-option2" onClick={(e)=>{
                                                         handleSubOptionAnaliza(e.target); 
                                                         //createDataToGraphs(listaTrosak,"r2");
                                                         filterYear(activeYear, "R2");
                                                         }}> R2 </div>
                                       {/*<div id="listaOcitanje" className="analiza-option potrosnja-option3" onClick={(e)=>{
                                                         handleSubOptionAnaliza(e.target); 
                                                         filterYear(activeYear, "R3");
                                                         }}> R3 </div>*/}
                                    </div>
                                    {/*<Line
                                              data={state}
                                              options={{
                                                   title: {
                                                   display: false,
                                                   text: "Potrošnja"
                                                   },
                                              scales: {
                                              yAxes: [
                                                   {
                                                   ticks: {
                                                        suggestedMin: -20,
                                                        suggestedMax: 100
                                                   }
                                                   }
                                              ]
                                              }
                                              }}
                                             />*/}
 
                          </div>
                     </div>
 
          </div>
          <div className="container-home" style={{display:checkMjMjesto? 'none' : 'unset'}}>

               <div className='main-content main-content-user-data'>

			
				<div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
                         <div className='user-data-title'>
                              Korisnik: 
                         </div>
        
                         <div className="user-data-email">
                              <div className='user-data-data' onClick={()=> {
                                   setUserSubMenuIsVisible(!userSubMenuIsVisible)
                              }}>
                                   {inputKorisnika}
                              </div>
                              <div className="user-data-submenu"  style={{opacity: userSubMenuIsVisible? "1" : "0", height: userSubMenuIsVisible? "69px" : "0px"}}>
                                   <div 
                                        
                                        className='user-data-close-submenu'
                                        onClick={() => {
                                             setUserSubMenuIsVisible(false);
                                             
                                        }
                                        }>
                                        <DeleteIcon style={{fill: '#29648a', }} size="0.5x"/>
                                   </div>   
                         
                                   <div className="user-data-delete"
                                   
                                        onClick={() => {
                                             setUserSubMenuIsVisible(false);
                                             handleDeleteUserAcc();
                                             
                                        }}       
                                   >
                                        Obrišite korisnički račun
                                   </div>
                                   {/*<div className="user-data-open-settings">
                                        Open settings
                                   </div>*/}
                              </div>
                         </div>                    

				</div>

				{inputKorisnika && inputKorisnikaShow && <button 
                                                            
					className='user-data-addUser'
					onClick={() => handleAddMeter()}
				>
					<AddIcon style={{fill: '#29648a', }} size="0.5x"/>
				</button> }   

                    {/*
				{inputKorisnika && <button 
                                                            
                              className='user-data-addUser'
                              onClick={() => handleDeleteUserAcc()}
                              >
                              <DeleteIcon style={{fill: '#e31b2e', }} size="0.5x"/>
                    </button> }  
                    */}

               </div>
          
               {inputKorisnika && inputKorisnikaShow && 
                    allMeters.map((meter, index)=> {
                         return(
                              <div className='main-content main-content-stanje-korisnika'>
                                   
                                   <div className='stanje-container-korisnika'>
                                             <div className='stanje-head-korisnika'>
                                                  <div style={{color: 'white', marginLeft: '10px'}}>{meter.name}</div>
                                                  <div style={{color: 'white'}}>
                                                            <button 
                                                            
                                                                 className='menu-deleteUser'
                                                                 onClick={() => handleDeleteMeter(meter.text)}
                                                            >
                                                                <DeleteIcon style={{fill: '#ffffff', }} size="0.5x"/>
                                                            </button>    

                                                  </div>
                                                  
                                             </div>
                                                 
                                             <div className="stanje-body-korisnika">





                                                  <div className="stanje-first-column">
                                                  <div className="stanje-stanje"> Stanje duga: </div> 
                                                            <div className="stanje-val">{meter.stanje} KM</div>
                                                            <div className="stanje-stanje"> Ime vlasnika: </div>
                                                            <div className="stanje-val">{meter.name}</div>
                                                            <div className="stanje-stanje">Adresa: </div>
                                                            <div className="stanje-val">{meter.address}</div>
                    
                                                            <div className="stanje-stanje">Šifra brojila:</div> 
                                                            <div className="stanje-val">{meter.meterCode}</div>
                                                            <div className="stanje-stanje">Šifra kupca:</div> 
                                                            <div className="stanje-val">{meter.buyerCode}</div>
                                                  </div>
                                                  <div className="stanje-second-column">
                                                            <div className="stanje-stanje">Datum zadnjeg očitanja: </div>
                                                            <div className="stanje-val">{meter.date}</div>
                                                            <div className="stanje-stanje">Tarifa R1: </div>
                                                            <div className="stanje-val">{meter.MeterR1}</div>
                                                            <div className="stanje-stanje">Tarifa R2: </div>
                                                            <div className="stanje-val">{meter.MeterR2}</div>
                                                            <div className="stanje-stanje">Korisnik </div>
                                                            <div className="stanje-val">{meter.user_id}</div>
                                                            {/*<div className="stanje-stanje">Tarifa R3: </div>
                                                            <div className="stanje-val">{R3}</div>*/}
                    
                                                  </div>
                                             </div>
     
                                   </div>
     
                              </div>
                         )
                    })
               }                              
          </div>                                  




 
 
           </div>
         
 
     );
 
 }
 
 
 export default BrojilaAdmin;