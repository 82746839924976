export const APIvar = {
    //address: "http://localhost:8080/EPHZHBSrv/",
    address: "https://korisnicka.ephzhb.ba/EPHZHBSrvNew/",


    //colorUse: "#2e86c1",
    colorUse: "#72a0c1",


    colorSecondary : 'gray',

    colorBackground : 'white',

    colorLines : 'gray',

    colorTouchableFont : 'white',
    colorTouchable : '#2e86c1',

    listColor:'white',

    appVersion:"1.0",

    listHeight : '12%',

    fontLarge : '2.5%',

    fontSmall : '2%',

    fontList : '2.2%',
    fontListSmall : '1.8%',

    paddList : '2%',
    paddTouchable: '0.5%',

    sectionHeaderPaddingVertical : '0.5%',
    sectionHeaderPaddingHorizontal : '2%',

    heightTouchable : '5%',
    iconModal : '3%',
    iconModalLarge: '5%',

    heightTouchModal : '4.5%',
};

//export const APIbase = "http://localhost:8080/";
export const APIbase = "https://korisnicka.ephzhb.ba/EPHZHBSrvNew/";

export const APPversion = "0.1";

export const APPid = "";

export const APPplatform = "3";

