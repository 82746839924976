import React from 'react';

import  { useState,useEffect } from 'react';

import  { Alert} from 'react';
import './css/contact.css';



import { useDispatch, useSelector } from "react-redux";

import { logout } from "../actions/auth";



import axios from 'axios';

import APIservice from "./services/api.service";

import PropTypes from 'prop-types';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from "./Loader";


import LoaderReg from "./LoaderReg";

import {ReactComponent as SendIcon} from '../img/send.svg';

import JoyRide from "react-joyride";

import {ReactComponent as InfoIcon} from '../img/information-circle.svg';






function Kontakt(props) {


 
  const [isLoading, setIsLoading] = useState(true);



  
  const [addedMsg, setAddedMsg] = useState("");
  const [addedEmail, setAddedEmail] = useState('');

  const [messages, setMessages] = useState([]);

  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [contactName, setContactName] = useState("");

  const [hasName, setHasName] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [hasPhone, setHasPhone] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);

  const [isPhone, setIsPhone] = useState(false);

  const [runTour, setRunTour] = useState(false);

 


  const { user: currentUser } = useSelector((state) => state.auth);

  const toastError = {                                                        
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
   };

   const toastSuccess = {                                                        
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: false,
             draggable: true,
             progress: undefined,
             theme: "light",
   };

   const toastInfo = {                                                        
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: false,
             draggable: true,
             progress: undefined,
             theme: "light",
   };


  const dispatch = useDispatch();








  useEffect(() => {


        //setAddedEmail(tmpEmail);
   
   
        getData();

   

        const allMenuTabs =  Array.from(document.querySelectorAll(".menu-item"));
        allMenuTabs.map((tab, index)=> {
             tab.classList.remove("menuItemIsSelected")
        })
        document.querySelector(".menu-item-6").classList.add("menuItemIsSelected");

        updateSize();

        window.addEventListener("resize", updateSize);

        return () => {
                window.removeEventListener("resize", updateSize);
              };

  }, []);

  const updateSize =()=> {
        let tmp = ((window.innerWidth < 500) ? true : false);

        //console.log("Window size is phone " + tmp);

        setIsPhone(tmp);
  }

const logOut = () => {
    
    dispatch(logout());
};









function sendMsg() {


    let unmounted = false;
    let source = axios.CancelToken.source();

    setIsLoading(true);
        
    APIservice.sendMessage(addedMsg, source.token).then(

            (response) => {

                    if (!unmounted) {
            
                                  //console.log(response);


                                  setIsLoading(false);




                                  toast.success(response.data.message, toastSuccess)


                                  setAddedMsg("");

                                  getData ();
           
                    }
            
            },

            (error) => {

                    if (!unmounted) {
            
                      //console.log("Error rq");
            
                      console.error(error);

                      setIsLoading(false);

            
                        if(!error.response){

                                toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                        } else {


                                if(!error.response.status){

                                        toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                                }else{
        
                                        if (error.response.status == 401) {
                    
                                                toast.error(error.response.data.message,
                                                        toastError);
                                                
                                                setTimeout(() => {
                                                                logOut();
                                                }, 5000); 
                                 
                                        } else {
                                 
                                                toast.error(error.response.data.message, toastError)
                                 
                                           
                                        }
                                }
                        }



                    }
            
            
            });
            
            
}            




function getData () {



    let unmounted = false;
    let source = axios.CancelToken.source();

    setIsLoading(true);
        
    APIservice.GetContactForm(source.token).then(

            (response) => {

                    if (!unmounted) {
            
                                //console.log(response);


                                setIsLoading(false);

                                
                                

                                var dataTmp = response.data;


                                if(dataTmp.contacts.length !=0){


                                            //dobavi listu za usera

                                            var nameTmp = "";
                                            var emailTmp = "";
                                            var phoneTmp = "";
                                            var addressTmp = "";


                                            for(var propT in dataTmp.contacts) {



                                                        //console.log(propT);


                                                        if(propT === 'name'){

                                                            //console.log("Name: " + dataTmp.contacts[propT]);

                                                            nameTmp = dataTmp.contacts[propT];



                                                        }else if (propT === "email"){

                                                            emailTmp = dataTmp.contacts[propT];


                                                        }else if (propT === "phone"){

                                                            phoneTmp = dataTmp.contacts[propT];


                                                        }else if (propT === "address"){

                                                            addressTmp = dataTmp.contacts[propT];


                                                        } else{

                                                            //nema ostalih


                                                        }
                                            }



                                            if(emailTmp != ""){

                                                    setHasEmail(true);
                                            }
                                                
                                            if(phoneTmp != ""){

                                                    setHasPhone(true);
                                            }
                                                
                                            if(addressTmp != ""){

                                                    setHasAddress(true);
                                            }
                                                
                                            if(nameTmp != ""){

                                                    setHasName(true);
                                            }    
                                                
                                            setContactEmail(emailTmp);                               
                                                
                                            setContactPhone(phoneTmp);
                                                
                                            setContactAddress(addressTmp);
                                                
                                            setContactName(nameTmp);

                                            //console.log("Stanje: " + hasName + "; " + nameTmp + "; " + hasEmail + "; " + emailTmp + "; " + hasAddress + "; " + addressTmp + "; " + hasPhone + "; " + phoneTmp)


                                }else{

     


                                            setContactEmail("");
                                            setContactPhone("");
                                            setContactAddress("");
                                            setContactName("");

                                            //console.log("Ne postoji nijedan dostupan kontakt");


                                }


                                if(dataTmp.messages.length !=0){

                                                //dobavi prijašnje poruke


                                                var msgCodeTmp = "";
                                                var msgContentTmp = "";

                                                var msgDateTmp = "";

                                                var msgTmp = [];

                                                var inty = 0;

                                                for(var propT in dataTmp.messages){

                                                        ////console.log("Props start: " + propT);

                                                        for(var propT2 in dataTmp.messages[propT]){

                                                                ////console.log("Props: " + propT + "; " + propT2);

                                                                if(propT2 === 'msg_code'){
                        
                                                                    msgCodeTmp = dataTmp.messages[propT][propT2];
                        
                        
                        
                                                                }else if (propT2 === "msg_content"){
                        
                                                                    msgContentTmp = dataTmp.messages[propT][propT2];
                        
                                                                
                                                                }else if (propT2 === "msg_time"){

                                                                        let tmpTime = 1000 * dataTmp.messages[propT][propT2];
                                                                        let tmpTime2 = new Intl.DateTimeFormat('hr-HR', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(tmpTime);
                                                                        
                                                                        

                                                                        msgDateTmp = tmpTime2;
                            
                                

                                                                } else{
                        
                                                                    //nema ostalih
                        
                        
                                                                }
                        
                        
                        
                        
                        
                                                        }

                                                        msgTmp.push({id: inty, "msgCode": msgCodeTmp, "msgContent": msgContentTmp, "msgDate": msgDateTmp});

                                                        inty = inty + 1;

                                                }        


                                                msgTmp.reverse();

                                                setMessages(msgTmp);

                                                //console.log(msgTmp);
     
                   
                                       
                                  }else{

     


                                            setMessages([]);


                                            //console.log("Ne postoji nijedna dostupna poruka");


                                  }

                                  
           
                    }
            
            },

            (error) => {

                    if (!unmounted) {
            
                      //console.log("Error rq");
            
                      console.error(error);

                      setIsLoading(false);



                      setMessages([]);

                      setContactEmail("");
                      setContactPhone("");
                      setContactAddress("");
                      setContactName("");

            
                        if(!error.response){

                                toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                        } else {

      
                                if(!error.response.status){

                                        toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                                }else{
                
                                        if (error.response.status == 401) {
                    
                                                toast.error(error.response.data.message,
                                                        toastError);
                                                
                                                setTimeout(() => {
                                                                logOut();
                                                }, 5000); 
                                      
                                 
                                        } else {
                                 
                                                toast.error(error.response.data.message, toastError)
                                 
                                           
                                        }
                                }
                        }


                    }
            
            
            });
            
            
}            



var steps = [
        {
          target: '.contact-first-title',
          content: `Na stranici "Kontakt" možete pregledati kontakt podatke JP Elektroprivrede HZ HB, vidjeti svoje ranije poruke poslane iz aplikacija 
          prema korisničkoj službi, kao i poslati novu poruku`,
          disableBeacon: true,
          placement: "right-start"
        },
        {
             target: '.contact-enter-name',
             content: `Ukoliko želite poslati novu poruku, tekst vaše poruke upišite u tekstualni okvir, te odaberite opciju "Pošalji".
             Odgovor ćete dobiti na svoju mail adresu kojom ste registrirani na uslugu.`,
             disableBeacon: true,
             placement: "right"
        },     
                
         
   ]
   
   
        const handleJoyrideCallback = (data) => {
   
             //console.log("JOyride callback");
             //console.log(data);
             const { status, type } = data;
             //const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
             if ((data.status == "finished" || data.status == "skipped")) {
               setRunTour(false);
             }
   
        }
   
   
        const handleJoyrideStart =()=>{
   
             setRunTour(true);
        }
   





return (
 
         

        <div className="contact">


        <div className="contact-first-part">

          
               
                {!isLoading && <div className="contact-first-title">
                      Pošaljite poruku
                </div>}
            
                <div style={{margin: 20, marginBottom: 20}}><button 
													
                             className='infoButton'
                                onClick={() => handleJoyrideStart()}
                                >
                               <InfoIcon style={{fill: '#29648a', }} size="0.7x"/>
                      </button>   
                </div>         

                <div className="contact-search">
              
                      {!isLoading && <div className="contact-enter-name">
                            <input className="contact-inputArea" type="text" placeholder={currentUser.email} value={currentUser.email}
                                        editable={false} selectTextOnFocus={false} disabled={true}
                            
                                  onChange={e => {}}>

                            </input>
                            
                            {!isPhone && <div className="contact-add">
                  
                                   <button  onClick={()=>{



                                              if(addedMsg != ''){

                                                    sendMsg();


                                              }else{

                                                    toast.error('Unesite poruku koju želite poslati', toastError);
                                              }

                                              
                                              
                                        }}>
                                        <SendIcon style={{fill: 'white', fontSize: '8px', marginRight:'10%'}} width={20} height={20} />
                                        Pošalji
                                  </button>
                                  
                                  </div>}

                                  {isPhone && <button className="addButtonSmall"  onClick={()=>{



                                                if(addedMsg != ''){

                                                sendMsg();


                                                }else{

                                                toast.error('Unesite poruku koju želite poslati', toastError);
                                                }



                                                }}>
                                                <SendIcon style={{fill: '#e31b2e', fontSize: '16x'}} width={26} height={26} />
                                                
                                </button>}

                            
                      
                      </div>}


                      {!isLoading && <div className="contact-enter-name">

                              <label className="contact-descLabel">
                                    
                                          <textarea className="contact-descriptionTA" name="description" placeholder="Vaša poruka:" maxLength="300" value={addedMsg}
                                                            rows={4}
                                                            cols={40}
                                                            onChange={e => {
                                                      
                                                            setAddedMsg(e.target.value) ;
                                                            }} />


                                         

                              </label>                           

                      </div>}



                      {/*{!isLoading && <div className="miniTitle">Vaše prethodno poslane poruke</div>}*/}

                      {!isLoading && <div className="contact-list">

                      <div className="item item-title">
                                             <div className="contact-list-description">
                                                  <div className="description-name description-name-title">Datum</div>
                                                  <div className="description-actDesc description-actDesc-title">Poruka</div>
                                             </div>
        

                                    </div>
                            {messages.map((element, index)=>{
                                return(

                                    

                                    <div className="item">
                                             <div className="contact-list-description">
                                                  <div className="description-name">{element.msgDate}</div>
                                                  <div className="description-actDesc">{element.msgContent}</div>
                                             </div>
        

                                    </div>
                                )
                                })}

                      </div>}

                      {isLoading && <Loader />}
                </div>
          
          </div>

          <div className="contact-second-part">
                
                <div className="contact-second-title">
                        Kontakt podaci:
                </div>
 
                <div className="contact-info">
                            {hasName && <div className="contact-stanje">Ime: <div className="contact-value">{contactName} </div></div>} 
                            {hasAddress && <div className="contact-stanje">Adresa: <div className="contact-value">{contactAddress}</div> </div> }

                            {hasPhone && <div className="contact-stanje">Telefon: <div className="contact-value">{contactPhone} </div></div> }
                            {hasEmail && <div className="contact-stanje">E-mail: <div className="contact-value">{contactEmail} </div></div> }
                </div>              


        
                <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                />         
          

                <div id="container1">

<JoyRide 
run={runTour}
steps={steps} 
continuous={true} 
showSkipButton={true} 
callback={handleJoyrideCallback}

locale={{
     skip: "Preskoči",
     last:"Završi", 
     back:"Natrag",
     next: "Dalje"}}
     
styles={{
     options: {
          arrowColor: "#f4f6f9",
          primaryColor: "#29648a",
          textColor: "#72a0c1",
          backgroundColor: "#f4f6f9",
          borderTopRadius: 10,
          borderWidth:0,
          width: 500,
          zIndex: 10000,
          fontSize: 14,
          
     },
     buttonNext: {
          backgroundColor: "#29648a",
          title: "Dalje",
          text:"Dalje",
          border: "none",
          borderRadius: 10,
          color: "white",
          fontSize: 14,
          "&:focus": {
          outline: "none !important",
          },
     },
     buttonBack: {
          color: "#29648a",
          marginLeft: "auto",
          marginRight: 5,
          fontSize: 14,
     },
     buttonClose: {
          color: "#29648a",
          height: 14,
          padding: 15,
          position: "absolute",
          right: 0,
          top: 0,
          width: 14,
          
     },
     buttonSkip: {
          backgroundColor: "#e31b2e",
          title: "Preskoči",
          text:"Preskoči",
          border: "none",
          borderRadius: 10,
          color: "white",
          fontSize: 14,
          "&:focus": {
          outline: "none !important",
          },                                   
     }
     }}
/>

                </div>   



          </div>

          </div>
          

            
    
    );
    

}



export default Kontakt;