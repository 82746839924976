import React, { useState, useEffect } from 'react';
import{Alert}from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useLocation
} from "react-router-dom";


import { useNavigate } from "react-router-dom";

import './css/Login.css'

import { FiUser } from "react-icons/fi";
import { FiLock } from "react-icons/fi";


import PropTypes from 'prop-types';


import Logo2 from './icons/EP.png';


import AuthService from "./services/auth.service"


import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
import { login } from "../actions/auth";

import { clearMsg } from '../actions/auth';

import { history } from "../helpers/history";

import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Login(props){


    const[ime,setIme]=useState('');
    const[sifra,setSifra]=useState('');



      
      

    const [isLoading, setIsLoading] = useState(true);

    const [attempt, setAttempt] = useState("1");

   
    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    const navigate = useNavigate();


    const toastError = {                                                        
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    };

    const toastSuccess = {                                                        
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: false,
             draggable: true,
             progress: undefined,
             theme: "light",
    };

    const toastInfo = {                                                        
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: false,
             draggable: true,
             progress: undefined,
             theme: "light",
    };



    useEffect(() => {

        //console.log("Login start");

        dispatch(clearMsg());


   }, []);




    //const handleSubmit = async (e) => {
    const handleSubmit = (e) => {


        e.preventDefault()


        let unmountedSubmit = false;
        let source = axios.CancelToken.source();
  
        //console.log("Koristim info: " + ime + " sifra " + sifra);
  

        dispatch(login(ime, sifra, attempt, source.token))
        .then(() => {
            if (!unmountedSubmit) {
                
                //console.log("Result from dispatch");

                props.history.push("/Pocetna");

            }


        })
        .catch(() => {
            setIsLoading(false);
        });


  
     }
  

 

     if (isLoggedIn) {

        //console.log("LoggedIn");
        return <Navigate to="/pocetna" />;
    }



    return (
    <div className="bodyLogIn">



                <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                />   


        {/*<div className="loginNatrag">
            <Link to="/pocetnaOut">Planirana isključenja</Link>
        
    </div>*/}

        <form id="loginForm" onSubmit={handleSubmit} className="containerLogIn"> 
          
            {/*<FiLogIn className="ikonaLogIn"/>*/}
            <div className="logoImg">
                    <img src={Logo2} alt="Logo" />
            </div>        

            <div className="logIng-form">

                <div className="inputContainer">
                            <FiUser className="ikona"/>
                            <input
                                    className="emailInputLogin"
                                    type='text'
                                    placeholder="Unesite e-mail"  
                                    onChange={e => {
                                        setIme(e.target.value) ;
                                    }}
                            />
                </div>

                <div className="inputContainer">
                            <FiLock className="ikona"/>
                            
                            <input
                                className="passwordInputLogin"
                                type='password'
                                placeholder="Unesite lozinku"  
                                onChange={e => {

                                    setSifra(e.target.value) ;
                                    }}
                            />
                </div>
                
                <div className="submitLogin">
                <button type="submit" className='buttonLogIn'>Prijava</button>
                            
                </div>
            </div>


            {message && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert"  >
                            {message}
                        </div>
                    </div>
            )}

        </form>
       {/*} <div className="footerlinks">
                <Link to="/register">Registriraj se</Link>
        </div>
        <div className="footerlinks2">
                <Link to="/resetPass">Zaboravili ste lozinku?</Link>
            </div>   */}     
    </div>
    );
}



export default Login;