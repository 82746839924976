import React from 'react';

import  { useState,useEffect, useRef } from 'react';

import  { Alert} from 'react';

import './css/FileUpload.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import { useDispatch, useSelector } from "react-redux";

import { logout } from "../actions/auth";




import axios from 'axios';

import APIservice from "./services/api.service";

import { IoMdCloseCircle } from "react-icons/io";

import {ReactComponent as DeleteIcon} from '../img/close-circle-outline.svg';

import JoyRide from "react-joyride";

import {ReactComponent as InfoIcon} from '../img/information-circle.svg';

import Swal from 'sweetalert2';



function FileUpload(props){

	const [selectedFile, setSelectedFile] = useState();

	const [documentName, setDocumentName] = useState("");

	const [typeDoc, setTypeDoc] = useState("");

	const [descDoc, setDescDoc] = useState("");

    const [isSelected, setIsSelected] = useState(false);

	const [docs, setDocs] = useState([]);
	const [selectedDocName, setSelectedDocName] = useState(""); 
	const [selectedDocCode, setSelectedDocCode] = useState(""); 
	const [selectedDocDesc, setSelectedDocDesc] = useState(""); 

	const [platform, setPlatform] = useState("3");
	const [isLoading, setIsLoading] = useState(true);
  
  
  
	const { user: currentUser } = useSelector((state) => state.auth);

	const [runTour, setRunTour] = useState(false);



	const toastError = {                                                        
		position: "top-center",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		theme: "light",
    };

    const toastSuccess = {                                                        
			 position: "top-center",
			 autoClose: 5000,
			 hideProgressBar: false,
			 closeOnClick: true,
			 pauseOnHover: false,
			 draggable: true,
			 progress: undefined,
			 theme: "light",
    };

    const toastInfo = {                                                        
			 position: "top-center",
			 autoClose: 5000,
			 hideProgressBar: false,
			 closeOnClick: true,
			 pauseOnHover: false,
			 draggable: true,
			 progress: undefined,
			 theme: "light",
    };



	const dispatch = useDispatch();
  



useEffect(() => {


   
   
        getData();

        const allMenuTabs =  Array.from(document.querySelectorAll(".menu-item"));
        allMenuTabs.map((tab, index)=> {
             tab.classList.remove("menuItemIsSelected")
        })
        document.querySelector(".menu-item-4").classList.add("menuItemIsSelected")


}, []);

	
 
const changeHandler = (event) => {

		setSelectedFile(event.target.files[0]);

		setDocumentName(event.target.files[0].name);

		setTypeDoc(event.target.files[0].type);

		setIsSelected(true);

};

	
const logOut = () => {
    
		dispatch(logout());
};






function getData() {


    let unmounted = false;
    let source = axios.CancelToken.source();

    setIsLoading(true);
        
    APIservice.GetDocuments(source.token).then(

            (response) => {

                    if (!unmounted) {
            
                                  //console.log(response);


                                  setIsLoading(false);

                                  //console.log(response.data.docList);

                                  var dataTmp = response.data;


                                  if(dataTmp.docList.length !=0){


											var arrDoc = [];
		
											var inty2 = 0;

											for(var propT in dataTmp.docList) {


													var docNameTmp = "";
													var docCodeTmp = "";
													var docDescTmp = "";


													for(var propT2 in dataTmp.docList[propT]){


															if(propT2 === 'fileName'){

																docNameTmp = dataTmp.docList[propT][propT2];


															

															}else if (propT2 === "fileCode"){

																docCodeTmp = dataTmp.docList[propT][propT2];

															

															}else if (propT2 === "docDesc"){

																docDescTmp = dataTmp.docList[propT][propT2];

															



															} else{

																//nema ostalih


															}


													}





													arrDoc.push({"name": docNameTmp, "code": docCodeTmp, "desc": docDescTmp, "id":inty2});

													inty2 = inty2+1;

											}

											//console.log("Ok response: " + arrDoc);
											//console.log("ArrDoc: " + arrDoc[0].code);

											setDocs(arrDoc);


         
                                            
                   
                                       
                                  }else{

     


                                            setDocs([]);
                                            

                                            //console.log("Ne postoji nijedan dostupan dokument");


                                  }
           
                    }
            
            },

            (error) => {

                    if (!unmounted) {
            
                      //console.log("Error rq");
            
                      console.error(error);

                      setIsLoading(false);

                      setDocs([]);
                      

            
					  	if(!error.response){

							toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


						} else {


								if(!error.response.status){

										toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

								}else{
				
										if (error.response.status == 401) {
			
											toast.error(error.response.data.message,
												toastError);
										
											setTimeout(() => {
															logOut();
											}, 5000); 
								
										} else {
								
												toast.error(error.response.data.message, toastError)
								
										
										}
								}
						}
                    }
            
            
            });
            
            
}            





function addNewDoc() {


	setIsLoading(true);
	
	const formData = new FormData();

	//console.log(selectedFile);
	

	formData.append('File', selectedFile);

	formData.append("FileName", documentName);

	formData.append("FileDesc", descDoc);
	formData.append("FileType", typeDoc);

	//console.log(formData);




    let unmounted = false;
    let source = axios.CancelToken.source();


        
    APIservice.DocumentUpload(formData, source.token).then(

            (response) => {

                    if (!unmounted) {
            
                                  //console.log(response);


                                  setIsLoading(false);

	
	
								  toast.success(response.data.message, toastSuccess);



								  setDocumentName("");
							  
								  setTypeDoc("");
							  
								  setIsSelected(false);

								  setDescDoc("");

								  setSelectedFile();

								  getData();
           
                    }
            
            },

            (error) => {

                    if (!unmounted) {
            
                      //console.log("Error rq");
            
                      console.error(error);

                      setIsLoading(false);

					  setDocumentName("");
							  
					  setTypeDoc("");
				  
					  setIsSelected(false);

					  setDescDoc("");

					  setSelectedFile();
                      

            
					  	if(!error.response){

							toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


						} else {


								if(!error.response.status){

										toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

								}else{
				
										if (error.response.status == 401) {
			
											toast.error(error.response.data.message,
												toastError);
										
											setTimeout(() => {
															logOut();
											}, 5000); 
									
								
										} else {
								
												toast.error(error.response.data.message, toastError)
								
										
										}
								}
						}
                    }
            
            
            });
            
            
}            


const DeleteDocument = (deleteID) => {



	Swal.fire({
		 title: `Jeste li sigurni da želite obrisati dokument?`,
		 text: "Ova radnja se ne može poništiti",
		 icon: "warning",
		 iconSize: '5',

		 showCancelButton: true,
		 confirmButtonColor: "#e31b2e",
		 cancelButtonColor: "#29648a",
		 showCancelButton: true,
		 confirmButtonText: "Da, obriši dokument!",
		 cancelButtonText: "Odustani",
		 reverseButtons: true
	   }).then((result) => {
		 if (result.isConfirmed) {

				DeleteDocumentConfirmed(deleteID)

		 }
	   });


};

function DeleteDocumentConfirmed(deleteID) {


	setIsLoading(true);
	



    let unmounted = false;
    let source = axios.CancelToken.source();


        
    APIservice.DeleteDocument(deleteID, source.token).then(

            (response) => {

                    if (!unmounted) {
            
                                  //console.log(response);


                                  setIsLoading(false);

	
	
								  toast.success(response.data.message, toastSuccess);


								  getData();
           
                    }
            
            },

            (error) => {

                    if (!unmounted) {
            
                      //console.log("Error rq");
            
                      console.error(error);

                      setIsLoading(false);


                      

            
					  	if(!error.response){

							toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


						} else {


								if(!error.response.status){

										toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

								}else{
				
										if (error.response.status == 401) {
			
											toast.error(error.response.data.message,
												toastError);
										
											setTimeout(() => {
															logOut();
											}, 5000); 
									
								
										} else {
								
												toast.error(error.response.data.message, toastError)
								
										
										}
								}
						}
                    }
            
            
            });
            
            
}         





  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 



  var steps = [
	{
	  target: '.upload-add',
	  content: `Administrator usluge može dodavati nove dokumente na sustav koji će korisnicima biti dostupni za pregledavanje, kao i preuzimanje. Novi dokument dodajete 
	  odabirom opcije "Odaberite dokument" nakon čega na vašem računalu odaberete željeni dokument.`,
	  disableBeacon: true,
	  placement: "end"
	},
	{
		 target: '.upload-inputArea',
		 content: `Dokument nakon odabira možete unutar korisničkog portala preimenovati, kao i unijeti opis koji će biti vidljiv korisnicima. Dokument se na sustav konačno sprema odabirom opcije "Spremi".`,
		 disableBeacon: true,
		 placement: "end"
	},     

	{
		 target: '.upload-list',
		 content: `Postojeće dokumente na sustavu možete brisati odabirom ikone "x" koja se nalazi desno od naziva dokumenta kojeg želite obrisati.`,
		 disableBeacon: true,
		 placement: "right"
	},                  
	 
]


	const handleJoyrideCallback = (data) => {

		 //console.log("JOyride callback");
		 //console.log(data);
		 const { status, type } = data;
		 //const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
		 if ((data.status == "finished" || data.status == "skipped")) {
		   setRunTour(false);
		 }

	}


	const handleJoyrideStart =()=>{

		 setRunTour(true);
	}



	return(

	<div className="upload">






			<div className="upload-second-part">
					<div className="upload-first-title">
							Dokumenti na sustavu
					</div>

		  
			  		<div className="upload-search">

							





							<div className="upload-list">
									{docs.map((element, index)=>{
										return(
											<div className="upload-list-item">
													<div className="list-itemName-upload">{element.name}</div>
													{/*<button className="list-item-btn-upload" onClick={()=>{
														docs.splice(index, 1);
														
														DeleteDocument(element.code);
																}}>Obriši</button>*/}

													{/*<div style={{marginRight: '2%'}}>
														<IoMdCloseCircle className="ikonaBrisi" onClick={(e)=>{
														
															//console.log("I'm gonna delete it!!!");

															docs.splice(index, 1);
															
															DeleteDocument(element.code);															
														}}/>

													</div>*/}
													<button 
													
													className='removeButton'
													onClick={() => {
														
														//console.log("I'm gonna delete it!!!");

														docs.splice(index, 1);
														
														DeleteDocument(element.code);	

													}}
													>
													<DeleteIcon style={{fill: '#29648a', color:  '#29648a'}} size="0.5x"/>
													</button>   											
											</div>
										)
										})}
							</div>
			  		</div>
		
			</div>


			<div className="upload-first-part">
					<div className="upload-first-title">
							Unesite ime novog dokumenta za arhivu
					</div>
						<div style={{margin: 10}}><button 
													
													className='infoButton'
													onClick={() => handleJoyrideStart()}
													>
													<InfoIcon style={{fill: '#29648a', }} size="0.7x"/>
							</button>   
						</div>        
						<div className="upload-enter-name">

							<div className="upload-add">

									{isSelected ? (<button onClick={()=>{



													if(documentName != ''){

														//addNewDocument();

														addNewDoc();


													}else{

														toast.error('Morate unijeti naziv dokumenta', toastError);
													}

								
								
													}}>
													Spremi
											</button>):(
											<div>
													<button onClick={handleClick}>
															Odaberite dokument
													</button>

													
													
													
													<input className="upload-inputFile" ref={hiddenFileInput} type="file" name="file" onChange={changeHandler} style={{display: 'none'}} />
											</div>)}
							</div>
						</div>
		  
			  		<div className="upload-search">
			
							<div className="upload-enter-name">
						  			<input className="upload-inputArea" type="text" placeholder="" value={documentName}
						  
											onChange={e => {
								  
											//console.log("Value to set: " + e.target.value);
											setDocumentName(e.target.value);
											}}>

						  			</input>
						  

					
							</div>
							
							<div className="upload-enter-name">

									<label className="upload-descLabel">
											
											<input className="upload-descriptionTA" name="description" placeholder="Opis dokumenta" maxLength="100" value={descDoc}
															onChange={e => {
														
															//console.log("Value to set: " + e.target.value);
															setDescDoc(e.target.value) ;
															}} />


										

									</label>                           

							</div>
							
						





			  		</div>
					  
				<ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                />      


				<div id="container1">

				<JoyRide 
				run={runTour}
				steps={steps} 
				continuous={true} 
				showSkipButton={true} 
				callback={handleJoyrideCallback}

				locale={{
					skip: "Preskoči",
					last:"Završi", 
					back:"Natrag",
					next: "Dalje"}}
					
				styles={{
					options: {
						height:"100px",
						arrowColor: "#f4f6f9",
						primaryColor: "#29648a",
						textColor: "#72a0c1",
						backgroundColor: "#f4f6f9",
						overlayHeight: '100px',
						borderTopRadius: 10,
						borderWidth:0,
						width: 500,
						zIndex: 10000,
						fontSize: 14,
						
					},
					buttonNext: {
						backgroundColor: "#29648a",
						title: "Dalje",
						text:"Dalje",
						border: "none",
						borderRadius: 10,
						color: "white",
						fontSize: 14,
						"&:focus": {
						outline: "none !important",
						},
					},
					buttonBack: {
						color: "#29648a",
						marginLeft: "auto",
						marginRight: 5,
						fontSize: 14,
					},
					buttonClose: {
						color: "#29648a",
						height: 14,
						padding: 15,
						position: "absolute",
						right: 0,
						top: 0,
						width: 14,
						
					},
					buttonSkip: {
						backgroundColor: "#e31b2e",
						title: "Preskoči",
						text:"Preskoči",
						border: "none",
						borderRadius: 10,
						color: "white",
						fontSize: 14,
						"&:focus": {
						outline: "none !important",
						},                                   
					}
					}}
				/>

				</div>

		
			</div>


	</div>

	);

    }



   export default FileUpload;