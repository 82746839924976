import{ BrowserRouter as Router, Routes, Route, Link, Redirect, useLocation, HashRouter, BrowserRouter} from 'react-router-dom';

import  { useState, useEffect,useMemo, useContext, createContext, } from 'react';



import { useDispatch, useSelector } from "react-redux";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";


import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import APIservice from "./components/services/api.service";


import './App.css';
import './components/css/modal.css';

import Pocetna from './components/Pocetna';
import PocetnaOut from './components/PocetnaOut';
import Postavke from './components/Postavke';

import Brojila from './components/Brojila';
import BrojilaAdmin from "./components/BrojilaAdmin";
import NavigationBarAdmin from'./components/NavigationBarAdmin';

import Register from './components/Register';

import NavigationBar from'./components/NavigationBar';
import Login from './components/Login'

import FileUpload from './components/FileUpload';

import FileDownload from './components/FileDownload';

import Kontakt from './components/Kontakt';

import ResetPass from './components/ResetPass';

import { changePass } from "./actions/auth";
import { clearMsg } from './actions/auth';


import userIcon from './img/person-circle.svg';

import {ReactComponent as UserIcon} from './img/person-circle.svg';
import { IoIosWarning } from "react-icons/io";




import  Logo from './components/icons/slika_logo.png';
import Logo2 from './components/icons/EP.jpg';
import { IoIosPerson } from "react-icons/io";

import Swal from 'sweetalert2';






function App(props){

          const [showMenu, setShowMenu] = useState(false);
          const [display, setDisplay] = useState("none");





          const [isLoading, setIsLoading] = useState(true);

          const [showOptions, setShowOptions] =useState({color: 'none',});
          const [showModal, setShowModal] =useState({color: 'none',});
          
          const [toggle, setToggle] = useState(true);
          const [toggle2, setToggle2] = useState(true);


          const [platform, setPlatform] = useState("3");


          const [list,setList]=useState([]);
          const [options, setOptions] = useState([])
          const [data, setData] = useState([])
          const [selectorOption, setSelectorOption] = useState(false);

          const [switchValue, setSwitchValue] = useState(false);
          const [switchValueOFF, setSwitchValueOFF] = useState(false);
          const [switchValueBILL, setSwitchValueBILL] = useState(false);
          const [switchValueNOT, setSwitchValueNOT] = useState(false);
          const [switchValueLIM, setSwitchValueLIM] = useState(false);
          const [switchValueMSG, setSwitchValueMSG] = useState(false);
          const [switchValueINFO, setSwitchValueINFO] = useState(false);

          const [valueAllBoxes, setValueAllBoxes] = useState([false, false, false, false, false, false]);
          const [disableBoxes, setDisableBoxes] = useState([false, false, false, false, false, false]);

          const [password, setPassword] = useState("");
          const [confirmPassword, setConfirmPassword] = useState("")


          const [username, setUsername] = useState('');
          const [name, setName] = useState('');
          const [address, setAddress] = useState('');
          
          const [phone, setPhone] = useState('');

          

          const [widthScreen, setWidthScreen] = useState(window.innerWidth);




          const { user: currentUser } = useSelector((state) => state.auth);

          const [isAdmin, setIsAdmin] = useState(false);

          const { message } = useSelector(state => state.message);

  
    

          
          const toastError = {                                                        
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
           };
      
           const toastSuccess = {                                                        
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: false,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
           };
      
           const toastInfo = {                                                        
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: false,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
           };

          const dispatch = useDispatch();
     


          

  useEffect(() => {
             
        if (!currentUser) {



        } else {        

                //console.log("currentUser ", currentUser)

                //console.log(currentUser.email);
        
                window.addEventListener('resize', ()=>{
                        setWidthScreen(window.innerWidth); 
                })
        
                history.listen((location) => {
                        dispatch(clearMessage());
                });

                dispatch(clearMessage());

           

        }
}, [dispatch]);
  



  useEffect(() => {


        if (currentUser) {
            setIsAdmin(currentUser.role == '1');

        }
    }, [currentUser]);

    const logOut = () => {
        dispatch(logout());
    };




    function getDataUser () {


        let unmounted = false;
        let source = axios.CancelToken.source();

        setIsLoading(true);
            
        APIservice.GetUserSettings(source.token).then(

                (response) => {
    
                        if (!unmounted) {
                
                                        //console.log(response);


                
                                        setIsLoading(false);

                                        //console.log(response.data.sett);

                                        //console.log(response.data.msg);



                                        setUsername(response.data.sett.username);
                                        setAddress(response.data.sett.address);
                                        setName(response.data.sett.name);
                                        setPhone(response.data.sett.phone);
                
                                        let notifTmp = response.data.sett.notif;



                                        let array = notifTmp.split(";");

                                        //console.log("Notif: " + array);

                                        if(array[0] == 1){

                                        setSwitchValue(true);

                                        }else{

                                        setSwitchValue(false);
                                        }

                                        if(array[1] == 1){

                                        setSwitchValueOFF(true);

                                        }else{

                                        setSwitchValueOFF(false);
                                        }

                                        if(array[2] == 1){

                                        setSwitchValueBILL(true);

                                        }else{

                                        setSwitchValueBILL(false);
                                        }

                                        if(array[3] == 1){

                                        setSwitchValueNOT(true);

                                        }else{

                                        setSwitchValueNOT(false);
                                        }

                                        if(array[4] == 1){


                                        setSwitchValueLIM(true);

                                        }else{

                                        setSwitchValueLIM(false);
                                        }



                                        if(array[5] == 1){

                                        setSwitchValueMSG(true);

                                        }else{

                                        setSwitchValueMSG(false);
                                        }


                                        if(array[6] == 1){

                                        setSwitchValueINFO(true);

                                        }else{

                                        setSwitchValueINFO(false);
                                        }

                                        setIsLoading(false);

               
                        }
                
                },
    
                (error) => {
    
                        if (!unmounted) {
                
                          //console.log("Error rq");
                
                          console.error(error);

                          setIsLoading(false);

                
                          if (error.response.status == 401) {
                
                                {/*toast.error(error.response.data.message,
                                        toastError);
                                
                                setTimeout(() => {
                                                logOut();
                                }, 5000); */}

                                alert("Istekla je korisnička sesija, molimo ponovite prijavu na uslugu");

                                logOut();


                
                          } else {
                
                            //toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);
                
                            
                          }
                        }
                
                
                });
                
                
}            




function setNewPass (newPass, newPass2) {



        let unmountedSubmit = false;
        let source = axios.CancelToken.source();
  
  

        dispatch(changePass(newPass, newPass2, source.token))
        .then(() => {

            if (!unmountedSubmit) {
                
                //console.log("Result from dispatch");

                //props.history.push("/Pocetna");

            }


        })
        .catch(() => {
            //setIsLoading(false);
        });


  
     }




  function changeNotifSettings () {


        var a="0";
        var b="0";
        var c="0";
        var d="0";
        var e="0";
        var f="0";
        var g="0";

        if(switchValue){

            a = 1;

        }
        if(switchValueOFF){

            b = 1;

        }          
        if(switchValueBILL){

            c = 1;

        }
        if(switchValueNOT){

            d = 1;

        }
        if(switchValueLIM){

            e = 1;

        }
        if(switchValueMSG){

            f = 1;

        }

        if(switchValueINFO){

            g = 1;

        }


        var notifSet = a + ";" + b + ";"  + c + ";"  + d + ";"  + e + ";"  + f + ";"  + g;


        let unmounted = false;
        let source = axios.CancelToken.source();

        setIsLoading(true);
            
        APIservice.ChangeNotifSettings(notifSet, currentUser.email, source.token).then(

                (response) => {
    
                        if (!unmounted) {
                
                          //console.log(response);


  
                          setIsLoading(false);

                          //toast.success(response.data.message, toastSuccess);

                
                
                
                        }
                
                
                },
    
                (error) => {
    
                        if (!unmounted) {
                
                          //console.log("Error rq");
                
                          console.error(error);

                          setIsLoading(false);

                
                          if(!error.response.status){

                                //toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);
            
                             }else{
                
                                if (error.response.status == 401) {
                        
                                        /*toast.error(error.response.data.message,
                                                toastError);
                                        
                                        setTimeout(() => {
                                                        logOut();
                                        }, 5000); */

                                        alert("Istekla je korisnička sesija, molimo ponovite prijavu na uslugu");

                                        logOut();
                            
                        
                                } else {
                        
                                        //toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);
                        
                                    
                                }
                             }
                   


                        
                        }
                
                
                });
                
                
}            





function changeAddress(event){


        setAddress(event);

}


function changePhone(event){

        setPhone(event);


}


function changeName(event){

        setName(event);

}


function handleSwitch(e) {
                  
                  //console.log(e)
                  
                  var x = Array.from(document.querySelectorAll(".switch-action")).map(item=>{                 
                          item.classList.remove("isSelected")
                  });
                  
                  var y = e.classList.add("isSelected")

}

const [showMyOptions, setShowMyOptions] = useState(false);

const handleIconClick = () => {
        setShowMyOptions(!showMyOptions);
      };



      const deleteMyAccount = () => {
        Swal.fire({
             title: `Jeste li sigurni da želite obrisati ovaj korisnički račun?`,
             text: "Svi vaši podaci biti će izbrisani. Ova radnja se ne može poništiti",
             icon: "warning",
             iconSize: '5',
   
             showCancelButton: true,
             confirmButtonColor: "#e31b2e",
             cancelButtonColor: "#29648a",
             showCancelButton: true,
             confirmButtonText: "Da, obriši!",
             cancelButtonText: "Odustani",
             reverseButtons: true
           }).then((result) => {
             if (result.isConfirmed) {
   
                   deleteAccount()
   
             }
           });
     
   }
   
   const deleteAccount = () => {
        let unmounted = false;
        let source = axios.CancelToken.source();
        setIsLoading(true);
   
        
        APIservice.DeleteUserAccountUsr(source.token).then(
   
             (response) => {
   
                     if (!unmounted) {
             
                                   //console.log(response);
   
   
                                   setIsLoading(false);
   
                                   //console.log(response.data.message);
   
   
   
                                   toast.success(response.data.message, toastSuccess);
   
   
   
                                  
                                   setTimeout(() => {
                                      logOut();
                                   }, 5000); 
   
            
                     }
             
             },
   
             (error) => {
   
                     if (!unmounted) {
             
                       //console.log("Error rq");
             
                       console.error(error);
   
                       setIsLoading(false);
   
   
             
                         if(!error.response){
   
                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);
   
   
                         } else {
   
   
                                   if(!error.response.status){
   
                                        toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);
   
                                   }else{
   
                                        if (error.response.status == 401) {
                    
                                             toast.error(error.response.data.message,
                                                  toastError);
                                          
                                             setTimeout(() => {
                                                            logOut();
                                             }, 5000); 
                                   
                                        } else {
                                   
                                                  toast.error(error.response.data.message, toastError)
                                   
                                             
                                        }
                                   }
                         }
                     }
             
             
             });
             
   }

return <>
{!currentUser ?
    (

      <BrowserRouter>
            <Routes>       
                  
 
                         
                              <Route path="/*" element={<PocetnaOut />} />

                              <Route exact path="/pocetnaOut" element={<PocetnaOut />} />

                             

                              <Route exact path="/login" element={<Login />} />

                              <Route exact path="/register" element={<Register />} />

                              <Route exact path="/resetPass" element={<ResetPass />} />



              </Routes>
      </BrowserRouter>

    ) :
    (
       isAdmin ? 
              (
              
                      <HashRouter>

                      <div onClick={(e)=>{
                              //console.log(showOptions)
                              //console.log(e.target)
                              var noDisplay ={display: 'none',}

                              //console.log(document.querySelector('.menu-selector'))
                              if(e.target!=document.querySelector('.menu-selector')) {
                              setShowOptions(noDisplay);
                              setToggle(true)
                              }
                      
                              
                      }}>

                      
                              <div className="menu">
                                      
                                      <div className="button-menu">
                                              <div className="logoMenu">

                                                      {/*<picture>

                                                              <source media="(min-width: 900px)" srcset={Logo}>
                                                              <source media="(max-width: 900px)" srcset={Logo2}>
                                                              <img src={Logo2}>        


                                                      </picture>*/}
                                                      {/*<img src={Logo2} srcSet={'${Logo2} 300w, ${Logo2} 768w, ${Logo} 1280w'} />*/}
                                              
                                                      {/*<img src={Logo2} alt="Logo" />*/}
                                                      {/*<img src={Logo} alt="Logo" />*/}

                                                      <img src={widthScreen>750 ? Logo : Logo2} alt="Logo"
                                                              width={widthScreen<751 ? "100px" : ""}/>
                                                      
                                              </div>
                                              
                                              <div className="selector-container">


                                      
                              
                                                      {/*<div className="menu-selector" onClick={()=>{
                                                              var display ={display: 'block',};
                                                              var noDisplay ={display: 'none',}

                                                              //console.log("Toggle is");
                                                              //console.log(toggle);
                                                              toggle ? setShowOptions(display) : setShowOptions(noDisplay); 
                                                              var toggling = toggle
                                                              setToggle(!toggling);
                                                              }}>
                                                                <IoIosPerson />


                                                          
                                                              
                                                        </div>*/}
                                        

                                                        
                                                        <button className='menu-selector' 
                                                                onClick={() => handleIconClick()}
                                                        >
                                                                <UserIcon style={{fill: '#29648a', }} size="1x"/>
                                                        </button>    
                                   
                                                      
                                                      {showMyOptions && <div id="" class="menu-sector-options" >
                                                              <div onClick={()=> {
                                                                      var display ={display: 'flex',};
                                                                      var noDisplay ={display: 'none',}
                                                                      toggle2 ? setShowModal(display) : setShowModal(noDisplay); 
                                                                      var toggling2 = toggle2
                                                                      setToggle2(!toggling2);
                                                                      if(showMyOptions){
                                                                                //console.log("My options are being shown!!!")
                                                                                dispatch(clearMessage());
                                                                                setPassword('');
                                                                                setConfirmPassword('');

                                                                      }
                                                                      setShowMyOptions(!showMyOptions);

                                                                      }}>Moje postavke
                                                              </div>
                                                              <div onClick={()=>{
                                                                      setShowMyOptions(!showMyOptions);

                                                                      logOut();


                                                                      


                                                                      }}>Odjava
                                                              </div>
                                                        </div>  }        
                              
                                                        
                              


                                              </div>  

                                      </div>

                              </div>
                              
                              <div style={{display: "block"}} className="list-menu">
                                      <NavigationBarAdmin />
                              </div>


                              <div id="myModal" class="modal" style={showModal}>


                                {/*<ToastContainer
                                        position="top-center"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme="colored"
                                                                />   */}

                                      <div class="modal-content">
                                              <span class="close" onClick={()=>{
                                                      var noDisplay ={display: 'none',}
                                                      setShowModal(noDisplay);
                                                      setToggle2(true)
                                                      }}>&times;
                                              </span>
                              
                                              <div className="modal-postavke">
                                      
                                                      <div className="modal-postavke-container">
                                                              
                                                              <div className="modal-postavke-subcontainer">

                                                                      <div className="modal-postavke-notifications">
                                                                              <div className="modal-postavke-title">
                                                                                              Notifikacije
                                                                              </div>
                                                                              <div className="modal-postavke-notification-options">
                                                                                      <div className="modal-postavke-switch">
                                                                                              <div className="switch-action on isSelected" onClick={(e)=>{
                                                                                                              setDisableBoxes([false, false, false, false, false, false]);
                                                                                                              handleSwitch(e.target);
                                                                                                              setSwitchValue(true);
                                                                                                              }}>ON
                                                                                              </div>
                                                                                              <div className="switch-action off" onClick={(e)=>{
                                                                                                              setValueAllBoxes([false, false, false, false, false, false]);
                                                                                                              setDisableBoxes([true,true, true, true, true, true]); 

                                                                                                              setSwitchValueOFF(false);
                                                                                                              setSwitchValueBILL(false);
                                                                                                              setSwitchValueNOT(false);
                                                                                                              setSwitchValueLIM(false);
                                                                                                              setSwitchValueMSG(false);
                                                                                                              setSwitchValueINFO(false);

                                                                                                              handleSwitch(e.target);
                                                                                                              setSwitchValue(false);
                                                                                                              }}>OFF
                                                                                              </div>
                                                                                      </div>
                                                                                      
                                                                                      <div className="modal-postavke-options">

                                                                                              <form onChange={()=>{ 
                                                                                                              setValueAllBoxes([switchValueOFF, switchValueBILL, switchValueNOT, switchValueLIM, switchValueMSG, switchValueINFO]);
                                                                                                              //console.log(valueAllBoxes)
                                                                                                      }}>
                                                                                                      <label>
                                                                                                              Isključenja u mreži :
                                                                                                              <input
                                                                                                                      name="Isključenja u mreži"
                                                                                                                      type="checkbox"
                                                                                                                      checked={switchValueOFF}
                                                                                                                      onChange={(e)=>{setSwitchValueOFF(e.target.checked)}} 
                                                                                                                      disabled={disableBoxes[0]}/>
                                                                                                      </label>
                                                                                                      <label>
                                                                                                              Pristigli računi :
                                                                                                              <input
                                                                                                                      name="Pristigli računi"
                                                                                                                      type="checkbox"
                                                                                                                      checked={switchValueBILL}
                                                                                                                      onChange={(e)=>{setSwitchValueBILL(e.target.checked)}} 
                                                                                                                      disabled={disableBoxes[1]}/>
                                                      
                                                                                                      </label>
                                                                                                      {/*<label>
                                                                                                              Pristigle opomene :
                                                                                                              <input
                                                                                                                      name="Pristigle opomene"
                                                                                                                      type="checkbox"
                                                                                                                      checked={switchValueNOT}
                                                                                                                      onChange={(e)=>{setSwitchValueNOT(e.target.checked)}} 
                                                                                                                      disabled={disableBoxes[2]}/>
                                                                                                      </label>
                                                                                                      <label>
                                                                                                              Prekoračenje zadanog limita :
                                                                                                              <input
                                                                                                                      name="Prekoračenje zadanog limita"
                                                                                                                      type="checkbox"
                                                                                                                      checked={switchValueLIM}
                                                                                                                      onChange={(e)=>{setSwitchValueLIM(e.target.checked)}} 
                                                                                                                      disabled={disableBoxes[3]}/>
                                                                                                      </label>
                                                                                                      <label>
                                                                                                              Odgovor na zahtjev/poruku :
                                                                                                              <input
                                                                                                                      name="Odgovor na zahtjev/poruku"
                                                                                                                      type="checkbox"
                                                                                                                      checked={switchValueMSG}
                                                                                                                      onChange={(e)=>{setSwitchValueMSG(e.target.checked)}} 
                                                                                                                      disabled={disableBoxes[4]}/>
                                                                                                      </label>*/}
                                                                                                      <label>
                                                                                                              Promotivne obavijesti :
                                                                                                              <input
                                                                                                                      name="Promotivne obavijesti"
                                                                                                                      type="checkbox"
                                                                                                                      checked={switchValueINFO}
                                                                                                                      onChange={(e)=>{setSwitchValueINFO(e.target.checked)}} 
                                                                                                                      disabled={disableBoxes[5]}/>
                                                                                                      </label>

                                                                                              </form>

                                                                                      </div>
                                                                              </div>
                                                                      </div>
                                                                      <div className="modal-postavke-save-notifications">
                                                                              <button onClick={()=>{

                                                                                   
                                                                                              
                                                                                      changeNotifSettings();
                                                                                              
                                                                                      }}>
                                                                                              Spremi
                                                                              </button>
                                                                      </div>
                                                              </div>



                                                              <div className="modal-postavke-password-part">
                                                                      <div className="modal-postavke-password">
                                                                                     
                                                                                      
                                                                                      <input className="passwordInput" placeholder="Lozinka" type='password' 
                                                                                              name="password" value={password} 
                                                                                              onChange={(e)=>{setPassword(e.target.value)}}/> 
                                                                                      
                                                                                      <input className="passwordInput" placeholder="Ponovite lozinku" 
                                                                                              type='password' name="password" value={confirmPassword} 
                                                                                              onChange={(e)=>{setConfirmPassword(e.target.value)}}/>
                                                                      </div>
                                                                      <div className="modal-postavke-save-password">
                                                                              <button onClick={()=>{


                                                                                      //console.log("Platforma: " + platform);

                                                                                      if(platform == '3'){

                                                            
                                                                                                              setNewPass (password, confirmPassword);

                                                                                

                                                                                      }else{

                                                                                              //console.log("Logiran preko soc.mreza");

                                                                                              //toast.error("Prijavljeni ste preko socijalne mreže, ne možete mijenjati lozinku unutar aplikacije", toastError);

                                                                                      }

                                                                                      }}>
                                                                                      Spremi
                                                                              </button>
                                                                      </div>

                                                                      {message && (
                                                                                                <div className="form-group">
                                                                                                        <div className="alert alert-danger" role="alert"  >
                                                                                                        {message}
                                                                                                        </div>
                                                                                                </div>
                                                                       )}                                                                      
                                                              </div>
                                                      </div>

                                      
                                              </div>
                              
                                      </div>

                              </div>

                            
                              
                              <Routes>
   

                                      <Route exact path="/pocetna" element={<Pocetna />} />

                                      <Route exact path="/" element={<Pocetna />} />

                                      <Route exact path="/brojilaAdmin" element={<BrojilaAdmin />} />

                                      <Route exact path="/postavke" element={<Postavke />} />

                                      <Route exact path="/upload" element={<FileUpload />} />

                                    
                              </Routes>

                             
                      
                      </div>

                      </HashRouter>


              )
              :
              (

                      <div onClick={(e)=>{
                              //console.log(showOptions)
                              //console.log(e.target)
                              var noDisplay ={display: 'none',}
          
                              //console.log(document.querySelector('.menu-selector'))
                              if(e.target!=document.querySelector('.menu-selector')) {
                                    setShowOptions(noDisplay);
                                    setToggle(true)
                              }
                              
                            }}>
          
                            <HashRouter>
                                  <div className="menu">
                                        
                                        <div className="button-menu">
                                                  <div className="logoMenu">
          
          
                                                                                  {/*<picture>
          
                                                                                          <source media="(min-width: 900px)" srcset={Logo}>
                                                                                          <source media="(max-width: 900px)" srcset={Logo2}>
                                                                                          <img src={Logo2}>        
          
          
                                                                                  </picture>*/}
                                                                                  {/*<img src={Logo2} srcSet={'${Logo2} 300w, ${Logo2} 768w, ${Logo} 1280w'} />*/}
                                                                          
                                                                                  {/*<img src={Logo2} alt="Logo" />*/}
                                                                                  {/*<img src={Logo} alt="Logo" />*/}
          
                                                                                  <img src={widthScreen>750 ? Logo : Logo2} alt="Logo"
                                                                                          width={widthScreen<750 ? "150px" : ""}/>
          
                                                          
                                                                  {/*<img src={Logo} alt="Logo" />*/}
                                                  </div>
                                                
                                                 <div className="selector-container">
                                
                                                        {/*<div className="menu-selector" onClick={()=>{
                                                                  var display ={display: 'block',};
                                                                  var noDisplay ={display: 'none',}
                                                                  toggle ? setShowOptions(display) : setShowOptions(noDisplay); 
                                                                  var toggling = toggle
                                                                  setToggle(!toggling);
                                                                }}><IoIosPerson />
                                                        </div>*/}

                                                        <button className='menu-selector' 
                                                                onClick={() => handleIconClick()}
                                                        
                                                        >
                                                                <UserIcon style={{fill: '#29648a', }} size="1x"/>
                                                        </button>    
                                                        
                                                        {showMyOptions && <div id="" class="menu-sector-options">
                                                                  <div onClick={()=> {
                                                                            var display ={display: 'flex',};
                                                                            var noDisplay ={display: 'none',}
                                                                            toggle2 ? setShowModal(display) : setShowModal(noDisplay); 
                                                                            var toggling2 = toggle2
                                                                            setToggle2(!toggling2);

                                                                            if(showMyOptions){
                                                                                //console.log("My options are being shown!!!")
                                                                                dispatch(clearMessage());
                                                                                setPassword('');
                                                                                setConfirmPassword('');
                                                                                getDataUser();

                                                                            }                                                                            
                                                                            setShowMyOptions(!showMyOptions);
                                                                          }}>Moje postavke
                                                                  </div>
                                                                  <div onClick={()=>{
                                                                            setShowMyOptions(!showMyOptions);
                                                                            logOut();
                                                                            }}>Odjava
                                                                  </div>
                                                          </div> }         
                                
                                                </div>  
          
                                        </div>
          
                                  </div>
                                
                                  <div style={{display: "block"}} className="list-menu">
                                          <NavigationBar />
                                  </div>
          
          
                                  <div id="myModal" class="modal" style={showModal}>
          



                                  {/*<ToastContainer
                                        position="top-center"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme="colored"
                                                                        />        */}     
                                                          <div class="modal-content">
                                                                  <span class="close" onClick={()=>{
                                                                          var noDisplay ={display: 'none',}
                                                                          setShowModal(noDisplay);
                                                                          setToggle2(true)
                                                                          }}>&times;
                                                                  </span>
          
                                                                  <div className="modal-postavke">
          
                                                                          <div className="modal-postavke-container">
                                                                                  
                                                                                  <div className="modal-postavke-subcontainer">
          
                                                                                          <div className="modal-postavke-notifications">
                                                                                                  <div className="modal-postavke-title">
                                                                                                          Notifikacije
                                                                                                  </div>
                                                                                                  <div className="modal-postavke-notification-options">
                                                                                                          <div className="modal-postavke-switch">
                                                                                                                  <div className="switch-action on isSelected" onClick={(e)=>{
                                                                                                                          setDisableBoxes([false, false, false, false, false, false]);
                                                                                                                          handleSwitch(e.target);
                                                                                                                          setSwitchValue(true);
                                                                                                                          }}>ON
                                                                                                                  </div>
                                                                                                                  <div className="switch-action off" onClick={(e)=>{
                                                                                                                          setValueAllBoxes([false, false, false, false, false, false]);
                                                                                                                          setDisableBoxes([true,true, true, true, true, true]); 
                                                                                                                          setSwitchValueOFF(false);
                                                                                                                          setSwitchValueBILL(false);
                                                                                                                          setSwitchValueNOT(false);
                                                                                                                          setSwitchValueLIM(false);
                                                                                                                          setSwitchValueMSG(false);
                                                                                                                          setSwitchValueINFO(false);
          
                                                                                                                          handleSwitch(e.target);
                                                                                                                          setSwitchValue(false);
                                                                                                                          }}>OFF
                                                                                                                  </div>
                                                                                                          </div>
                                                                                                          
                                                                                                          <div className="modal-postavke-options">
          
                                                                                                                  <form onChange={()=>{ 
                                                                                                                          setValueAllBoxes([switchValueOFF, switchValueBILL, switchValueNOT, switchValueLIM, switchValueMSG, switchValueINFO]);
                                                                                                                          //console.log(valueAllBoxes)
                                                                                                                          }}>
                                                                                                                          <label>
                                                                                                                                  Isključenja u mreži :
                                                                                                                                  <input
                                                                                                                                          name="Isključenja u mreži"
                                                                                                                                          type="checkbox"
                                                                                                                                          checked={switchValueOFF}
                                                                                                                                          onChange={(e)=>{setSwitchValueOFF(e.target.checked)}} 
                                                                                                                                          disabled={disableBoxes[0]}/>
                                                                                                                          </label>
                                                                                                                          <label>
                                                                                                                                  Pristigli računi :
                                                                                                                                  <input
                                                                                                                                          name="Pristigli računi"
                                                                                                                                          type="checkbox"
                                                                                                                                          checked={switchValueBILL}
                                                                                                                                          onChange={(e)=>{setSwitchValueBILL(e.target.checked)}} 
                                                                                                                                          disabled={disableBoxes[1]}/>
                                                                          
                                                                                                                          </label>
                                                                                                                          {/*<label>
                                                                                                                                  Pristigle opomene :
                                                                                                                                  <input
                                                                                                                                          name="Pristigle opomene"
                                                                                                                                          type="checkbox"
                                                                                                                                          checked={switchValueNOT}
                                                                                                                                          onChange={(e)=>{setSwitchValueNOT(e.target.checked)}} 
                                                                                                                                          disabled={disableBoxes[2]}/>
                                                                                                                          </label>
                                                                                                                          <label>
                                                                                                                                  Prekoračenje zadanog limita :
                                                                                                                                  <input
                                                                                                                                          name="Prekoračenje zadanog limita"
                                                                                                                                          type="checkbox"
                                                                                                                                          checked={switchValueLIM}
                                                                                                                                          onChange={(e)=>{setSwitchValueLIM(e.target.checked)}} 
                                                                                                                                          disabled={disableBoxes[3]}/>
                                                                                                                          </label>
                                                                                                                          <label>
                                                                                                                                  Odgovor na zahtjev/poruku :
                                                                                                                                  <input
                                                                                                                                          name="Odgovor na zahtjev/poruku"
                                                                                                                                          type="checkbox"
                                                                                                                                          checked={switchValueMSG}
                                                                                                                                          onChange={(e)=>{setSwitchValueMSG(e.target.checked)}} 
                                                                                                                                          disabled={disableBoxes[4]}/>
                                                                                                                          </label>*/}
                                                                                                                          <label>
                                                                                                                                  Promotivne obavijesti :
                                                                                                                                  <input
                                                                                                                                          name="Promotivne obavijesti"
                                                                                                                                          type="checkbox"
                                                                                                                                          checked={switchValueINFO}
                                                                                                                                          onChange={(e)=>{setSwitchValueINFO(e.target.checked)}} 
                                                                                                                                          disabled={disableBoxes[5]}/>
                                                                                                                          </label>
          
                                                                                                                  </form>
          
                                                                                                          </div>
                                                                                                  </div>
                                                                                          </div>
                                                                                          <div className="modal-postavke-save-notifications">
                                                                                                  <button onClick={()=>{
          
          
          
                                                                                                      changeNotifSettings();
                                                                                                          
                                                                                                          }}>
                                                                                                          Spremi
                                                                                                  </button>
                                                                                          </div>
                                                                                  </div>
          
                                                                                  <div className="modal-postavke-password-part">
                                                                                          <div className="modal-postavke-password">
                                                                                                  <div className="passwordLabel">Lozinka: </div>
                                                                                                          
                                                                                                          <input className="passwordInput" placeholder="Lozinka" type='password' 
                                                                                                                  name="password" value={password} 
                                                                                                                  onChange={(e)=>{setPassword(e.target.value)}}/> 
                                                                                                          <div className="passwordLabel">Ponovite lozinku: </div> 
                                                                                                          
                                                                                                          <input className="passwordInput" placeholder="Ponovite lozinku" 
                                                                                                                  type='password' name="password" value={confirmPassword} 
                                                                                                                  onChange={(e)=>{setConfirmPassword(e.target.value)}}/>
                                                                                          </div>
                                                                                          <div className="modal-postavke-save-password">
                                                                                          <button onClick={()=>{
          
          
          
                                                                                                          if(platform == '3'){
          
                                                                                        
          
                                                                                                                          setNewPass (password, confirmPassword);
          

          
                                                                                                          }else{
          
                                                                                                                  //console.log("Logiran preko soc.mreza");
          
                                                                                                                  //toast.error("Prijavljeni ste preko socijalne mreže, ne možete mijenjati lozinku unutar aplikacije", toastError);
          
                                                                                                          }
          
                                                                                                  }}>
                                                                                                  Spremi
                                                                                          </button>
                                                                                          <div className='modal-postavke-delete-user' onClick={()=> {
                                                                                                deleteMyAccount()
                                                                                          }}>
                                                                                                <IoIosWarning  style={{}} size="15px"/>
                                                                                                <div style={{marginLeft: '15px'}}>Obriši korisnički račun</div>
                                                                                                        
                                                                                          </div>

                                                                                          </div>

                                                                                        {message && (
                                                                                                <div className="form-group">
                                                                                                        <div className="alert alert-danger" role="alert"  >
                                                                                                        {message}
                                                                                                        </div>
                                                                                                </div>
                                                                                        )}
                                                                                  </div>
                                                                          </div>
          
          
                                                                  </div>
          
                                                          </div>
          
                                                          </div>
          
                                  <Routes>
          

                                              <Route exact path="/pocetna" element={<Pocetna />} />

                                              <Route exact path="/" element={<Pocetna />} />

                                              <Route exact path="/brojila" element={<Brojila />} />

                                              <Route exact path="/download" element={<FileDownload />} />

                                              <Route exact path="/contact" element={<Kontakt />} />

                                         
          
                                  </Routes>
                            </HashRouter>
          
          
                            
                    </div>
          

              )
  )}
  </>
}
export default App;

