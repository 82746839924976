import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    CLEAR_MESSAGE,
  } from "./types";
  
  import AuthService from "../components/services/auth.service";
  
  
  import APIservice from "../components/services/api.service";
  
  
  
  
  export const login = (username, password, attempt, platform, cancelTkn) => (dispatch) => {
    
    return AuthService.login(username, password, attempt, platform, cancelTkn).then(
      (data) => {
        //console.log('dispatching');
        //console.log(data);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        
        dispatch({type: CLEAR_MESSAGE});
        
        return Promise.resolve();
        
      },
      (error) => {


            let message = "Problem u povezivanju sa serverom, molimo pokušajte kasnije"

            if(error.response){

                  if(error.response.data){

                        if(error.response.data.message){

                                message = error.response.data.message;

                        }

                  }

            }

          
            dispatch({
              type: LOGIN_FAIL,
            });
            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });
            return Promise.reject();


      }
    );
  };

  export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
      type: LOGOUT,
    });
  };
  


  function validateEmail (email){

    var re = /\S+@\S+\.\S+/;

    return re.test(email);

  }


  export const register = (username, password, password2, cancelTkn) => (dispatch) => {
    
    
    if(password != ''){

      if(password === password2){

          //console.log("Lozinke ok");

          if(username != ''){

                  if(validateEmail(username)){
    
    
                        return APIservice.Register(username, password, cancelTkn).then(
                            (data) => {
                              //console.log('dispatching register');

                              //dispatch({type: CLEAR_MESSAGE});

                              let message = "Uspješna registracija korisnika, molimo prijavite se na uslugu";

                              dispatch({
                                type: SET_MESSAGE,
                                payload: message,
                              });
                              
                              return Promise.resolve();
                              
                            },
                            (error) => {
                                    //console.log("Error in auth jsssssss");
                                    ////console.log(error.response.data.message);


                                    let message = "Problem u povezivanju sa serverom, molimo pokušajte kasnije"

                                    if(error.response){

                                          if(error.response.data){

                                                if(error.response.data.message){

                                                        message = error.response.data.message;

                                                }

                                          }

                                    }
                            
                                  
                                    dispatch({
                                        type: SET_MESSAGE,
                                        payload: message,
                                    });
                                    return Promise.reject();
                            }
                        );





                  } else {

                    //setIsLoadingReg(false);

                    //console.log("Email invalid");

                    let msg = "E-mail adresa koju ste unijeli je neispravna, molimo unesite ispravnu e-mail adresu";

                    dispatch({
                      type: SET_MESSAGE,
                      payload: msg,
                    });
                    return Promise.reject();

                }



          }else{


              let msg = "Molimo unesite ispravnu e-mail adresu";

              dispatch({
                type: SET_MESSAGE,
                payload: msg,
              });
              return Promise.reject();

          }

      }else{


          //console.log("Lozinke ne pasu");


          let msg = "Unešene lozinke se ne podudaraju, molimo ponovite unos";

          dispatch({
            type: SET_MESSAGE,
            payload: msg,
          });
          return Promise.reject();


      }

    }else{


      //console.log("Lozinka prazna");


      let msg = "Molimo unesite lozinku";

      dispatch({
        type: SET_MESSAGE,
        payload: msg,
      });

      return Promise.reject();


}

};



export const changePass = (password, password2, cancelTkn) => (dispatch) => {
    
    
  if(password != ''){

    if(password === password2){

        //console.log("Lozinke ok");

  
                      return APIservice.ChangePass(password, cancelTkn).then(
                          (data) => {
                            //console.log('dispatching changePass');

                            //dispatch({type: CLEAR_MESSAGE});

                            //console.log(data);

                            let message = "Uspješno ste promijenili lozinku";

                            if(data.data.message){

                                message = data.data.message;

                            }

                            dispatch({
                              type: SET_MESSAGE,
                              payload: message,
                            });
                            
                            return Promise.resolve();
                            
                          },
                          (error) => {
                                  //console.log("Error in changePass");
                                  ////console.log(error.response.data.message);


                                  let message = "Problem u povezivanju sa serverom, molimo pokušajte kasnije"

                                  if(error.response){

                                        if(error.response.data){

                                              if(error.response.data.message){

                                                      message = error.response.data.message;

                                              }

                                        }

                                  }
                          
                                
                                  dispatch({
                                      type: SET_MESSAGE,
                                      payload: message,
                                  });
                                  return Promise.reject();
                          }
                      );



    }else{


        //console.log("Lozinke ne pasu");


        let msg = "Unešene lozinke se ne podudaraju, molimo ponovite unos";

        dispatch({
          type: SET_MESSAGE,
          payload: msg,
        });
        return Promise.reject();


    }

  }else{


    //console.log("Lozinka prazna");


    let msg = "Molimo unesite lozinku";

    dispatch({
      type: SET_MESSAGE,
      payload: msg,
    });

    return Promise.reject();


}

};



export const resetPassword = (username, password, password2, cancelTkn) => (dispatch) => {
    
    
    if(password != ''){

            if(password === password2){

                  //console.log("Lozinke ok");

                  if(username != ''){

                          if(validateEmail(username)){
  
  
                                return APIservice.RenewPass(username, password, cancelTkn).then(
                                    (data) => {
                                      //console.log('dispatching renewPass');

                                      //dispatch({type: CLEAR_MESSAGE});

                                      let message = "Poslan vam je e-mail za autorizaciju izmjene lozinke na vašu e-mail adresu";

                                      dispatch({
                                          type: SET_MESSAGE,
                                          payload: message,
                                      });
                                      
                                      return Promise.resolve();
                                      
                                    },
                                    (error) => {
                                            //console.log("Error in auth jsssssss");
                                            ////console.log(error.response.data.message);


                                            let message = "Problem u povezivanju sa serverom, molimo pokušajte kasnije"

                                            if(error.response){

                                                  if(error.response.data){

                                                        if(error.response.data.message){

                                                                message = error.response.data.message;

                                                        }

                                                  }

                                            }
                                    
                                          
                                            dispatch({
                                                type: SET_MESSAGE,
                                                payload: message,
                                            });
                                            return Promise.reject();
                                    }
                                );





                          } else {

                            //setIsLoadingReg(false);

                            //console.log("Email invalid");

                            let msg = "E-mail adresa koju ste unijeli je neispravna, molimo unesite ispravnu e-mail adresu";

                            dispatch({
                              type: SET_MESSAGE,
                              payload: msg,
                            });
                            return Promise.reject();

                          }



                  }else{


                      let msg = "Molimo unesite ispravnu e-mail adresu";

                      dispatch({
                        type: SET_MESSAGE,
                        payload: msg,
                      });
                      return Promise.reject();

                  }

            }else{


                //console.log("Lozinke ne pasu");


                let msg = "Unešene lozinke se ne podudaraju, molimo ponovite unos";

                dispatch({
                  type: SET_MESSAGE,
                  payload: msg,
                });
                return Promise.reject();


            }

    }else{


          //console.log("Lozinka prazna");


          let msg = "Molimo unesite lozinku";

          dispatch({
            type: SET_MESSAGE,
            payload: msg,
          });

          return Promise.reject();


    }

};





  export const clearMsg = () => (dispatch) => {
    
    dispatch({type: CLEAR_MESSAGE});
  };