import axios from "axios";
import authHeader from "./auth-header";

import { APIbase, APPversion, APPid, APPplatform } from '../variables.js';




//**************************************************************************** */

const GetDistListOut = (canToken) => {

    const data = {
        platform: APPplatform,
        version: APPversion,
        appId:APPid,
    }

    ////console.log("data", data);


    return axios.post(APIbase + "GetDistListUnsigned",
        {data: data},
        { cancelToken: canToken }
        )


    
}

const GetDistOneOut = (areaCode, canToken) => {
    
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        areaCode:areaCode
    }

    //console.log("data", data);

    return axios.post(APIbase + "GetDistOneUnsigned",
            {data: data},
            { cancelToken: canToken }
        )


}




const GetDistList = (canToken) => {
    
    
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
    }
    //console.log("data" , data)
    
    return axios.post(APIbase + "GetDistList", 
        {data: data},
        {headers: authHeader()},
        {cancelToken: canToken}
    );
}


const GetDistOne = (areaCode, canToken) => {
    
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        areaCode: areaCode,
    }

    //console.log("data", data)

    return axios.post(APIbase + "GetDistOne",
            {data: data},
            { headers: authHeader() },
            { cancelToken: canToken }
        )
}





const Register = (email, pass, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        email: email,
        pass: pass,
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "Register",
            {data: data},
          { cancelToken: canToken }
      
    );
  

}





const ChangePass = (newPass, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        passwd: newPass,

    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "ChangePass",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}


const ChangeNotifSettings = (notif, email, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        notif: notif,
        email: email
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "ChangeNotifSettings",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}



const GetUserSettings = (canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "GetUserSettings",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}

const sendMessage = (msg, canToken) => {
    
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        msgContent: msg,
    }
    //console.log("data" , data)
    
    return axios.post(APIbase + "sendMessage", 
        {data: data},
        {headers: authHeader()},
        {cancelToken: canToken}
    );
}

const GetContactForm = (canToken) => {


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
    }
    //console.log("data" , data)
    
    return axios.post(APIbase + "getContactForm", 
        {data: data},
        {headers: authHeader()},
        {cancelToken: canToken}
    );



}



const GetDocuments = (canToken) => {
    
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
    }

    //console.log("data" , data)
    
    return axios.post(APIbase + "GetDocuments", 
        {data: data},
        {headers: authHeader()},
        {cancelToken: canToken}
    );
}




const GetMeterList = (canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "GetMeterList",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}


const GetMeterListAdmin = (userID, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        user_id: userID
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "GetMeterListAdmin",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}


 

const AddMeter = (fctCode, buyCode, name, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        meterID: fctCode,
        meterCode: buyCode,
        name: name
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "AddMeter",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}

const AddMeterAdmin = (userID, fctCode, buyCode, name, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        meterID: fctCode,
        meterCode: buyCode,
        name: name,
        user_id: userID
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "AddMeterAdmin",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}



const GetMeterState = (meterID, lim, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        limit: lim,
        KPC_ID: meterID
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "GetMeterState",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}




const GetMeterStateAdmin = (tvor_broj, lim, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        limit: lim,
        tvor_broj: tvor_broj
        
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "GetMeterStateAdmin",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}


const DeleteMeter = (meterID, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        meterID: meterID,
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "DeleteMeter",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}



const DeleteMeterAdmin = (meterID, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        KPC_ID: meterID,
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "DeleteMeterAdmin",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}

const DeleteUserAccount = (userID, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        userID: userID,
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "DeleteUserAccount",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}


const GetBillList = (meterID, lim, canToken) => {
     
    //console.log(meterID);
    //console.log(lim);

    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        limit: lim,
        KPC_ID: meterID
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "GetBillList",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}



const GetBillListAdmin = (meterID, lim, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        limit: lim,
        KPC_ID: meterID
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "GetBillListAdmin",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}


const ConvertPDF = (KPC_ID, PNB, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        KPC_ID: KPC_ID,
        pnb: PNB
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "ConvertPDF",
          {data: data},
          { headers: authHeader() },
          { cancelToken: canToken }
      
    );
  

}



const previewPDF = (guid) => {
    
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        guid: guid
    }



    return axios.post(APIbase + "PDFViewer",
    {data: data},
    { headers: authHeader() }

);


}







const AddDistArea = (addedArea, addedCode, address, mail, phone, canToken) => {


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        areaCode: addedCode,
        areaName: addedArea,
        address: address,
        email: mail,
        phone: phone,
    }
    //console.log("data" , data)
    
    return axios.post(APIbase + "AddDistArea", 
        {data:data},
        {headers: authHeader()},
        {cancelToken: canToken}
    );



}


const DeleteDistArea = (areaCode, canToken) => {


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        areaCode: areaCode,
    }
    //console.log("data" , data)
    
    return axios.post(APIbase + "DeleteDistArea", 
        {data:data},
        {headers: authHeader()},
        {cancelToken: canToken}
    );



}

const AddInfoArea = (areaCode, info, start, end, canToken) => {


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        areaCode: areaCode,
        info: info,
        start: start,
        end: end,
    }
    //console.log("data" , data)
    
    return axios.post(APIbase + "AddInfoArea", 
        {data:data},
        {headers: authHeader()},
        {cancelToken: canToken}
    );



}


const DeleteInfoArea = (infoID, canToken) => {


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        infoID: infoID,
    }
    //console.log("data" , data)
    
    return axios.post(APIbase + "DeleteInfoArea", 
        {data: data},
        {headers: authHeader()},
        {cancelToken: canToken}
    );



}
    

const DeleteDocument = (docID, canToken) => {
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        docID: docID,
    }
    //console.log("data" , data)
    
    return axios.post(APIbase + "DeleteDocument", 
        {data:data},
        {headers: authHeader()},
        {cancelToken: canToken}
    );
}


//***************************************************************************** */






const RenewPass = (email, pass, canToken) => {
    


    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        email: email,
        pass: pass,
    }
      
    //console.log("data ", data)
      
   
    return axios.post(APIbase + "RenewPass",
          {data:data},
          { cancelToken: canToken }
      
    );
  

}



















const DocumentUpload = (formData, canToken) => {
    
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        formData: formData,
    }
    //console.log("data" , data)

    formData.append("platform", "3");


/*    return axios.post(APIbase + "DocumentUpload", 
        formData,
        {
            headers: {
                auth,
                "Content-Type": "multipart/form-data"
            }
        },
        {cancelToken: canToken}
    );
    */
    return axios.post(APIbase + "DocumentUpload", 
    formData,
    {
        headers: authHeader()
    },
    {cancelToken: canToken}
);
}


const DocumentDownload = (fileName, fileType, canToken) => {
    
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,
        fileName: fileName,
        fileType: fileType
    }
    
    //console.log("data" , data);



    
    return axios.post(APIbase + "downloadFile", 
        {data:data},
        {headers: authHeader()},
        { responseType: 'blob' },
        {cancelToken: canToken}
    );

    /*
    return await Axios.get('API_URL/download', { responseType: 'blob' });
    if (response.data.error) {
    //console.error(response.data.error)
    }
    */

}


const DeleteUserAccountUsr = (canToken) => {
    const data = {
        platform: APPplatform,
        version: APPversion,
        appId: APPid,

    }

    return axios.post(APIbase + "DeleteUserAccountUsr", 
            {data:data},
            {headers: authHeader()},
            { responseType: 'blob' },
            {cancelToken: canToken}
        );
}














  export default {
    GetDistListOut,
    GetDistOneOut,
    Register,
    GetDistList,
    GetDistOne,
    ChangePass,
    ChangeNotifSettings,
    sendMessage,
    GetContactForm,
    GetDocuments,
    GetMeterList,
    GetMeterListAdmin,
    GetMeterStateAdmin,
    GetMeterState,
    GetBillList,
    GetBillListAdmin,
    AddMeter,
    AddMeterAdmin,
    DeleteMeter,
    DeleteMeterAdmin,
    ConvertPDF,
    AddDistArea,
    DeleteDistArea,
    AddInfoArea,
    DeleteInfoArea,
    DeleteDocument,
    previewPDF,
    DeleteUserAccount,



    
    
    RenewPass,
    GetUserSettings,
    


    
    


    DocumentUpload,
    DocumentDownload,
    DeleteUserAccountUsr
    
   


    
    

  };



