import React,{cloneElement, Component }from 'react';
import  { useState,useEffect } from 'react';

import  { Alert} from 'react';

import './css/postavke.css';



import { useDispatch, useSelector } from "react-redux";

import { logout } from "../actions/auth";



import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import APIservice from "./services/api.service";



//import Datetime from 'react-datetime';
//import "react-datetime/css/react-datetime.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

import { format } from 'date-fns';

import {ReactComponent as DeleteIcon} from '../img/close-circle-outline.svg';

import JoyRide from "react-joyride";

import {ReactComponent as InfoIcon} from '../img/information-circle.svg';

import Swal from 'sweetalert2';



const { v4: uuidv4 } = require('uuid');



function Postavke(props) {

  const [area, setArea] = useState([]);



  const [platform, setPlatform] = useState("3");
  const [isLoading, setIsLoading] = useState(true);

  const [addedArea, setAddedArea] = useState("");


  const [selectedAreaCode, setSelectedAreaCode] = useState('');
  const [selectorOption, setSelectorOption] = useState("Odaberite distribucijsko područje");

  
  const [addedInfo, setAddedInfo] = useState("");


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [addedStartTime, setAddedStartTime] = useState('');
  const [addedEndTime, setAddedEndTime] = useState('');

  const [list,setList]=useState([]);



  const [value, onChange] = useState(new Date());

  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");

  const [runTour, setRunTour] = useState(false);


  const toastError = {                                                        
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
  };

  const toastSuccess = {                                                        
           position: "top-center",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: false,
           draggable: true,
           progress: undefined,
           theme: "light",
  };

  const toastInfo = {                                                        
           position: "top-center",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: false,
           draggable: true,
           progress: undefined,
           theme: "light",
   };


      const { user: currentUser } = useSelector((state) => state.auth);


      const dispatch = useDispatch();


      useEffect(() => {


            
            getData();

            
            const allMenuTabs =  Array.from(document.querySelectorAll(".menu-item"));
            allMenuTabs.map((tab, index)=> {
                  tab.classList.remove("menuItemIsSelected")
            })
            document.querySelector(".menu-item-3").classList.add("menuItemIsSelected")
            

      }, []);



const logOut = () => {
    
      dispatch(logout());
};





function getData () {
      
      
      let unmounted = false;
      let source = axios.CancelToken.source();
 
      setIsLoading(true);
 
      APIservice.GetDistList(source.token).then(
 
           (response) => {
 
                if(!unmounted) {
 


                        setIsLoading(false);

                        //console.log(response.data.distLocations)

                        let dataTmp = response.data;

                        if(dataTmp.distLocations.length != 0){


                              var arrDist = [];

                              var inty2 = 0;

                              for(var propT in dataTmp.distLocations) {


                                          var areaNameTmp = "";
                                          var areaCodeTmp = "";


                                          for(var propT2 in dataTmp.distLocations[propT]){


                                                if(propT2 === 'areaName'){

                                                      areaNameTmp = dataTmp.distLocations[propT][propT2];


                                                      

                                                }else if (propT2 === "areaCode"){

                                                      areaCodeTmp = dataTmp.distLocations[propT][propT2];



                                                } else{

                                                      //nema ostalih


                                                }


                                          }





                                          arrDist.push({"name": areaNameTmp, "code": areaCodeTmp, "id":inty2});

                                          inty2 = inty2+1;

                              }


                              setArea(arrDist);

                              setSelectedAreaCode(arrDist[0].code);
                              setSelectorOption(arrDist[0].name); 

                              getDataOne(arrDist[0].code, arrDist[0].name);


                        }else{


      
      
                              //console.log(dataTmp.message);

                        }


   



 
 
                }
           }, 
           (error) => {
  
                if (!unmounted) {
        
                  //console.log("Error rq");
        
                  console.error(error);
 
                  setIsLoading(false);
 
        
                  if(!error.response){

                        toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                  } else {


                              if(!error.response.status){

                                          toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{
      
                                          if (error.response.status == 401) {

                                                      toast.error(error.response.data.message, toastError);;

                                                      setTimeout(() => {
                                                            logOut();
                                                      }, 5000); 
                              
                                          } else {
                              
                                                      toast.error(error.response.data.message, toastError);
                              
                                          
                                          }
                              }
                  }
                }
        
        
           }
 
      )
}



function getDataOne (chosenAreaCode, chosenAreaName) {
      
      let unmounted = false;
      
      let source = axios.CancelToken.source();
 
      setIsLoading(true);

      setList([]);
 
      APIservice.GetDistOne(chosenAreaCode, source.token).then (
 
            (response) => {
 
                  if(!unmounted) {
   
  
  
                          setIsLoading(false);
  
                          //console.log(response.data.distLocations);

                          //console.log(response.data);
  
                          let dataTmp = response.data;
  
                          if(dataTmp.distLocations.length != 0){
  
  
                                 //dobavi listu za usera

                                 var arrDistUser = [];

                                 var inty = 0;


                                 for(var propT in dataTmp.distList) {


                                    var areaNameTmp = "";
                                    var areaCodeTmp = "";
                                    var actDescTmp = "";
                                    var actCodeTmp = 0;
                                    var startTmp = "";
                                    var endTmp = "";

                                    

                                    for(var propT2 in dataTmp.distList[propT]){


                                          if(propT2 === 'areaName'){

                                          areaNameTmp = dataTmp.distList[propT][propT2];



                                          }else if (propT2 === "areaCode"){

                                                areaCodeTmp = dataTmp.distList[propT][propT2];


                                          }else if (propT2 === "actDesc"){

                                                actDescTmp = dataTmp.distList[propT][propT2];


                                          }else if (propT2 === "actCode"){

                                                actCodeTmp = dataTmp.distList[propT][propT2];


                                          }else if (propT2 === "start"){

                                                startTmp = dataTmp.distList[propT][propT2];

                                          }else if (propT2 === "end"){

                                                endTmp = dataTmp.distList[propT][propT2];


                                          } else{

                                          //nema ostalih


                                          }


                                    }





                                    arrDistUser.push({"name": areaNameTmp, "code": areaCodeTmp, "start": startTmp, "end": endTmp,
                                                 "actDesc": actDescTmp, "actCode": actCodeTmp, "id":inty});


                                    inty = inty+1;


                                 }


                                setIsLoading(false);

                                setList(arrDistUser);

  
  
                          }else{
  
  
        
        
                                //console.log(dataTmp.message);
  
                          }
  

   
                  }
             }, 
             (error) => {
    
                  if (!unmounted) {
          
                    //console.log("Error rq");
          
                    console.error(error);
   
                    setIsLoading(false);
   
          
                        if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                        } else {


                              if(!error.response.status){

                                          toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{
      
                                          if (error.response.status == 401) {

                                                      toast.error(error.response.data.message, toastError);;

                                                      setTimeout(() => {
                                                            logOut();
                                                      }, 5000); 
                                    
                              
                                          } else {
                              
                                                      toast.error(error.response.data.message, toastError);
                              
                                          
                                          }
                              }
                        }
                  }
          
          
             }
   
        )
}






function addNewArea() {
      
      let unmounted = false;
      
      let source = axios.CancelToken.source();
 
      //console.log("Area to add: " + addedArea);


      let addedCode = uuidv4();
      //console.log("AddedCode: " + addedCode);

      setIsLoading(true);



 
      APIservice.AddDistArea(addedArea, addedCode, address, mail, phone, source.token).then (
 
            (response) => {
 
                  if(!unmounted) {
   
  
  
                          setIsLoading(false);
  
                          //console.log(response.data.message);
  

                          toast.success(response.data.message, toastSuccess);

                          setAddedArea("");

                          setAddress("");
                          setPhone("");
                          setMail("");

                          getData();




   
                  }
             }, 
             (error) => {
    
                  if (!unmounted) {
          
                    //console.log("Error rq");
          
                    console.error(error);
   
                    setIsLoading(false);

                    setAddedArea("");

                    setAddress("");
                    setPhone("");
                    setMail("");
   
          
                        if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                        } else {


                              if(!error.response.status){

                                          toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{
      
                                          if (error.response.status == 401) {

                                                      toast.error(error.response.data.message, toastError);;
                                                      setTimeout(() => {
                                                            logOut();
                                                      }, 5000); 
                                    
                              
                                          } else {
                              
                                                      toast.error(error.response.data.message, toastError);
                              
                                          
                                          }
                              }
                        }
                  }
          
          
             }
   
        )
}



const DeleteArea = (deleteID) => {



      Swal.fire({
           title: `Jeste li sigurni da želite obrisati distribucijsko područje?`,
           text: "Ova radnja se ne može poništiti",
           icon: "warning",
           iconSize: '5',
 
           showCancelButton: true,
           confirmButtonColor: "#e31b2e",
           cancelButtonColor: "#29648a",
           showCancelButton: true,
           confirmButtonText: "Da, obriši područje!",
           cancelButtonText: "Odustani",
           reverseButtons: true
         }).then((result) => {
           if (result.isConfirmed) {
 
                  DeleteAreaConfirmed(deleteID)

           }
         });
 
 
 };



function DeleteAreaConfirmed(deleteID) {
      
      let unmounted = false;
      
      let source = axios.CancelToken.source();

      setIsLoading(true);
 

 
      APIservice.DeleteDistArea(deleteID, source.token).then (
 
            (response) => {
 
                  if(!unmounted) {
   
  
  
                          setIsLoading(false);
  
                          //console.log(response.data.message);
  

                          toast.success(response.data.message, toastSuccess);


                          getData();




   
                  }
             }, 
             (error) => {
    
                  if (!unmounted) {
          
                    //console.log("Error rq");
          
                    console.error(error);
   
                    setIsLoading(false);

   
          
                        if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                        } else {


                              if(!error.response.status){

                                          toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{
      
                                          if (error.response.status == 401) {

                                                      toast.error(error.response.data.message, toastError);;
                                                     

                                                      setTimeout(() => {
                                                            logOut();
                                                      }, 5000); 
                                    
                              
                                          } else {
                              
                                                      toast.error(error.response.data.message, toastError);
                              
                                          
                                          }
                              }
                        }
                  }
          
          
             }
   
        )
}






function prepareNewInfo(){

        if(addedInfo != ""){



                    if(addedStartTime != ''){


                              
                              if(addedEndTime !=''){



                                    //console.log(addedStartTime);

                                    //console.log(addedEndTime);


                                    if(addedStartTime >= addedEndTime){

                                          toast.error("Vrijeme završetka mora biti iza vremena početka", toastError);

                                    }else{

                                          addNewInfo();

                                          setStartDate(null);
                                          setEndDate(null);
                                          setAddedStartTime('');
                                          setAddedEndTime('');
                                          setAddedInfo('');


                                    }

                              }else{

                                    toast.error("Molimo unesite vrijeme završetka događaja", toastError);
                              }



                    }else{

                          toast.error("Molimo unesite vrijeme početka događaja", toastError);
                    }



        }else{

              toast.error("Molimo unesite opis informacije koju dodajete", toastError);

        }


}





function addNewInfo() {


      setIsLoading(true);
    
    
      //console.log("Info to add: " + addedInfo);
      //console.log("Info start: " + addedStartTime);
      //console.log("Info end: " + addedEndTime);
      //console.log("Area code: " + selectedAreaCode);
      
      let unmounted = false;
      
      let source = axios.CancelToken.source();



 
      APIservice.AddInfoArea(selectedAreaCode, addedInfo, addedStartTime, addedEndTime, source.token).then (
 
            (response) => {
 
                  if(!unmounted) {
   
  
  
                          setIsLoading(false);
  
                          //console.log(response.data.message);
  

                          toast.success(response.data.message, toastSuccess);

                          setAddedInfo("");
                          setAddedStartTime("");
                          setAddedEndTime("");


                          getData();




   
                  }
             }, 
             (error) => {
    
                  if (!unmounted) {
          
                    //console.log("Error rq");
          
                    console.error(error);
   
                    setIsLoading(false);

                    setAddedInfo("");
                    setAddedStartTime("");
                    setAddedEndTime("");
   
          
                        if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                        } else {


                              if(!error.response.status){

                                          toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{
      
                                          if (error.response.status == 401) {

                                                      toast.error(error.response.data.message, toastError);;

                                                      
                                                      setTimeout(() => {
                                                            logOut();
                                                      }, 5000); 
                              
                                          } else {
                              
                                                      toast.error(error.response.data.message, toastError);
                              
                                          
                                          }
                              }
                        }
                  }
          
          
             }
   
        )
}




const DeleteInfo = (deleteID) => {



      Swal.fire({
           title: `Jeste li sigurni da želite obrisati aktivnost?`,
           text: "Ova radnja se ne može poništiti",
           icon: "warning",
           iconSize: '5',
 
           showCancelButton: true,
           confirmButtonColor: "#e31b2e",
           cancelButtonColor: "#29648a",
           showCancelButton: true,
           confirmButtonText: "Da, obriši aktivnost!",
           cancelButtonText: "Odustani",
           reverseButtons: true
         }).then((result) => {
           if (result.isConfirmed) {
 
                  DeleteInfoConfirmed(deleteID);

           }
         });
 
 
 };


function DeleteInfoConfirmed(deleteID) {
      
      let unmounted = false;
      
      let source = axios.CancelToken.source();

      setIsLoading(true);
 

 
      APIservice.DeleteInfoArea(deleteID, source.token).then (
 
            (response) => {
 
                  if(!unmounted) {
   
  
  
                          setIsLoading(false);
  
                          //console.log(response.data.message);
  

                          toast.success(response.data.message, toastSuccess);


                          getData();




   
                  }
             }, 
             (error) => {
    
                  if (!unmounted) {
          
                    //console.log("Error rq");
          
                    console.error(error);
   
                    setIsLoading(false);

   
          
                        if(!error.response){

                              toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);


                        } else {


                              if(!error.response.status){

                                          toast.error("Problem u povezivanju sa serverom, molimo pokušajte kasnije", toastError);

                              }else{
      
                                          if (error.response.status == 401) {
                                                
                                                toast.error(error.response.data.message,
                                                      toastError);
                                              
                                                setTimeout(() => {
                                                                  logOut();
                                                }, 5000); 
                                    
                              
                                          } else {
                              
                                                      toast.error(error.response.data.message, toastError);
                              
                                          
                                          }
                              }
                        }
                  }
          
          
             }
   
        )
}





function changedTime(){

      //console.log(value);

}


var yesterday = moment().subtract(1, "day");
function valid(current) {
            return current.isAfter(yesterday);
}



var steps = [
     {
       target: '.postavke-inputArea',
       content: `Za unos novog distribucijskog područja na korisnički sustav morate obavezno unijeti ime novog područja, dok su neobvezne informacije dodatni podaci kao što je adresa sjedišta, kontakt broj i kontakt mail adresa. 
       Pojedino distribucijsko područje možete brisati odabirom ikone "x" pokraj dist. područja koje želite obrisati.`,
       disableBeacon: true,
       placement: "end"
     },
     {
          target: '.postavke-selector',
          content: `Za unos novih informacija o aktivnostima na pojedinom distribucijskom području u izborniku "Unesite podatke o aktivnostima na distribucijskom području" morate odabrati na koje dist. područje se aktivnost odnosi, te unijeti opis i vrijeme trajanja.
          Aktivnosti možete brisati odabirom ikone "x" pokraj aktivnosti koju želite obrisati iz liste.`,
          disableBeacon: true,
          placement: "right"
     },                    
      
]


     const handleJoyrideCallback = (data) => {

          //console.log("JOyride callback");
          //console.log(data);
          const { status, type } = data;
          //const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
          if ((data.status == "finished" || data.status == "skipped")) {
            setRunTour(false);
          }

     }


     const handleJoyrideStart =()=>{

          setRunTour(true);
     }





return (
      <div className="postavke">




            <div className="postavke-first-part">
                  <div className="postavke-first-title">
                        Unesite novo distribucijsko područje
                  </div>

                  <div style={{margin: 20}}><button 
													
                                                                              className='infoButton'
                                                                              onClick={() => handleJoyrideStart()}
                                                                              >
                                                                              <InfoIcon style={{fill: '#29648a', }} size="0.7x"/>
                                      </button>   
                                 </div>       

                  <div className="postavke-search">
                
                        <div className="postavke-enter-name">
                              <input className="postavke-inputArea" type="text" maxLength="50" placeholder="Unesite ime distribucijskog područja" value={addedArea}
                              
                                    onChange={e => {
                                      
                                      setAddedArea(e.target.value) ;
                                      }}>
  
                              </input>
                              
                              <div className="postavke-add">
                    
                                    <button onClick={()=>{
  
  
  
                                                if(addedArea != ''){
  
                                                      addNewArea();

                                                      setAddedArea('');
                                                      setAddress('');
                                                      setPhone('');
                                                      setMail('');

                                                      
                                                      
  
  
                                                }else{
  
                                                      toast.error('Morate unijeti naziv novog distribucijskog područja', toastError);
                                                }
  
                                                
                                                
                                          }}>
                                          Spremi
                                    </button>
                              </div>
                        
                        </div>
  
  
                        <div className="postavke-enter-name">
  
                                <label className="postavke-descLabel">
                                        
                                            <input className="postavke-descriptionAddress" name="description" placeholder="Adresa distribucijskog područja" maxLength="100" value={address}
                                                              onChange={e => {
                                                        
                                                              setAddress(e.target.value) ;
                                                              }} />
  
  
                                            
  
                                </label>                           
  
                        </div>
  
                        <div className="postavke-enter-name">
  
                                <label className="postavke-descLabel"> 
  
  
                                      <input className="postavke-input-phone" type="text" placeholder="Kontakt broj" pattern="[0-9]*" maxLength="20" value={phone} 
                                            onChange={event => setPhone(event.target.value.replace(/\D/,''))}/>
  
                                </label>
  
                        </div>
  
  
                        <div className="postavke-enter-name">
  
                                <label className="postavke-descLabel">
  
  
                                      <input className="postavke-input-phone" type="text" placeholder="Kontakt e-mail" maxLength="30" value={mail} 
                                            onChange={event => setMail(event.target.value)}/>
  
                                </label>
  
                        </div>
  
  
                        <div className="postavke-list">
                              {area.map((element, index)=>{
                                  return(
                                    <div className="postavke-list-item">
                                            <div className="list-itemName">{element.name}</div>
                                            {/*<button className="list-item-btn" onClick={()=>{
                                                   area.splice(index, 1);
                                                   
                                                   DeleteArea(element.code);
                                                        }}>Obriši</button>*/}
                                    
                                            <button 
													
								className='removeAreaButton'
								onClick={() => {

                                                      //console.log("Deleting area");
                                                      //console.log(element.name);
                                                      area.splice(index, 1);
                                                   
                                                      DeleteArea(element.code);                                                

								}}>
								{/*<DeleteIcon style={{fill: '#e31b2e', }} size="0.5x"/>*/}
                                                <DeleteIcon style={{fill: '#29648a', color: '#29648a'}} size="0.5x"/>
							  </button>   


                                    </div>
                                  )
                                })}
                        </div>
                  </div>
            
            </div>
  
            <div className="postavke-second-part">
                  
                  <div className="postavke-second-title">
                          Unesite podatke o aktivnostima na distribucijskom području
                  </div>
              
                  <div className="postavke-input-container">
                
                  <div className="postavke-selector">
                          <select value={selectorOption} name="basic" id="ex-basic" 
                                    
                          onChange={(e)=>{
  
                                let index = e.target.selectedIndex;
                                let optionElement = e.target.childNodes[index];
                                let optionElementId = optionElement.getAttribute('id');
  
                                let a = optionElement.getAttribute('value');
                                let b = optionElementId;
                                 
  
                                //console.log("Index " + index);
                                //console.log("Option: " + optionElement);
                                //console.log("ID: " + optionElementId);
                          
                                //console.log(optionElementId);
  
  
                                //let a = e.target.name;
                                //let b = e.target.code;
                                //let c = e.target.id;
  
                                ////console.log(a);
                                ////console.log(b);
                                ////console.log(c);
  
                                setSelectedAreaCode(b);
                                setSelectorOption(a); 
  
  
                                //console.log("Getting the data for area: " + a);
                                //console.log("Getting the data for area: " + b);
  
                                getDataOne(b, a);
  
                          }}
                          >
                        
                                {area && area.map(element=>{
  
                                  return(
  
                                     
  
                                    <option id={element.code} value={element.name} onClick={(e)=>{
  
  
  
  
                                      }}> {element.name} </option>
                                  )
                                })}
                          </select>
                  </div>
              
                          <div className="postavke-description">
                                <label className="postavke-descLabelTA">
                                        
                                <textarea className="postavke-descriptionTA" name="description" placeholder=' Opis aktivnosti' maxLength="1000" value={addedInfo}
                                                  onChange={e => {
                                                 
                                                  setAddedInfo(e.target.value) ;
                                                  }} />
  
  
                               
  
                                </label>
                                
                          </div>


                                                 

                          <div className="postavke-dates">




                                <div className="postavke-start">
                                      <label className="labelPicker">
                                            <div className="postavke-start-label">
                                                  Početak :
  
                                            </div>
  
                          

                                                <div className="datePicker">
                                                      <DatePicker
                                                            showIcon
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                            selected={startDate}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="Vrijeme"

                                                            onChange={(date) => {
                                                                  let tmp = new Date(date).getTime();
                                                                  let aStart= new Date(date).getTime() / 1000;


                                                                  
                                                                  setAddedStartTime(aStart);
                                                                  setStartDate(date)}}
                                               


                                                      />    
                                                 </div>            
                                                 
                                                                                 
                                      </label>
   
   
                                </div>
                                <div className="postavke-end">
                                      <label className="labelPicker">
                                            <div className="postavke-end-label">
                                                  Završetak :
  
                                            </div>
                                            


                                                <div className="datePicker">
                                                      <DatePicker
                                                            showIcon
                                                            disabled={!addedStartTime}
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                            selected={endDate}
                                                            minDate={startDate}
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="Vrijeme"
                                                            showTimeSelect

                                                            onChange={(date) => {
                                                              

                                                                  let tmp = new Date(date).getTime();
                                                                  let aEnd= new Date(date).getTime() / 1000;


                                                                  setEndDate(date);

                                                                  setAddedEndTime(aEnd);
                                                                  
                                                         
                                                                  }}

                                                                  
                                                                                                                    
                                                      />    
                                                 </div>            

                                      </label>
                                </div>
                          </div>
                          <div className="postavke-save-button">
                                <button onClick={()=>{
                                
  
                                            
                                            prepareNewInfo();
                                            
                                            }}>
                                            Spremi
                                </button>
                          </div>
                          <div className="postavke-list-info">
  
  
                               {list.map((element, index)=>{
                                  return(
                                    <div className="postavke-list-item-info">
  
                                            <div className="list-first-row">
                                                  <div className="list-itemName-info"><span style={{fontWeight: 'bold', color: '#29648a'}}>{element.name}</span> : {element.actDesc}</div>
                                                  <div className="list-itemDate-info">{element.start} - {element.end}</div>
                                            </div>      
                           
                                            
                                            <button 
													
                                                      className='removeAreaButton'
                                                      onClick={() => {
                              
                                                                  //console.log("Deleting info");
                                                                  //console.log(element.name);
                                                                  
                                                                  //list.splice(index, 1);
                                                        
                                                                  DeleteInfo(element.actCode);
                              
                                                                            
                              
                                                      }}>
                                                      <DeleteIcon style={{fill: '#29648a', color: '#29648a'}} size="0.5x"/>
                                            </button>                                              
                                                        
                                    
                                    </div>
                                  )
                                })}    

   
            <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
            />   


<div id="container1">

<JoyRide 
run={runTour}
steps={steps} 
continuous={true} 
showSkipButton={true} 
callback={handleJoyrideCallback}

locale={{
     skip: "Preskoči",
     last:"Završi", 
     back:"Natrag",
     next: "Dalje"}}
     
styles={{
     options: {
          arrowColor: "#f4f6f9",
          primaryColor: "#29648a",
          textColor: "#72a0c1",
          backgroundColor: "#f4f6f9",
          borderTopRadius: 10,
          borderWidth:0,
          width: 500,
          zIndex: 10000,
          fontSize: 14,
          
     },
     buttonNext: {
          backgroundColor: "#29648a",
          title: "Dalje",
          text:"Dalje",
          border: "none",
          borderRadius: 10,
          color: "white",
          fontSize: 14,
          "&:focus": {
          outline: "none !important",
          },
     },
     buttonBack: {
          color: "#29648a",
          marginLeft: "auto",
          marginRight: 5,
          fontSize: 14,
     },
     buttonClose: {
          color: "#29648a",
          height: 14,
          padding: 15,
          position: "absolute",
          right: 0,
          top: 0,
          width: 14,
          
     },
     buttonSkip: {
          backgroundColor: "#e31b2e",
          title: "Preskoči",
          text:"Preskoči",
          border: "none",
          borderRadius: 10,
          color: "white",
          fontSize: 14,
          "&:focus": {
          outline: "none !important",
          },                                   
     }
     }}
/>

</div>                                                        
                          </div>
  
                  </div>
            </div>

              
      </div>
      );
      
  
  }
  
  
  
  export default Postavke;