import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";


import{Alert}from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useLocation
  } from "react-router-dom";

import './css/Register.css'

import { FiLogIn } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { FiLock } from "react-icons/fi";



import Logo2 from './icons/EP.png';

import LoaderReg from "./LoaderReg";

import { history } from "../helpers/history";

import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import APIservice from "./services/api.service";



  import { register } from "../actions/auth";
  import { clearMsg } from '../actions/auth';
  


function Register(props){


      const[ime,setIme]=useState('');
      const[sifra,setSifra]=useState('');

      const[ime2,setIme2]=useState('');
      const[sifra2,setSifra2]=useState('');


      const [allUsers,setAllUsers]=useState([]);

      const [userId, setUserId]=useState();
      
      
      const [platform, setPlatform] = useState("3");

      const [isLoading, setIsLoading] = useState(true);

      const [isLoadingReg, setIsLoadingReg] = useState(false);

      const { message } = useSelector(state => state.message);

      const dispatch = useDispatch();



    const toastError = {                                                        
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                
    };

    const toastSuccess = {                                                        
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
    };

    const toastInfo = {                                                        
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
    };



    useEffect(() => {

        //console.log("Register start");

        dispatch(clearMsg());


   }, []);



    const handleSubmit = (e) => {


        e.preventDefault()


        let unmountedSubmit = false;
        let source = axios.CancelToken.source();
  
        //console.log("Koristim info: " + ime + " sifra " + sifra);
  

        dispatch(register(ime, sifra, sifra2, source.token))
        .then(() => {

            if (!unmountedSubmit) {
                
                //console.log("Result from dispatch");

                //props.history.push("/Pocetna");

            }


        })
        .catch(() => {
            //setIsLoading(false);
        });


  
     }
  



return (
    
    
    <div className="bodyLogIn">




                <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        z-index={200000}
                />   


         {/*<div className="loginNatrag">
            <Link to="/pocetnaOut">Planirana isključenja</Link>
        
        </div>*/}


        {!isLoadingReg &&

        
            <form onSubmit={handleSubmit} className="containerRegister"> 



                <div className="logoImg-register">
                        <img src={Logo2} alt="Logo" />
                </div>  
                <div className="register-form">

                    <div className="inputNoRepeat">
                            <div className="inputContainerReg">
                                    <FiUser className="ikona"/>
                                    <input
                                            className="emailInput"
                                            type='text'
                                            placeholder="Unesite e-mail"  
                                            onChange={e => {
                                                setIme(e.target.value) ;
                                            }}
                                    />
                            </div>        

                    </div>
                    <div className="inputRepeat">
                                <div className="inputContainerReg">
                                        <FiLock className="ikona"/>
                                        
                                        <input
                                            className="emailInput"
                                            type='password'
                                            placeholder="Unesite lozinku"  
                                            onChange={e => {
                                                setSifra(e.target.value) ;
                                                }}
                                        />
                                        
                                </div>
                                <div className="inputContainerReg">
                                        <FiLock className="ikona"/>
                                        <input
                                            className="emailInput"
                                            type='password'
                                            placeholder="Ponovite unos"  
                                            onChange={e => {
                                                setSifra2(e.target.value) ;
                                                }}
                                        />
                                </div>        
                
                            
                    </div>
                    
                    <div className="registerSubmit">
                                <button type="submit" className='buttonRegister' >
                                    Registracija
                                </button>
                    </div>
                </div>

                {message && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert"  >
                            {message}
                        </div>
                    </div>
                )}
                
            </form>




        

       
    }

{isLoadingReg && <LoaderReg />}



    </div>


    );
}



export default Register;